
export class Track {
  private static hasLoggedClosingWindow: boolean = false;

  public static initialize(): void {
    Track.log("Page ready");

    $("a").on("click", this.trackLink);
    $(":button, :submit").on("click", this.trackSubmit);

    this.hasLoggedClosingWindow = false;
    $(window).on("mousemove", this.logMousemove);
  }

  public static log(event: string, data?: any): void {
    $.post("/tracking", {
      url: document.location.href,
      event: event,
      data: data
    });
  }

  private static logMousemove(e: JQueryEventObject): void {
    if (!this.hasLoggedClosingWindow && e.pageY < 20) {
      Track.log("Mouse left the window");
      this.hasLoggedClosingWindow = true;
    }
  }

  private static trackLink(elem: Event): void {
    const target: JQuery<HTMLElement> = $(elem.target);
    let link: string = "";

    if (target.is("a")) {
      link = target.text();
    } else {
      link = target.parent().attr("href");
    }

    Track.log(`Click on link "${link}" (${target.attr("id")})`);
  }

  private static trackSubmit(elem: Event): void {
    const target: JQuery<HTMLElement> = $(elem.target);
    Track.log(`Click on button "${target.val()}" (${target.attr("id")})`);
  }
}
