/**
 * Retrieves the Google Analytics element with partner ID.
 *
 * @returns {Element} The Google Analytics token element.
 */
export function getAnalyticsTag() {
  return document.getElementsByName("google-analytics-token")[0];
}

/**
 * Retrieves the Google Flights element with partner ID.
 *
 * @returns {Element} The element with the name "google-flights-token".
 */
export function getFlightsTag() {
  return document.getElementsByName("google-flights-token")[0];
}

/**
 * Pushes the provided arguments to the dataAnalyticsLayer.
 */
function gtag() { dataAnalyticsLayer.push(arguments); }

/**
 * Load analytics tags and configure gtag to load configs for provided partner IDs.
 *
 * @return {void}
 */
export function loadAnalytics() {
  const analyticsTag = getAnalyticsTag();
  const flightsTag = getFlightsTag();

  window.dataAnalyticsLayer = window.dataAnalyticsLayer || [];
  gtag('js', new Date());

  if(analyticsTag) gtag('config', analyticsTag.content);
  if(flightsTag) gtag('config', flightsTag.content);
}

/**
 * Transforms a given string representation of a price into a desired format based on the currency.
 *
 * @param {string} string - The string representation of the price.
 * @param {string} currency - The currency in which the price should be transformed.
 *
 * @return {string} - The transformed price.
 */
export function priceTransform(string, currency) {
  if(string.length===0) return '';

  const price = string.match(/\d+/g).join("");

  if (['EUR', 'CHF'].includes(currency)) {
    return (parseFloat(price) / 100).toString();
  } else {
    return price;
  }
}

/**
 * Sends a Google conversion event for a purchase.
 *
 * @param {string} tripType - The type of trip (e.g. "one-way", "round-trip").
 * @param {string} value - Total client's cost.
 * @param {string} currency - The currency of the purchase (e.g. "USD", "EUR").
 * @param {{total: number, infant_in_lap: number, adult: number, child: number}} passengers - The number of passengers.
 * @param {object[]} items - List of all flights in the itinerary.
 * @return {boolean} - True if the event was sent successfully, false otherwise.
 */
export function sendGoogleConversionEvent(tripType, value, currency, passengers, items) {
  if(!getFlightsTag()) return false;

  gtag('event', 'purchase', {
    'send_to': getFlightsTag().content,
    'trip_type': tripType,
    'value': value,
    'currency': currency,
    'passengers': passengers,
    'items': items
  });
  return true;
}

/**
 * Sends a Google Price Accuracy event with the error instead of flight data.
 *
 * @param errorCode - Defaults to `FLIGHT_NOT_FOUND`, other accepted values are `TIMEOUT`, `GEO_BLOCKED` and `OTHER`
 * @param {string} message - Optional error message.
 * @return {boolean} - Returns true if the error event was sent successfully, false otherwise.
 */
export function sendGoogleErrorEvent(errorCode = 'FLIGHT_NOT_FOUND', message = '') {
  if(!getFlightsTag()) return false;

  gtag('event', 'view_item', {
    'send_to': getFlightsTag().content,
    'flight_error_code': errorCode,
    'flight_error_message': message
  });
  return true;
}

/**
 * Sends a Google Price Accuracy event.
 *
 * @param {string} value - The value of the event.
 * @param {string} currency - The currency of the event.
 * @return {boolean} - True if the event was sent successfully, false otherwise.
 */
export function sendGooglePriceAccuracyEvent(value, currency) {
  if(!getFlightsTag()) return false;

  gtag('event', 'view_item', {
    'send_to': getFlightsTag().content,
    'value': value,
    'currency': currency
  });
  return true;
}
