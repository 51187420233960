// import ActionCable from "actioncable";
import { getConfig, toObject, isNull, HttpHelper } from "app/helpers";
import { Track } from "components/common/track";

// class Logger {
//   public log(...messages: string[]) {
//     Track.log(messages.slice(0, -1).join(" "));
//     // console.log(messages);
//   }
// }
//
// ActionCable.logger = new Logger();
// ActionCable.startDebugging();

/**
 * Protocols that are supported by the Channel.
 */
export enum ChannelProtocol {
  Http,
  WebSocket
}

interface IPacketTrackerHash {
  [action: string]: number[];
}

export class ChannelPacketLimiter {
  public enabled: boolean = true;

  private list: IPacketTrackerHash = {};
  private paketDuration: number;
  private paketLimit: number;

  /**
   * Initialize a new ChannelPacketLimiter.
   * @param paketDuration milliseconds allowed between the limit.
   * @param paketLimit amount of pakets we allow per paketDuration.
   */
  constructor(paketDuration = 1000, paketLimit = 5) {
    this.paketDuration = paketDuration;
    this.paketLimit = paketLimit;
  }

  /**
   * Reset the limiter.
   */
  public reset(): void {
    this.list = {};
  }

  /**
   * Reset the limiter index.
   * @param index The index name.
   */
  public resetIndex(index: string): void {
    this.list[index] = [];
  }

  /**
   * Check if we are allowed to handle the response or not.
   * @param action paket action type.
   * @param timestamp timestamp of the call.
   */
  public allow(action: string, timestamp: number = Date.now()): boolean {
    if (this.enabled) {
      this.addResponse(action, timestamp);
      return this.checkLimit(action);
    } else {
      return true;
    }
  }

  private checkLimit(action: string): boolean {
    if (this.list[action].length > this.paketLimit) {
      this.flush(action);
    }
    return (this.list[action].length <= this.paketLimit);
  }

  private addResponse(action: string, timestamp: number): void {
    if (isNull(this.list[action])) {
      this.list[action] = [];
    }
    this.list[action].push(timestamp);
  }

  private flush(action: string): void {
    const timestampNow: number = Date.now() - this.paketDuration;
    while (this.list[action].length > 0) {
      const firstElem: number = this.list[action][0];
      if (firstElem < timestampNow || isNull(firstElem)) {
        this.list[action].pop();
      } else {
        break;
      }
    }
  }
}

/**
 * An object oriented timer.
 */
export class ChannelTimer {
  /** Called when the timer stops. */
  public onStop: () => void;

  private timeout: number;
  private timerHandler: TimerHandler;
  private timerHandlerArgs: any[];
  private timerHandleId: number;

  /**
   * Initialize a new Channel Timer.
   * @param timeoutSeconds in seconds
   */
  constructor(handle: TimerHandler, timeoutSeconds: number, ...args: any[]) {
    this.timerHandler = handle;
    this.timerHandlerArgs = args;
    this.setTimeout(timeoutSeconds);
  }

  /**
   * Set the timeout in seconds.
   * @param timeoutSeconds in seconds
   */
  public setTimeout(timeoutSeconds: number): void {
    this.timeout = timeoutSeconds * 1000;
    this.restart();
  }

  /**
   * Stop the timer.
   */
  public stop(): void {
    if (this.timerHandleId) {
      clearTimeout(this.timerHandleId);
      if (this.onStop) {
        this.onStop();
      }
    }
  }

  /**
   * Restart the timer.
   */
  public restart(): void {
    if (this.timerHandleId) {
      clearTimeout(this.timerHandleId);
    }
    this.timerHandleId = setTimeout(this.timerHandler, this.timeout, this.timerHandlerArgs);
  }
}

/**
 *
 */
export class ChannelExecuteDelay {
  private context: any;
  private action: any;
  private delay: number;
  private handler: any = null;

  constructor(context: any, delay: number = 500) {
    this.context = context;
    this.delay = delay;
  }

  public reset(): void {
    if (this.handler) {
      clearTimeout(this.handler);
    }
    this.handler = null;
    this.action = null;
  }

  public run(action: () => void): void {
    this.action = action;

    if (this.handler === null) {
      const self = this;
      this.handler = setTimeout(function () {
        if (self.action) {
          self.action.bind(self.context)();
        }
        self.reset();
      }, this.delay);
    }
  }
}

/**
 * Base class for a network Channel, designed to target ActionCable (WebSocket).
 * Has the possibility of falling back to using normal HTTP calls
 * if WebSocket is not supported by the client.
 */
export abstract class Channel {
  private cable: ActionCable.Cable;

  private channelName: string;
  private protocol: ChannelProtocol;
  private initialProtocol: ChannelProtocol;

  // private channel: ActionCable.Channel;
  private limiter: ChannelPacketLimiter;

  /** Gets the packet limiter. */
  public getLimiter(): ChannelPacketLimiter { return this.limiter; }

  /** Gets the current protocol. */
  public getProtocol(): ChannelProtocol { return this.protocol; }

  /**
   * Initialize a new Channel.
   * @param channelName channel name to connect to.
   * @param protocol (optional) Set to use a specific protocol.
   */
  constructor(channelName: string, options: any = null, protocol: ChannelProtocol = null) {
    this.channelName = channelName;
    this.limiter = new ChannelPacketLimiter();
    this.protocol = ChannelProtocol.Http;
    // this.initialProtocol = ChannelProtocol.WebSocket;

    this.initialProtocol = ChannelProtocol.Http;
    this.onConnected(this.protocol);

    // // Start connection to WebSocket
    // this.connectWebSocket(channelName, options);

    // // Is there no WebSocket connection within 1 sec then we connect using HTTP.
    // setTimeout(function () {
    //   if (this.protocol === ChannelProtocol.Http) {
    //     this.cable.connection.close();
    //     this.onConnected(this.protocol);
    //   }
    // }.bind(this), 250);
  }

  /** Gets the action cable query url. */
  protected abstract getCableUrlQuery(): string;

  /**
   * Perform a request to the channel.
   * @param action channel action.
   * @param payload
   */
  protected perform(action: string, payload: any = {}, attempts: number = 0) {
    // console.log(`[Channel] Request: ${action}`);

    // if (this.protocol === ChannelProtocol.WebSocket && this.cable.connection.disconnected) {
    //   Track.log(`[Channel] Very unexpected result!`);
    //   this.channel.perform(action, payload);
    // } else {
      const cableUrl: string = getConfig("action-cable-http-url");
      const url: string = `${cableUrl}/${this.channelName}/${action}?${this.getCableUrlQuery()}`;
      HttpHelper.post(url, payload)
        .then((response: any) => {
          this.received(response.data);
        }).catch((error: any) => {
          if (error.response) {
            Track.log(`[Channel] Error: ${error} - ${error.response.status} (attempt: ${attempts})`, error.response.data);
          } else {
            Track.log(`[Channel] Error: ${error} (attempt: ${attempts})`);
          }

          if (attempts < 10) {
            this.perform(action, payload, attempts + 1);
          }
        });
    // }
  }

  /**
   * Called when we connect to a channel or switched protocol.
   * @param protocol the protocol we are currently running.
   */
  protected abstract onConnected(protocol: ChannelProtocol): void;

  /**
   * Called when we disconnect from a channel.
   */
  protected abstract onDisconnected(): void;

  /**
   * Called when we receive something new from the channel.
   * @param response the response body we get from the channel.
   * @returns true, if the packet limiter should count; otherwise, false.
   */
  protected abstract onReceived(response: any): boolean;

  private connectWebSocket(channelName: string, options: any = null): void {
    // const cableUrl = `${getConfig("url")}?${this.getCableUrlQuery()}`;
    // this.cable = ActionCable.createConsumer(cableUrl);
    //
    // var parameters = {
    //   channel: channelName
    // };
    //
    // if (options) {
    //   // parameters = Object.assign(parameters, options);
    // }
    //
    // // Track.log(`[Channel] Connecting to channel: ${channelName}.`);
    // this.channel = this.cable.subscriptions.create(parameters, {
    //   connected: this.webSocketConnected.bind(this),
    //   disconnected: this.disconnected.bind(this),
    //   received: this.webSocketReceived.bind(this)
    // });
  }

  private webSocketConnected(): void {
    if (this.protocol === ChannelProtocol.Http) {
      Track.log(`[Channel] Use WebSocket protocol`);
      this.protocol = ChannelProtocol.WebSocket;
    }

    this.onConnected(this.protocol);
  }

  private webSocketReceived(response: any): void {
    if (this.protocol === ChannelProtocol.Http) {
      this.protocol = ChannelProtocol.WebSocket;
    }
    this.received(response);
  }

  private disconnected(): void {
    if (this.initialProtocol === ChannelProtocol.WebSocket &&
        this.protocol === ChannelProtocol.WebSocket) {
      this.protocol = ChannelProtocol.Http;
      Track.log(`[Channel] Use Http protocol`);
      this.perform("status");
    }

    this.onDisconnected();
  }

  private received(response: any): void {
    const obj: any = toObject(response);
    if (obj.action) {
      // console.log(`[Channel] Response: ${obj.action}`);

      if (this.limiter.allow(obj.action)) {
        if (!this.onReceived(obj)) {
          this.limiter.resetIndex(obj.action);
        }
      } else {
        // throw new Error("Packet is ignored.");
      }
    } else {
      throw new Error("Packet type is not supported.");
    }
  }
}
