<template>
  <div :id="componentID()">
    <input type="hidden" :name="this.name" :value="this.currentValue" />
    <div class="slider_component"></div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    value: Number,
    min: Number | null,
    max: Number | null,
    step: Number | null,
    context: Number | Object
  },
  data() {
    return {
      uid: null,
      currentValue: this.value,
      skipWatch: false
    };
  },
  watch: {
    value: {
      handler(val, oldVal) {
        this.setValue(val);
      }
    },
    currentValue: {
      handler(val, oldVal) {
        if (!this.skipWatch) {
          this.$emit("change", this, this.name, val, this.context);
        }
      }
    }
  },
  methods: {
    componentID() {
      return `cuid_${this.uid}`;
    },
    component() {
      return $(`#${this.componentID()} .slider_component`);
    },
    getValue() {
      return this.currentValue;
    },
    setValue(value, watch = false) {
      this.skipWatch = watch;
      this.currentValue = value;
      this.component().slider("option", "value", value);
      this.$nextTick(() => {
        this.skipWatch = false;
      });

      if (!watch) {
        this.$emit("slide", this, this.getValue(), this.context);
      }
    },
    reset() {
      this.setValue(this.max);
    },
    componentOptions() {
      const self = this;
      return {
        range: "min",
        value: this.currentValue,
        min: this.min,
        max: this.max,
        step: this.step,
        slide(event, ui) {
          self.$emit("slide", self, ui.value, self.context);
        },
        stop(event, ui) {
          self.currentValue = ui.value;
        }
      };
    }
  },
  beforeMount() {
    this.uid = this._uid;
  },
  mounted() {
    this.component().slider(this.componentOptions());
  }
};
</script>
