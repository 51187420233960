import aes from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";
import jsCookie from "js-cookie";

export class FormCache {
  public static Save(form: JQuery<HTMLElement>, include: string[] = null, exclude: string[] = []): boolean {
    const formData: object = this.GetFormData(form, include, exclude);
    if (formData == null) {
      return false;
    }

    const encodedData: string = this.encode(JSON.stringify(formData));

    // 5 min
    const expireIn: Date = new Date(new Date().getTime() + 5 * 60 * 1000);
    jsCookie.set(`form_cache_${form.attr("id")}`, encodedData, { expires: expireIn });

    return true;
  }

  public static Apply(form: JQuery<HTMLElement>): boolean {
    if (form == null) {
      return false;
    }

    const formData: any = this.Get(form);
    const formId: string = form.attr("id");
    if (formData) {
      for (const element in formData) {
        const elem: JQuery<HTMLElement> = $(`#${formId} [name="${element}"]`);
        if (elem.attr("type") === "radio") {
          const elemRadio: JQuery<HTMLElement> = $(`#${formId} [name="${element}"][value="${formData[element]}"]`);
          elemRadio.attr("checked", "checked");
        } else {
          elem.val(formData[element]);
        }
      }
    }

    return true;
  }

  public static GetFormData(form: JQuery<HTMLElement>, include: string[] = null, exclude: string[] = []): Object | null {
    if (!form) {
      return null;
    }

    const formData: JQuerySerializeArrayElement[] = form.serializeArray();

    const result: object = {};
    formData.forEach((element: any) => {
      if (include !== null) {
        if (this.arrayContains(include, element.name) &&
            !this.arrayContains(exclude, element.name)) {
          result[element.name] = element.value;
        }
      } else {
        if (!this.arrayContains(exclude, element.name)) {
          result[element.name] = element.value;
        }
      }
    });

    return result;
  }

  public static Get(form: JQuery<HTMLElement>): any {
    try {
      const val: string = jsCookie.get(`form_cache_${form.attr("id")}`);
      return val ? this.decode(val) : null;
    } catch (e) {
      return null;
    }
  }

  // Check if the array contains the string.
  private static arrayContains(array: string[], value: string): boolean {
    let found: boolean = false;

    array.forEach((item: string, index: number): void => {
      if (value.includes(item)) {
        found = true;
        return;
      }
    });

    return found;
  }

  private static encode(value: string): string {
    const token: string = this.getToken();
    if (token) {
      return aes.encrypt(value, token);
    }
    return null;
  }

  private static decode(value: string): any {
    const token: string = this.getToken();
    if (token) {
      return JSON.parse(aes.decrypt(value.toString(), token).toString(encUtf8));
    }
    return null;
  }

  private static getToken(): string {
    return $("meta[name='token']").attr("content") || null;
  }
}
