<template>
  <div class="fake-itinerary">
    <div class="fake-itinerary-airline"></div>
    <div class="fake-itinerary-price"></div>
    <div class="fake-itinerary-container">
      <table class="fake-itinerary-itinerary">
        <tr>
          <td class="fake-itinerary-item"></td>
          <td class="fake-itinerary-br"></td>
          <td class="fake-itinerary-item"></td>
        </tr>
        <tr class="fake-itinerary-br"></tr>
        <tr>
          <td class="fake-itinerary-item"></td>
          <td class="fake-itinerary-br"></td>
          <td class="fake-itinerary-item"></td>
        </tr>
      </table>
      <div class="fake-itinerary-br"></div>
      <table class="fake-itinerary-itinerary">
        <tr>
          <td class="fake-itinerary-item"></td>
          <td class="fake-itinerary-br"></td>
          <td class="fake-itinerary-item"></td>
        </tr>
        <tr class="fake-itinerary-br"></tr>
        <tr>
          <td class="fake-itinerary-item"></td>
          <td class="fake-itinerary-br"></td>
          <td class="fake-itinerary-item"></td>
        </tr>
      </table>
    </div>
    <div class="fake-itinerary-button"></div>
  </div>
</template>

<script>
export default {
};
</script>
