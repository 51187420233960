<template>
  <table>
    <tr>
      <td class="filter-caption" v-html="title"></td>
    </tr>
    <tr>
      <td class="filter-label" style="text-align: left;">
        {{ currentValues[0] | minutes_to_clock_with_day(dayNumber) }}
      </td>
      <td class="filter-label">
        {{ currentValues[1] | minutes_to_clock_with_day(dayNumber) }}
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <multi-slider ref="slider" :min-name="minName" :max-name="maxName" :values="currentValues" :min="min" :max="max"
                      :step="step" @change="onChange" @slide="onSlide" />
      </td>
    </tr>
  </table>
</template>

<script>
import MultiSlider from "./multi_slider";

export default {
  components: { MultiSlider },
  props: {
    title: {
      type: String,
      required: true
    },
    minName: {
      type: String,
      required: true
    },
    maxName: {
      type: String,
      required: true
    },
    step: Number | null,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1440
    },
    dayNumber: {
      type: Number | null,
      default: null
    }
  },
  data() {
    return {
      currentValues: [this.min, this.max]
    };
  },
  methods: {
    reset() {
      this.$refs.slider.reset();
      this.currentValues = this.$refs.slider.getValues();
      this.onChange(this, this.currentValues);
    },
    onSlide(sender, values) {
      this.currentValues = values;
    },
    onChange(sender, values) {
      this.currentValues = values;
      this.$emit("change", this);
    }
  }
};
</script>
