/* global gon */

import Vue from "vue";
import Vuex from "vuex";
import VueResource from "vue-resource";
import Honeybadger from "honeybadger-js";
import HoneybadgerVue from "@honeybadger-io/vue";

Vue.use(Vuex);
Vue.use(VueResource);

const config = {
    apiKey: "c1ed40c8",
    environment: "production",
    revision: "master"
};

Vue.use(HoneybadgerVue, config);

Vue.config.errorHandler = (err, vm, info) => {
    if (gon.debug || gon.development) {
        console.error(err);
        console.error(`Error occurred in ${info}`);
        console.dir(vm);
    }

    Honeybadger.notify(err, { info, vm });
};

Vue.config.warnHandler = (msg, vm, info) => {
    if (gon.debug || gon.development) {
        console.warn(msg);
        console.warn(`Warning occurred in ${info}`);
    }
};

Vue.http.interceptors.push(function (request, next) {
    request.headers.set("X-CSRF-TOKEN", document.querySelector("meta[name='csrf-token']").content);
    next();
});

import "./helpers/vue_filters";
