<template>
  <label :id="componentID()">
    <span class="checkbox-compoent" :class="{ 'selected': currentChecked }">
      <input type="checkbox" :name="name" value="1" v-model="currentChecked" />
    </span> <span>{{ title }}</span>
  </label>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    title: String
  },
  data() {
    return {
      uid: null,
      currentChecked: this.checked,
      skipWatch: false
    };
  },
  watch: {
    currentChecked: {
      handler(val, oldVal) {
        if (!this.skipWatch) {
          this.$emit("change", this, val);
        }
      }
    }
  },
  methods: {
    componentID() {
      return `cuid_${this.uid}`;
    },
    getValue() {
      return this.currentChecked;
    },
    setValue(value) {
      this.skipWatch = true;
      this.currentChecked = value;
      this.$nextTick(() => {
        this.skipWatch = false;
      });
    },
    reset() {
      this.setValue(this.checked);
    }
  },
  beforeMount() {
    this.uid = this._uid;
  }
};
</script>
