<template>
  <div class="fake-itinerary">
    <div class="fake-itinerary-airline"></div>
    <div class="fake-itinerary-price"></div>
    <div class="fake-itinerary-container">
      <table class="fake-itinerary-itinerary">
        <tr>
          <td class="fake-itinerary-item"></td>
          <td class="fake-itinerary-br"></td>
          <td class="fake-itinerary-item"></td>
        </tr>
        <tr class="fake-itinerary-br"></tr>
        <tr>
          <td class="fake-itinerary-item"></td>
          <td class="fake-itinerary-br"></td>
          <td class="fake-itinerary-item"></td>
        </tr>
      </table>
      <div class="fake-itinerary-br"></div>
      <table class="fake-itinerary-itinerary">
        <tr>
          <td class="fake-itinerary-item"></td>
          <td class="fake-itinerary-br"></td>
          <td class="fake-itinerary-item"></td>
        </tr>
        <tr class="fake-itinerary-br"></tr>
        <tr>
          <td class="fake-itinerary-item"></td>
          <td class="fake-itinerary-br"></td>
          <td class="fake-itinerary-item"></td>
        </tr>
      </table>
    </div>
    <div class="fake-itinerary-button"></div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
/* Placing the css here because it is scoped */

.fake-itinerary-airline,
.fake-itinerary-price,
.fake-itinerary-button,
.fake-itinerary-item {
  animation: fakeItineraryBgKeyFrame 1s infinite;
}

@keyframes fakeItineraryBgKeyFrame {
  0% {
    background-color: #ededed;
  }
  50% {
    background-color: #e6e6e6;
  }
  100% {
    background-color: #ededed;
  }
}
</style>
