<template>
  <div>
    <template v-if="$store.state.alternativeItinerary">
      <itinerary-air-alternative :itinerary="$store.state.alternativeItinerary" />
    </template>

    <div v-if="itineraries && itineraries.length === 0 && !$store.getters.searching && !loading">
      {{ "Alla resultat är bortfiltrerade." | locale }} <a id="reset-filter" href="#" @click="resetFilter">{{ "Klicka här för att rensa alla filter" | locale }}</a>.
    </div>
    <div v-else>
      <template v-for="(itinerary, index) in itineraries">
        <itinerary-hotel v-if="itinerary.attributes.itinerary_type == 'hotel'" :itinerary="itinerary" :key="itinerary.id" />

        <div v-if="hasBanners && (index % 4) == 0" :key="`banner_${index}`" class="banners_horizontal">
          <a target="_blank" :href="banners[(index / 4) % banners.length].link">
            <img :src="`/assets/banners_horizontal/${banners[(index / 4) % banners.length].image}` | cloudinary" />
          </a>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
/* global gon */
import ItineraryAirAlternative from "./itineraries/itinerary_air_alternative.vue";
import ItineraryHotel from "./itineraries/itinerary_hotel.vue";
import { ViewState } from "../store/defs";

export default {
  components: { ItineraryAirAlternative, ItineraryHotel },
  data() {
    return {
      loading: true,
      hasBanners: false
    };
  },
  computed: {
    itineraries() {
      return this.$store.state.hotelItineraries;
    },
    banners() {
      return this.$store.getters.hasHotelBanners ? this.$store.getters.hotelBanners : null;
    }
  },
  watch: {
    itineraries(newValue, oldValue) {
      this.loading = false;
    },
    banners(newValue, oldValue) {
      this.hasBanners = newValue && newValue.length > 0;
    }
  },
  methods: {
    resetFilter() {
      $(document).trigger("resetFilter");
    }
  },
  mounted() {
    if (gon.links.flight && window.location.pathname === gon.links.flight) {
      this.$store.dispatch("updateViewState", ViewState.air);
    }
  }
};
</script>
