<template>
  <form id="filter_form" style="width: 290px;" @submit="onSubmit">
    <input name="utf8" type="hidden" value="✓">
    <input type="hidden" name="sequence" id="sequence" value="0">
    <input type="hidden" name="filter_view" id="filter_view" value="list">
    <input type="hidden" name="meta_uuid" id="meta_uuid">

    <template v-if="filterData && filterData.hotels">
      <hotel-maps :hotels="filterData.hotels" />
    </template>

    <div style="display: none;" id="map-canvas-all">
      <div id="map-canvas-inner"></div>
    </div>

    <ul class="filter">
      <li key="header" class="filter-section filter-section-main">
        <div class="filter-header">
          <div class="filter-header-text">
            <span>{{ "Filtrera resultatet" | locale }}</span>
          </div>
          <div class="filter-header-actions">
            <input type="button" :value="'Återställ' | locale" @click="onReset" />
          </div>
        </div>

        <div class="filter-content">
          <table>
            <tr>
              <td class="filter-caption">{{ "Pris" | locale }}</td>
            </tr>
            <tr>
              <td><label id="filter_price_txt_min">{{ filterPrice[0] | currency }}</label></td>
              <td><label id="filter_price_txt_max">{{ filterPrice[1] | currency }}</label></td>
            </tr>
            <tr>
              <td colspan="2" v-if="filterData">
                <multi-slider minName="filter_price_min" maxName="filter_price_max" @slide="filterPriceChange" @change="onChange"
                              :min="filterData.min_price" :max="filterData.max_price" />
              </td>
            </tr>

            <tr>
              <td>{{ "Hotellnamn" | locale }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <input type="text" name="filter_name" id="filter_name" placeholder="Ex. Radison" class="hotelname" @change="onChange">
              </td>
            </tr>
          </table>
        </div>
      </li>

      <!-- Stjärnor -->
      <search-filter-item ref="starsTab" v-if="filterData && filterData.stars" :title="'Stjärnor' | locale" selectLinks>
        <ul class="checkbox-list">
          <li v-for="n in filterData.stars" :key="`stars_${n.rating}`">
            <checkbox :name="`stars[${n.rating}]`" :title="n.fulltext" checked @change="onChange" />
            <span class="only-button" @click="onlyCheckbox($refs.starsTab, `stars[${n.rating}]`)">{{ "bara" | locale }}</span>
          </li>
        </ul>
      </search-filter-item>

      <!-- Mältider -->
      <search-filter-item ref="mealTab" v-if="filterData && filterData.boardtypes" :title="'Måltider' | locale" selectLinks>
        <ul class="checkbox-list">
          <li v-for="n in filterData.boardtypes" :key="`meal_${n.code}`">
            <checkbox :name="`meal[${n.code}]`" :title="n.fulltext" checked @change="onChange" />
            <span class="only-button" @click="onlyCheckbox($refs.mealTab, `meal[${n.code}]`)">{{ "bara" | locale }}</span>
          </li>
        </ul>
      </search-filter-item>

      <!-- Faciliteter -->
      <search-filter-item ref="facilityTab" v-if="filterData && filterData.facilities" :title="'Faciliteter' | locale" selectLinks closed>
        <ul class="checkbox-list">
          <li v-for="n in filterData.facilities" :key="`facilities_${n.code}`">
            <checkbox :name="`facility[${n.code}]`" :title="n.fulltext" checked @change="onChange" />
            <span class="only-button" @click="onlyCheckbox($refs.facilityTab, `facility[${n.code}]`)">{{ "bara" | locale }}</span>
          </li>
        </ul>
      </search-filter-item>

      <!-- Stadsdel -->
      <search-filter-item ref="areascodeTab" v-if="filterData && filterData.areas_codes" :title="'Stadsdel' | locale" selectLinks closed>
        <ul class="checkbox-list">
          <li v-for="n in filterData.areas_codes" :key="`areas_codes_${n.code}`">
            <checkbox :name="`areas_code[${n.code}]`" :title="n.fulltext" checked @change="onChange" />
            <span class="only-button" @click="onlyCheckbox($refs.areascodeTab, `areas_code[${n.code}]`)">{{ "bara" | locale }}</span>
          </li>
        </ul>
      </search-filter-item>

      <div class="tripadvisor_powered_by">
        <div class="tripadvisor_powered_by_text">
          Ratings and Reviews<br />
          Powered by
        </div>
        <div class="tripadvisor_powered_by_image">
          <a href="http://www.tripadvisor.se/">
            <img src="//www.tripadvisor.com/img/cdsi/langs/en/tripadvisor_logo_transp_280x60-MCID-0.png" target="_blank" />
          </a>
        </div>
      </div>

      <!-- Banner -->
      <div v-if="$store.state.banner_filter" class="filter_banner sv-SE"></div>
    </ul>
  </form>
</template>

<script>
import SearchFilter from "./search_filter";
import HotelMaps from "app/form/hotel_maps";

export default {
  extends: SearchFilter,
  components: { HotelMaps },
  methods: {
    onlyCheckbox(tab, name) {
      tab.setAllCheckboxes(false, false);
      tab.$children.forEach((c) => {
        if (c.name === name) {
          c.setValue(true);
        }
      });
      this.onChange(null);
    }
  }
};
</script>

<style>
.checkbox-list li:hover .only-button {
  display: inline-block;
}
.checkbox-list .only-button {
  display: none;
  padding-left: 5px;
  color: darkgray;
  font-weight: lighter;
}
.checkbox-list .only-button:hover {
  color: lightgray;
  cursor: pointer;
}
</style>
