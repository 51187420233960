
export enum SearchType {
  air         = 0,
  hotel       = 1,
  package     = 2,
  noSearch    = 10
}

export enum ViewState {
  air         = 0,
  hotel       = 1
}
