<template>
  <li :id="componentID()" class="filter-section filter-collapsable" :class="{ 'filter-open': open, 'filter-closed': !open }">
    <a href="javascript:;" class="filter-header" @click="toggleOpen">{{ title }}</a>

    <div class="actions" v-if="selectLinks">
      <a href="javascript:;" @click="selectAll">{{ 'alla' | locale }}</a> / <a href="javascript:;" @click="unselectAll">{{ 'ingen' | locale }}</a>
    </div>

    <div class="filter-content">
      <slot />
    </div>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    selectLinks: Boolean,
    closed: Boolean
  },
  data() {
    return {
      uid: null,
      open: !this.closed
    };
  },
  methods: {
    componentID() {
      return `cuid_${this.uid}`;
    },
    toggleOpen() {
      this.open = !this.open;
    },
    selectAll() {
      this.setAllCheckboxes(true);
    },
    unselectAll() {
      this.setAllCheckboxes(false);
    },
    setAllCheckboxes(value, runFilter = true) {
      this.$store.state.lockFilter = true;

      for (var key in this.$children) {
        if ({}.hasOwnProperty.call(this.$children, key)) {
          let comp = this.$children[key];
          if (comp.$options._componentTag === "checkbox") {
            comp.setValue(value);
          }
        }
      }
      this.$store.state.lockFilter = false;
      if (runFilter) {
        this.$nextTick(() => { // Allow the form inputs to update.
          $(document).trigger("runFilter");
        });
      }
    }
  },
  beforeMount() {
    this.uid = this._uid;
  }
};
</script>

<style>
.filter-section.filter-closed .actions {
  display: none;
}

.filter-section .actions {
  font-size: 12px;
  float: right;
  padding: 0 20px;
  pointer-events: initial;
}

.filter-section .actions a {
  color: white;
}
</style>
