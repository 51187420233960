/* global skyscannerDvTag */

import Cookies from 'js-cookie'

const trackingSourceId = "DV-889071-1"

/**
 * Verifies if the user comes from Skyscanner.
 *
 * @returns {boolean} - Information if the user comes from the Skyscanner.
 */
export function isSkyscanner() {
    const market = document.getElementsByTagName("body")[0].getAttribute("market");
    return market === "skyscanner";
}

// Imports the Skyscanner Tag code. It's supposed to be loaded only for Skyscanner's gate
$(function () {
    if (isSkyscanner()){
        (function(s,k,y,t){s[t]=s[t]||function(){ (s[t].buffer=s[t].buffer||[]).push(arguments)};s[t].u=y;var l=k.createElement("script");l.src=y;l.id=t;l.async=1;var h=k.getElementsByTagName("head")[0];h.appendChild(l); })(window,document,'https://analytics.skyscanner.net/dv/v1/tag.js','skyscannerDvTag');
    }
});

/**
 * Parses the itinerary price from string to float with two decimal points
 *
 * @param {string} string - The string to be parsed as a float.
 * @param {string} currency - The currency code used to determine the parsing strategy.
 * @returns {string} - The transformed price based on the currency.
 */
function stringToFloat(string, currency) {
    const price = string.match(/\d+/g).join("");

    if (['EUR', 'CHF'].includes(currency)) {
        return (parseFloat(price) / 100).toFixed(2);
    } else {
        return (parseFloat(price)).toFixed(2);
    }
}

/**
 * Builds the Conversion Event payload for the Skyscanner's analytics.
 *
 * @param {string} adultNumber - The number of adult passengers.
 * @param {string} cabinClass - The cabin class of the flight.
 * @param {string} childNumber - The number of child passengers.
 * @param {string} conversionId - The conversion identifier.
 * @param {string} currency - The currency used for the transaction.
 * @param {string} fareBasisCodes - The fare basis codes for the flight.
 * @param {string} flightFare - The fare amount for the flight.
 * @param {string} infantNumber - The number of infant passengers.
 * @param {string} legs - The legs of the flight.
 * @param {string} marketingCarrier - The marketing carrier of the flight.
 * @param {string} paymentMethod - The payment method used for the transaction.
 * @param {string} routeType - The type of route for the flight - oneway/return/multi.
 * @param {string} transactionTotal - The total amount of the transaction.
 *
 * @returns {Object} - The Conversion Event payload.
 */
export function buildConversionData(
    adultNumber,
    cabinClass,
    childNumber,
    conversionId,
    currency,
    fareBasisCodes,
    flightFare,
    infantNumber,
    legs,
    marketingCarrier,
    paymentMethod,
    routeType,
    transactionTotal
) {
    return {
        "skyscanner_redirectid": Cookies.get('skyscanner_redirect_id'),
        "tracking_source_id": trackingSourceId,
        "event_type": "conversion",
        "conversion_id": conversionId,

        "transaction_total": transactionTotal,
        "flight_fare": flightFare,
        "currency": currency,
        "payment_method": paymentMethod,

        "fare_basis_code": fareBasisCodes,
        "marketing_carrier": marketingCarrier,

        "legs": legs,
        "cabin_class": cabinClass,
        "route_type": routeType,

        "platform": "web",

        "adult_number": adultNumber,
        "child_number": childNumber,
        "infant_number": infantNumber
    };
}

/**
 * Builds the Pre-Conversion Event payload for the Skyscanner's analytics
 * @param {string} currency - The currency of the price
 * @param {string} fareBasisCodes - The fare basis codes
 * @param {string} marketingCarriers - The marketing carriers
 * @param {string} price - The price
 * @param {string} stage - The stage of the conversion
 *
 * @returns {Object} - The Pre-Conversion Event payload
 */
export function buildPreConversionData(
    currency,
    fareBasisCodes,
    marketingCarriers,
    price,
    stage
) {
    return {
        "skyscanner_redirectid": Cookies.get('skyscanner_redirect_id'),
        "tracking_source_id": trackingSourceId,
        "event_type": "pre-conversion",
        "stage": stage,

        "price": stringToFloat(price, currency),
        "currency": currency,

        "fare_basis_code": fareBasisCodes,
        "marketing_carrier": marketingCarriers,

        "platform": "web",
    };
}

/**
 * Sends the Pre-Conversion Event to the Skyscanner.
 *
 * @param {Object} payload - The payload containing the pre-conversion data.
 * @returns {boolean} - Returns true if the event was sent successfully, otherwise false.
 */
export function sendPreConversionEvent(payload) {
    if(!isSkyscanner()) return false;
    if(!Cookies.get('skyscanner_redirect_id')) return false;

    skyscannerDvTag("init", trackingSourceId);
    skyscannerDvTag("send", "pre-conversion", payload);

    return true;
}

/**
 * Sends the Conversion Event to the Skyscanner
 *
 * @param {Object} payload - The payload containing the conversion data.
 * @returns {boolean} - Returns true if the event is sent successfully, otherwise false.
 */
export function sendConversionEvent(payload) {
    if(!isSkyscanner()) return false;
    if(!Cookies.get('skyscanner_redirect_id')) return false;

    skyscannerDvTag("init", trackingSourceId);
    skyscannerDvTag("send", "conversion", payload);

    Cookies.remove('skyscanner_redirect_id');

    return true;
}
