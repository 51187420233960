<template>
  <div>
    <div v-if="panelEnabled" class="debug-panel" :style="{ 'height': panelHeight + 'px' }">
      <div class="header">
        <div class="tabs">
          <ul>
            <li v-for="tabComp in tabComponents" :key="`tab_${tabComp.tabOptions.title}`" class="debug-tab" @click="tabClicked(tabComp)"
                :class="{ 'active': activeTab === tabComp }">{{ tabComp.tabOptions.title }}</li>
          </ul>
        </div>

        <div class="extra_options">
          <span style="margin: 0 20px;">
            Showing {{ $store.state.flightItinerariesCount }} of {{ $store.state.flightItinerariesCountMax }} results
            <template v-if="$store.state.flightItinerariesCountRemoved && $store.state.flightItinerariesCountRemoved > 0">({{ $store.state.flightItinerariesCountRemoved }} removed)</template>
          </span>

          <!-- <input type="checkbox" name="show_removed_flights" id="show_removed_flights" value="1" @change="showRemovedItineraries">
          <label for="show_removed_flights">Show removed itineraries</label> -->

          <label v-if="debugData">uuid: {{ debugData.uuid }}</label>

          <a v-bind:href="'https://admin.travex.se/logs/searches/'+ this.searchId" target="_blank">Open in Falco</a>

        </div>

        <div class="controls">
          <a href="javascript:;" @click="hidePanel">hide</a>
        </div>
      </div>

      <div class="debug-info" style="background-color: #e8e8e8;">
        <div v-if="tabComponents.length === 0" class="debug-info-message">
          Move the mouse over an itinerary to show the debug information.
        </div>

        <keep-alive v-if="activeTab">
          <component v-bind:is="activeTab" :info="debugData"></component>
        </keep-alive>
      </div>

      <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;" @mousedown="panelMouseDown"></div>
    </div>
    <div v-else>
      <div class="debug-button" @click="showPanel">Debug</div>
    </div>
  </div>
</template>

<script>
import DebugTabPrice          from "./debug_tab_price.vue";
import DebugTabSegment        from "./debug_tab_segment.vue";
import DebugTabAncillaries    from "./debug_tab_ancillaries.vue";
import DebugTabHotel          from "./debug_tab_hotel.vue";
import DebugTabPresentation   from "./debug_tab_presentation.vue";
import DebugTabVue            from "./debug_tab_vue.vue";

import { ViewState } from "app/store/defs";
import { windowHeight } from "app/helpers";

export default {
  components: { DebugTabPrice, DebugTabSegment, DebugTabAncillaries,
                DebugTabPresentation, DebugTabHotel, DebugTabVue },
  data() {
    return {
      panelEnabled: false,
      panelHeight: 200,
      panelResizeHandler: null,
      tabComponents: [],
      activeTab: null,
      debugUUID: null,
      debugData: null
    };
  },
  computed: {
    filterData() {
      switch (this.$store.getters.viewState) {
        default:
        case ViewState.air: return this.$store.state.flightFilter;
        case ViewState.hotel: return this.$store.state.hotelFilter;
      }
    },
    itineraries() {
      switch (this.$store.getters.viewState) {
        default:
        case ViewState.air: return this.$store.state.flightItineraries;
        case ViewState.hotel: return this.$store.state.hotelItineraries;
      }
    }
  },
  watch: {
    itineraries() {
      this.$nextTick(() => {
        this.attachDebugger();
      });
    }
  },
  methods: {
    tabClicked(tabComp) {
      this.activeTab = tabComp;
    },
    showPanel() {
      this.panelEnabled = true;
    },
    hidePanel() {
      this.panelEnabled = false;
    },
    updateValidTabs() {
      var comps = [];
      for (var compName in this.$options.components) {
        const comp = this.$options.components[compName];
        if (comp.tabOptions && comp.tabOptions.canShow(this.debugData)) {
          comps.push(comp);
        }
      }
      this.tabComponents = comps;
      this.setDefaultTab();
    },
    setDefaultTab() {
      if (this.activeTab === null) {
        const tabs = this.tabComponents;
        const tabKeys = Object.keys(tabs);
        if (tabKeys.length > 0) {
          this.activeTab = tabs[tabKeys[0]];
        }
      }
    },
    loadItinerary(uuid, uuidAlt) {
      if (this.debugUUID !== uuid) {
        this.debugUUID = uuid;
        // console.log(`load itinerary ${uuid}`);

        this.$store.state.searchChannel.perform("load_debug_info", {
          uuid: uuid, alt_uuid: uuidAlt
        });
      }
    },
    showRemovedItineraries(eventObject) {
      this.$store.state.loadHidden = eventObject.target.value === "1";
      this.$store.dispatch("runFilter");
    },
    attachDebugger() {
      const elements = $(".itinerary[data-uuid]:not(.debugger-attached)");
      elements.each((index, elem) => {
        elem.classList.add("debugger-attached");
      });
      elements.on("mouseenter", (eventObject) => {
        const uuid = eventObject.currentTarget.getAttribute("data-uuid-raw");
        const uuidAlt = eventObject.currentTarget.getAttribute("data-uuid-raw-alt");
        this.loadItinerary(uuid, uuidAlt);
      });
    },

    panelMouseDown(eventObject) {
      this.panelResizeHandler = this.panelResize.bind(this);
      document.addEventListener("mousemove", this.panelResizeHandler, false);
      document.body.classList.add("noselect");
    },
    panelMouseUp(eventObject) {
      document.removeEventListener("mousemove", this.panelResizeHandler, false);
      document.body.classList.remove("noselect");
    },
    panelResize(eventObject) {
      const newHeight = windowHeight() - eventObject.y - 12;
      if (newHeight < 500 && newHeight > 50) {
        this.panelHeight = newHeight;
      }
    }
  },
  mounted() {
    this.attachDebugger();

    // Bind the global resize end
    const self = this;
    document.addEventListener("mouseup", () => self.panelMouseUp(), false);

    this.$store.state.searchChannel.onReceivedPacket.on((packet) => {
      if (packet.type === "debug_info") {
        this.debugUUID = packet.data.uuid;
        this.debugData = packet.data;
        this.searchId = gon.search['id']
        self.updateValidTabs();
      }
    });
  }
};
</script>

<style scoped>
/*
$color1: #7f7f7f;
$color2: #E8E8E8;
$color3: #5D5D5D;
$color4: #A0A0A0;
$color5: #AEAEAE;
$color6: #C4C4C4;
$color7: #E2E2E2;
$color8: #B0AFAF;
*/

.debug-panel {
  position: fixed;
  display: block;
  color: #000000;
  background-color: #aeaeae;
  border-top: 1px solid #7f7f7f;
  font-size: 12px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.debug-panel .header {
  border-bottom: 1px solid #7f7f7f;
  height: 28px;
  padding: 5px 5px 0 7px;
  clear: left;
  font-size: 14px;
}

.debug-panel .header .title,
.debug-panel .header .tabs,
.debug-panel .header .extra_options {
  float: left;
}

.debug-panel .header .controls {
  float: right;
}

.debug-panel .header .tabs li {
  float: left;
  background-color: #C4C4C4;
  padding: 0 15px 3px;
  margin-right: 5px;
  font-size: 12px;
  border-top-left-radius: 3px;
  -webkit-border-top-righht-radius: 3px;
  border-top-right-radius: 3px;
  margin-top: 1px;
  cursor: pointer;
}

.debug-panel .header .tabs li.active {
  background-color: #E8E8E8;
  border: 1px solid #7f7f7f;
  border-bottom: none;
}

.debug-panel .debug-info {
  height: 100%;
  background-color: #FFFFFF;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.debug-button {
  color: #000000;
  font-size: 12px;
  background-color: #E8E8E8;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 50px;
  height: 30px;
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius-radius: 3px;
  text-align: center;
  padding-top: 5px;
  border-top: 1px solid #5D5D5D;
  border-left: 1px solid #5D5D5D;
}

.debug-button:hover {
  background-color: #A0A0A0;
  cursor: pointer;
}

.debug-info-message {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 20px;
}
</style>
