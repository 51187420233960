<!-- Create fake baggege checkboxes. Prices from standard fare and light price diff. -->
<template>
  <div class="baggage" v-if="has_fake_baggage_ancillaries">
    <div class="headline">{{ "Bagage:" | locale }}</div>
    <div class="passengers">
      <div
        class="passenger"
        v-for="bag_itinerary in fake_baggage_ancillaries"
      >
        <div v-if="bag_itinerary.pax_type != 'INF'">
          <div class="passenger_number">{{ translatePax(bag_itinerary, itinerary.attributes.passenger_type) }}</div>

          <div class="leg-container" style="width: 380px; float: left;">
            <div class="leg">
              <div class="ancillary" style="display: inline-block; clear: both;">
                <input
                  type="checkbox"
                  :name="fetch_ancillary_id(bag_itinerary)"
                  :id="fetch_ancillary_id(bag_itinerary)"
                  value="1"
                  :data-price="bag_itinerary.std_price_raw_pax"
                  :data-price-pax="bag_itinerary.std_price_raw_pax"
                  :data-ancillary-type="'fake'"
                  v-model="bag_itinerary.selected"
                  @change="$emit('change')"
                >
                <label :for="fetch_ancillary_id(bag_itinerary)">{{ translateBag(bag_itinerary, 'ancillary') }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translate } from "app/helpers";

const ancillaryText = {
  "BAG":    "Hela resan checkin %{weight}kg +%{amount}",
  "CABIN":  "Hela resan cabin %{weight}kg +%{amount}"
};

export default {
  name: "baggage",
  props: {
    /**
     * The itinerary.
     */
    itinerary: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      /** Cache the ids as we use it quite a lot. */
      ancillary_cache_ids: {}
    };
  },
  computed: {
    /**
     * Returns the fake baggage object from the itinerary.
     *
     * TODO: In the future this shouldn't have access to itinerary.
     */
    fake_baggage_ancillaries() {
      return this.itinerary.attributes.template.fake_baggage_ancillaries;
    },
    /**
     * Returns whether we have fake baggage ancillaries available.
     *
     * @returns {boolean}
     */
    has_fake_baggage_ancillaries() {
      return Object.keys(this.itinerary.attributes.template.fake_baggage_ancillaries).length !== 0
    }
  },
  methods: {
    /**
     * Returns the ancillary id.
     *
     * @param ancillary
     * @returns {string}
     */
    fetch_ancillary_id(ancillary) {
      /*
      if (typeof this.ancillary_cache_ids[ancillary.id] !== "undefined" || this.ancillary_cache_ids[ancillary.id] === null) {
          return this.ancillary_cache_ids[ancillary.id];
      }*/
      const uuid = this.itinerary.attributes.uuid;
      return this.ancillary_cache_ids[ancillary.id] = `baggage[${uuid}][${ancillary.id}]`;
    },
    /**
     * Returns the pax header text.
     *
     * @param bag_itinerary
     * @returns {string}
     */
    translatePax(bag_itinerary, type) {
      let args = [bag_itinerary.pax_number, translate(bag_itinerary.pax_type)];
      if (type == "ADT" && bag_itinerary.pax_type == "YTH") {
        args = [bag_itinerary.pax_number, translate("ADT")];
      }

      return translate("Resenär %{num}, %{type}", ...args);
    },

    /**
     * Returns the bag text.
     *
     * @param bag_itinerary
     * @param ancillary
     * @returns {string}
     */
    translateBag(bag_itinerary, ancillary) {
      const args = [23, bag_itinerary.std_price_pax];

      return translate(ancillaryText["BAG"] || ancillaryText["BAG"], ...args)
    },

    cabinBagWidth(ancillaries) {
      if (ancillaries.some(e => e["key"] == "CABIN")) {
        return 300
      } else {
        return 170
      }
    },
  }
}
</script>
