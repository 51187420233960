import store from "../store";
import { Channel, ChannelProtocol, ChannelTimer, ChannelExecuteDelay } from "./channel";
import { translate, showAlert, showNoResultsAlert, showServerTimeoutAlert, EventHandler, Event } from "../../helpers";
import { Track } from "../../../components/common/track";

declare const gon: any;

// Toggle the searchfield overlay with the current search type text.
function searchfieldOverlay(hide: boolean): void {
  const query: string = `.search_box_overlay, \
                         .search_box_overlay_top, \
                         .search_box_overlay_top .${gon.search.search_type}`;

  if (hide) {
    $(query).hide();
  } else {
    $(query).show();
  }
}

export class SearchChannel extends Channel {
  public readonly onReceivedPacket: EventHandler<Event> = new EventHandler<Event>();

  private statusTimer: ChannelTimer;
  private itinerariesTimer: ChannelTimer;
  private filterRunDelay: ChannelExecuteDelay;
  private hasReceivedItineraries: boolean = false;

  constructor() {
    super("SearchChannel", { meta_test: gon.meta_test });

    this.filterRunDelay = new ChannelExecuteDelay(this, 200);

    this.statusTimer = new ChannelTimer((searchChannel: SearchChannel): void => {
      showServerTimeoutAlert("status");
    }, 30, this);

    // When the status timer is stopped it means that we have received "ready"
    this.statusTimer.onStop = () => {
      if (!this.hasReceivedItineraries) {
        this.itinerariesTimer = new ChannelTimer((searchChannel: SearchChannel): void => {
          showServerTimeoutAlert("itineraries");
        }, 10, this);
      }
    }

    searchfieldOverlay(false);

    // Set the search status because we now recieve the status call from the backend.
    store.commit("searchStatus", "searching");
  }

  public status(): void {
    this.perform("status");
  }

  /**
   * Run the filter.
   * @param filter
   */
  public runFilter(filter: any): void {
    if (store.state.searchStatus) {
      this.filterRunDelay.run(() => {
        this.perform("run_filter", {
          view_state: store.getters.viewState,
          filter: filter
        });
      });
    }
  }

  /**
   * Load more itineraries.
   * @param offset
   */
  public loadMoreResults(offset: number = 0): void {
    if (store.state.searchStatus) {
      this.perform("load_more", {
        offset: offset,
        view_state: store.getters.viewState,
        filter: $("#filter_form").serialize()
      });
    }
  }

  public runFilterData(): void {
    if (store.state.searchStatus) {
      this.perform("filter_data", {
        view_state: store.getters.viewState
      });
    }
  }

  protected getCableUrlQuery(): string {
    return `search_id=${gon.search.id}`;
  }

  protected onConnected(protocol: ChannelProtocol): void {
    if (protocol === ChannelProtocol.Http) {
      this.status();
    }
  }

  protected onDisconnected(): void {

  }

  protected onReceived(resp: any): boolean {
    switch (resp.action) {
      case "status": {
        store.commit("searchStatus", resp.items);

        if (resp.items === "ready") {
          searchfieldOverlay(true);
        }

        this.statusTimer.restart();

        if (resp.items === "ready") {
          if (this.getProtocol() === ChannelProtocol.WebSocket) {
            this.statusTimer.stop();
          } else {
            this.statusTimer.stop();
            this.perform("filter_data");
            this.perform("run_filter");
          }
        } else if (resp.items === "error") {
          if (resp.search_error) {
            showAlert(
              "status-response",
              translate("Något är fel!"),
              resp.search_error,
              "/");
          } else {
            showNoResultsAlert("status-response", resp.youth);
          }
        } else {
          const self = this;
          setTimeout(() => self.status(), 1000);
        }

        break;
      }

      case "itineraries": {
        this.setItineraries("updateItineraries", resp.items);
        break;
      }

      case "loadMoreItineraries": {
        store.commit("updateItineraries", { type: "append", data: resp.items });
        break;
      }

      case "filter_data": {
        store.commit("updateFilterData", resp.items);
        break;
      }

      case "redirect": {
        location.href = resp.url;
        break;
      }

      default:
        this.onReceivedPacket.trigger(new Event(resp.action, resp.items));
        return false;
    }
    return true;
  }

  private setItineraries(type: string, payload: any): void {
    this.hasReceivedItineraries = true;
    if (this.itinerariesTimer) {
      this.itinerariesTimer.stop();
    }

    store.commit(type, { type: "set", data: payload });
  }
};
