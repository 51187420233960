import Vue from "vue";
import { SearchChannel } from "./cable/search_channel";
import { ViewState } from "./defs";

declare const gon: any;

export default {
  updateGon({ commit }) {
    commit("updateGon", gon);
  },

  createSearchChannel({ state }) {
    if (state.searchChannel === null) {
      state.searchChannel = new SearchChannel();
    }
  },

  runFilter({ state, getters }, filter = null) {
    if (state.lockFilter) {
      return;
    }

    if (filter === null) {
      filter = $("#filter_form").serialize();
    }

    if (state.loadHidden) {
      filter += "&debug=1";
    }

    state.flightItinerariesOffset = 0;
    state.loadMoreResultsRunning = false;
    getters.searchChannel.runFilter(filter);
  },
  loadMoreResults({ state, getters }) {
    var currentOffset = 0;
    switch (getters.viewState) {
      case ViewState.air:
        currentOffset = state.flightItinerariesOffset;
        break;

      case ViewState.hotel:
        currentOffset = state.hotelItinerariesOffset;
        break;
    }

    if (currentOffset !== 0) {
      getters.searchChannel.loadMoreResults(currentOffset);
    } else {
      state.loadMoreResultsRunning = false;
    }
  },

  updateViewState({ state, getters }, viewState: ViewState | null) {
    state.alternativeViewState = viewState;

    Vue.nextTick(() => {
      getters.searchChannel.runFilterData();
      getters.searchChannel.runFilter(null);
    });
  }
};
