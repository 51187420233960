import "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
import "air-datepicker/dist/css/datepicker.min.css";
import "air-datepicker/dist/js/datepicker.min.js";
import "font-awesome/css/font-awesome.css";
import "jquery-ui";
import "jquery-ui/ui/widgets/autocomplete";
import "jquery-ui/ui/widgets/selectmenu";
import "jquery-ui/ui/widgets/slider";

import "popper.js";
import "bootstrap/dist/js/bootstrap.min.js";

import "tooltipster";
import "tooltipster/dist/css/tooltipster.bundle.min.css";

import Honeybadger from "honeybadger-js";
import $ from "jquery";
import {} from "jquery-ujs";

import "../../components/desktop/searchfield/languages";
import "../../dep/jquery.resizeandcrop";
import "../../dep/gon";

import { Track } from "../../components/common/track";
import { Translation } from "../../components/common/translation";

Honeybadger.configure({
  apiKey: "780ab0c8",
  environment: process.env.ENVIRONMENT || "development",
  revision: "master",
  disabled: !(process.env.HB_DISABLED === "false"),
  maxErrors: 100
});

Honeybadger.beforeNotify(function (notice) {
  if (/api.tiles.mapbox.com|cdn.livechatinc.com/.test(notice.stack)) {
    return false;
  }
});

$(document).ready(function() {
  Track.initialize();

  if ($("#alert-message").length > 0) {
    $.fancybox.open({ src: "#alert-message" });
  }

  if ($(".languages").length > 0) {
    $(".languages").tooltipster({
      trigger: "custom",
      triggerOpen: {
        click: true
      },
      triggerClose: {
        click: true,
        scroll: true
      },
      contentAsHTML: true,
      placement: "auto",
      autoClose: false,
      content: $("#language_dropdown_window").html(),
      functionReady(instance, helper) {
        $(`#${instance.__namespace}`).addClass("tooltipster-clickable");
      }
    });
  }
});
