<template>
  <form id="filter_form" style="width: 290px;" @submit="onSubmit">
    <input name="utf8" type="hidden" value="✓">
    <input type="hidden" name="sequence" id="sequence" value="0">
    <input type="hidden" name="filter_order_by" id="filter_order_by" value="price">
    <input type="hidden" name="meta_uuid" id="meta_uuid">

    <ul class="filter">
      <li key="header" class="filter-section filter-section-main">
        <div class="filter-header">
          <div class="filter-header-text">
            <span>{{ "Filtrera resultatet" | locale }}</span>
          </div>
          <div class="filter-header-actions">
            <input type="button" :value="'Återställ' | locale" @click="onReset" />
          </div>
        </div>

        <div class="filter-content">
          <table>
            <tr>
              <td class="filter-caption">{{ "Endast direktflyg" | locale }}</td>
              <td class="filter-label"><switch-button name="filter_direct" ref="directFlightButton" @change="onChangeDirect" /></td>
            </tr>

            <tr>
              <td class="filter-caption">{{ "Inkl incheckat bagage" | locale }}</td>
              <td class="filter-label"><switch-button name="filter_inc_bagage" @change="onChange" /></td>
            </tr>

            <tr>
              <td class="filter-caption">{{ "Utan flygplatsbyte" | locale }}</td>
              <td class="filter-label"><switch-button name="filter_airport_transfer" @change="onChangeAirportTransfer" /></td>
            </tr>

            <!-- Total restid -->
            <template v-if="filterData">
              <tr>
                <td class="filter-caption">{{ "Total restid" | locale }}</td>
              </tr>
              <tr class="filter-label-group">
                <td class="filter-label">{{ minTraveltime | minutes_to_hours_and_minutes }}</td>
                <td class="filter-label">{{ maxTraveltime | minutes_to_hours_and_minutes }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <slider name="max_traveltime" :value="filterData.max_traveltime" @change="onChange" @slide="maxTraveltimeChange"
                          :min="filterData.min_traveltime" :max="filterData.max_traveltime" :step="1" />
                </td>
              </tr>
            </template>
          </table>
        </div>
      </li>

      <template v-if="filterData && filterData.legs">
        <search-filter-item v-for="(leg, legIndex) in filterData.legs" :key="`leg_${legIndex}`" :title="`${leg.from.city} - ${leg.to.city}`">
          <table>
            <!-- Antal byten -->
            <tr>
              <td class="filter-caption">{{ "Antal byten" | locale }}</td>
            </tr>
            <tr class="filter-label-group">
              <td class="filter-label">{{ minMaxStops[legIndex] }} {{ "byten" | locale }}</td>
              <td class="filter-label">{{ maxMaxStops[legIndex] }} {{ "byten" | locale }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <slider :name="`filter_stops[${legIndex}]`" :value.sync="maxMaxStops[legIndex]" @change="onChangeMaxStops" @slide="maxStopsChange" :context="legIndex"
                        :min="filterData.min_stops[legIndex]" :max="filterData.max_stops[legIndex]" />
              </td>
            </tr>

            <!-- Restid -->
            <tr>
              <td class="filter-caption">{{ "Restid" | locale }}</td>
            </tr>
            <tr class="filter-label-group">
              <td class="filter-label">{{ minTraveltimeSeg[legIndex] | minutes_to_hours_and_minutes }}</td>
              <td class="filter-label">{{ maxTraveltimeSeg[legIndex] | minutes_to_hours_and_minutes }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <slider :name="`filter_travel_time_leg[${legIndex}]`" :value="filterData.max_traveltime_segments[legIndex]" @change="onChange" @slide="maxTraveltimeSegChange" :context="legIndex"
                        :min="filterData.min_traveltime_segments[legIndex]" :max="filterData.max_traveltime_segments[legIndex]" :step="1" />
              </td>
            </tr>

            <!-- Avgång -->
            <tr>
              <td colspan="2">
                <time-range-slider :title="legTextAirportFrom('Avgång', leg)" :min-name="`filter_departure_time_min[${legIndex}]`" :max-name="`filter_departure_time_max[${legIndex}]`"
                                   @change="onChange" :min="filterDepartureTime[legIndex][0]" :max="filterDepartureTime[legIndex][1]" :dayNumber="leg.from_date_wday" />
              </td>
            </tr>

            <!-- Ankomst -->
            <tr>
              <td colspan="2">
                <time-range-slider :title="legTextAirportTo('Ankomst', leg)" :min-name="`filter_arrival_time_min[${legIndex}]`" :max-name="`filter_arrival_time_max[${legIndex}]`"
                                   @change="onChange" :min="filterArrivalTime[legIndex][0]" :max="filterArrivalTime[legIndex][1]" :dayNumber="leg.from_date_wday" />
              </td>
            </tr>
          </table>
        </search-filter-item>
      </template>

      <!-- Allianser -->
      <search-filter-item ref="allicanceTab" v-if="filterData && filterData.alliances" :title="'Allianser' | locale" selectLinks>
        <ul class="checkbox-list">
          <li v-for="alliance in filterData.alliances" :key="`alliance_${alliance[1]}`">
            <checkbox :name="`alliance[${alliance[1]}]`" :title="alliance[0]" checked @change="onChange" />
            <span class="only-button" @click="onlyCheckbox($refs.allicanceTab, `alliance[${alliance[1]}]`)">{{ "bara" | locale }}</span>
          </li>
        </ul>
      </search-filter-item>

      <!-- Flygbolag -->
      <search-filter-item ref="airlineTab" v-if="filterData && filterData.airlines" :title="'Flygbolag' | locale" selectLinks>
        <ul class="checkbox-list">
          <li v-for="(airline, code) in filterData.airlines" :key="`airline_${code}`">
            <checkbox :name="`airline[${code}]`" :title="airline" checked @change="onChange" />
            <span class="only-button" @click="onlyCheckbox($refs.airlineTab, `airline[${code}]`)">{{ "bara" | locale }}</span>
          </li>
        </ul>
      </search-filter-item>

      <!-- Bytesflygplatser -->
      <search-filter-item ref="connectionPointsTab" v-if="filterData && filterData.connection_points" :title="'Bytesflygplatser' | locale" selectLinks>
        <ul class="checkbox-list">
          <li v-for="(title, code) in filterData.connection_points" :key="`connection_points_${code}`">
            <checkbox :name="`connection_points[${code}]`" :title="title" checked @change="onChange" />
            <span class="only-button" @click="onlyCheckbox($refs.connectionPointsTab, `connection_points[${code}]`)">{{ "bara" | locale }}</span>
          </li>
        </ul>
      </search-filter-item>

      <!-- Aircrafts -->
      <search-filter-item ref="aircraftTab" v-if="filterData && filterData.equipment_types" :title="'Flygplan' | locale" selectLinks closed>
        <ul class="checkbox-list">
          <li v-for="item in filterData.equipment_types" :key="`equipment_type_${item[0]}`">
            <checkbox :name="`equipment_type[${item[0]}]`" :title="item[1]" checked @change="onChange" />
            <span class="only-button" @click="onlyCheckbox($refs.aircraftTab, `equipment_type[${item[0]}]`)">{{ "bara" | locale }}</span>
          </li>
        </ul>
      </search-filter-item>

      <!-- Banner -->
      <div v-if="$store.state.banner_filter" class="filter_banner sv-SE"></div>
    </ul>
  </form>
</template>

<script>
import SearchFilter from "./search_filter";
import { translate } from "app/helpers";

export default {
  extends: SearchFilter,
  data() {
    return {
      skipMapStops: false
    };
  },
  methods: {
    legTextAirportFrom(text, airport) {
      const airportText = `${airport.from.city} (${airport.from.code})`;
      return `${translate(text)} <span class="name">${airportText}</span>`;
    },
    legTextAirportTo(text, airport) {
      const airportText = `${airport.to.city} (${airport.to.code})`;
      return `${translate(text)} <span class="name">${airportText}</span>`;
    },
    onlyCheckbox(tab, name) {
      tab.setAllCheckboxes(false, false);
      tab.$children.forEach((c) => {
        if (c.name === name) {
          c.setValue(true);
        }
      });
      this.onChange(null);
    },
    onChangeDirect(sender, value) {
      this.onChange(sender, value);

      this.skipMapStops = true;
      const stopsLength = Object.keys(this.maxMaxStops).length;
      for (let i = 0; i < stopsLength; i += 1) {
        if (value) {
          this.maxMaxStops[i] = this.minMaxStops[i];
        } else {
          this.maxMaxStops[i] = this.filterData.legs[i].max_stops;
        }
      }
      this.$nextTick(() => {
        this.skipMapStops = false;
      });
    },
    onChangeMaxStops(sender, value) {
      this.onChange(sender, value);
      if (!this.skipMapStops) {
        this.$refs.directFlightButton.setValue(false);
      }
    },
    onChangeAirportTransfer(sender, value) {
      this.onChange(sender, value);
    }
  }
};
</script>

<style>
.checkbox-list li:hover .only-button {
  display: inline-block;
}
.checkbox-list .only-button {
  display: none;
  padding-left: 5px;
  color: darkgray;
  font-weight: lighter;
}
.checkbox-list .only-button:hover {
  color: lightgray;
  cursor: pointer;
}
</style>
