<template>
  <div class="baggage" v-if="has_cfar_ancillaries">
    <div class="headline">{{ "Fri avbokning:" | locale }}</div>
    <div class="passengers">
      <div
        class="passenger"
        v-for="(cfar, index) in itinerary.attributes.cfar"
      >


        <div class="passenger_number" tooltip :title="cfarTooltipText(cfar.formatted_price)" style="display: inline-block; width: 600px;">
          <input
            type="checkbox"
            :name="cfarName(index)"
            :id="cfarName(index)"
            value="1"
            :data-price="cfar.price"
            :data-price-pax="cfar.price"
            :data-ancillary-type="cfar"
            @change="$emit('change')"
            :checked="cfarSelected(cfar)"
          >
          <label :for="cfarName(index)">{{translatePax(itinerary, cfar)}}</label>
        </div>
        <!--
        <div class="leg-container" style="width: 380px; float: left;">
          <div
            v-for="bag_flight in bag_itinerary"
            class="leg" :style="{ display: 'inline-block', width: cabinBagWidth(bag_flight.ancillaries) + 'px' }"
          >
            <template v-for="ancillary in bag_flight.ancillaries">
              <div v-if="['BAG1', 'CABIN'].includes(ancillary.key)" class="ancillary" style="display: inline-block; clear: both;">
                <input
                  type="checkbox"
                  :name="fetch_ancillary_id(ancillary)"
                  :id="fetch_ancillary_id(ancillary)"
                  value="1"
                  :data-price="ancillary.price"
                  :data-price-pax="ancillary.price_per_pax"
                  :data-ancillary-type="ancillary.type"
                  v-model="ancillary.selected"
                  @change="$emit('change')"
                >
                <label :for="fetch_ancillary_id(ancillary)">{{ translateBag(bag_flight, ancillary) }}</label>
              </div>
            </template>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import { translate } from "app/helpers";

const ancillaryText = {
  "CFAR":    "Resenär %{num}, avbokning +%{amount}",
};

export default {
  name: "baggage",
  props: {
    /**
     * The itinerary.
     */
    itinerary: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      /** Cache the ids as we use it quite a lot. */
      ancillary_cache_ids: {}
    };
  },
  computed: {
    /**
     * Returns the baggage object from the itinerary.
     *
     * TODO: In the future this shouldn't have access to itinerary.
     */
    baggage_ancillaries() {
      return this.itinerary.attributes.baggage_ancillaries;
    },
    /**
     * Returns whether we have baggage ancillaries available.
     *
     * @returns {boolean}
     */
    has_cfar_ancillaries() {
      if (this.itinerary.attributes.cfar === 0) {
        return false
      }

      let firstCfar = this.itinerary.attributes.cfar[0]
      return firstCfar.price > 0
    }
  },
  methods: {
    /**
     * Returns the ancillary id.
     *
     * @param ancillary
     * @returns {string}
     */
    fetch_ancillary_id(ancillary) {
      if (typeof this.ancillary_cache_ids[ancillary.id] !== "undefined" || this.ancillary_cache_ids[ancillary.id] === null) {
          return this.ancillary_cache_ids[ancillary.id];
      }
      const uuid = this.itinerary.attributes.uuid;
      return this.ancillary_cache_ids[ancillary.id] = `baggage[${uuid}][${ancillary.id}]`;
    },
    /**
     * Returns the pax header text.
     *
     * @param bag_itinerary
     * @returns {string}
     */

    cfarName(index){
      const uuid = this.itinerary.attributes.uuid;
      return `cfar[${uuid}][${index}]`
    },

    cfarTooltipText(cost) {
        return translate(
          "Fri avbokning %{cost}", cost)
    },

    translatePax(itinerary, cfar) {
/*
      const bag_itinerary = itinerary.attributes.baggage_ancillaries
console.log("hejsan")
console.log(bag_itinerary)
      const firstFlight = bag_itinerary[0];
      console.log(firstFlight)

      let args = [firstFlight.pax_number, translate(firstFlight.pax_type)];
      if (type == "ADT" && firstFlight.pax_type == "YTH") {
        args = [firstFlight.pax_number, translate("ADT"), price];
      }

      //return translate("Resenär %{num}, %{type}", ...args);

      console.log(itinerary)
      console.log(itinerary[0])
      */
      let pax_type = translate(cfar.pax_type) //cfar.pax_type
      let args = [cfar.pax_number, pax_type, cfar.formatted_price, cfar.back]
      return translate("Resenär %{num}, %{type} +%{price} (%{back} åter vid avbokning)", ...args);
    },

    cfarSelected(cfar) {
      if (cfar.selected) {
        return "checked"
      }
    },

    /**
     * Returns the bag text.
     *
     * @param bag_flight
     * @param ancillary
     * @returns {string}
     */
    translateBag(bag_flight, ancillary) {
      const args = [bag_flight.leg_number, ancillary.weight, ancillary.formatted_price];

      return translate(ancillaryText[ancillary.type] || ancillaryText["CFAR"], ...args);
    },

    cabinBagWidth(ancillaries) {
      if (ancillaries.some(e => e["key"] == "CABIN")) {
        return 300
      } else {
        return 170
      }
    },
  }
}
</script>
