<template>
  <form class="itinerary step_1_air_form"
        :data-uuid="itinerary.id" :data-uuid-raw="itinerary.attributes.uuid"  @submit="submit">
    <input type="hidden" name="alternative" value="1" />
    <input type="hidden" name="uuid" :value="itinerary.attributes.uuid" />

    <div class="fly-schedule" :class="{ 'overlay': locked }">
      <div class="fly-schedule-inner">
        <div class="flight-wrap">

          <div class="flight">
            <img v-for="carrier in itinerary.attributes.carriers" :key="`${itinerary.id}_${carrier.code}`" :src="`/assets/airlines/${carrier.code}.png`" />
            <div class="name" v-if="itinerary.attributes.carriers.length === 1">{{ itinerary.attributes.carriers[0].name }}</div>
          </div>

          <div class="extra-links">
            <div class="flight-rules">
              <a href="javascript:;" fancybox :data-src="generateTicketrulesPath()" data-type="ajax">
                <img :src="'/assets/shared/icons/rules.png' | cloudinary" /> {{ "Biljettregler" | locale }}
              </a>
            </div>
          </div>

          <div class="clear"></div>
        </div>

        <div class="places-left" v-if="itinerary.attributes.seats < 9">{{ placesLeftText(itinerary.attributes.seats) }}</div>

        <div v-if="hasCabins()" class="cabin-info">
          {{ allCabinsText() }}
          <span>(<span class="link" tooltip :data-tooltip-content="`#cabin-tooltip-${itinerary.id}`">{{ "mer information" | locale }}</span>)</span>
        </div>

        <div v-if="itinerary.attributes.textrule" class="text-info">
          <span><span class="link" tooltip :data-tooltip-content="`#text-tooltip-${itinerary.id}`">{{ itinerary.attributes.textrule.headline }}</span></span>
        </div>

        <div class="flight-sec-wrap" v-for="(itin, itinIndex) in itinerary.attributes.itinerary" :key="`${itinerary.id}_${itinIndex}`">
          <div class="flight-sc">
            <ul class="table-header">
              <li class="li_1">{{ "Resa %{num}" | locale(itinIndex + 1) }}</li>
              <li class="li_2">{{ "Från" | locale }}</li>
              <li class="li_3">{{ "Ankomst" | locale }}</li>
              <li class="li_4">{{ "Till" | locale }}</li>
              <li class="li_5">{{ "Flygs av" | locale }}</li>
              <li class="li_6">{{ "Flight" | locale }}</li>
              <li class="li_7">{{ "Bagage" | locale }}</li>
              <li class="li_8">{{ "Restid" | locale }}</li>
            </ul>

            <ul class="outbound">
              <li class="flight-sc-layout">
                <div v-if="itin.change_airport" class="time-box change-airport change-airport-itinerary" :key="`${itinerary.id}_${itinIndex}_change_airport`">
                  {{ itin.change_airport }}
                </div>

                <template  v-for="(segment, segmentIndex) in itin.segments">

                  <div v-if="segment.recheck_bags" class="time-box change-airport" :key="`${itinerary.id}_${itinIndex}_${segmentIndex}_change_airport`" tooltip :title="'Er resa består av två eller flera biljetter, vilket innebär att ni bokar två eller flera separata biljetter.<br /><br />Du behöver därför checka in dig själv och eventuellt bagage igen mellan de flygningar som är på separata biljetter.<br /><br />Om en av dina flygningar ställer in av någon anledning och inte genomförs som planerat så ändras/avbokas INTE den/de andra flygningen/flygningarna per automatik.<br /><br />Ni ansvarar själva för eventuell ombokning och står själva för eventuella merkostnader.' | locale">
                    <img :src="'/assets/shared/icons/rules.png' | cloudinary" />
                    {{ "Hämta ut och checka in dig och ditt bagage igen!" | locale }}
                  </div>

                  <div v-if="segment.change_airport" class="time-box change-airport" :key="`${itinerary.id}_${itinIndex}_${segmentIndex}_change_airport`">
                    <div>{{ segment.change_airport }}</div>
                    <div>{{ "Hämta ut och checka in dig och ditt bagage igen!" | locale }}</div>
                  </div>

                  <div class="time-box" :class="{ first: segmentIndex === 0, last: segmentIndex === itin.segments.length - 1 }" :key="`${itinerary.id}_${itinIndex}_${segmentIndex}`">
                    <div class="time-1">{{ segment.dep_time }}</div>
                    <div class="time-dep_airport" tooltip :title="segment.origin" :class="{ 'time-technical-stop': segment.origin_nearby }">
                      <i v-if="segment.type === 'TRN'" class="fa fa-train"></i>
                      <i v-else-if="segment.type === 'BUS'" class="fa fa-bus"></i>

                      {{ segment.origin || '&nbsp;' }}

                      <div v-if="segment.origin_nearby" class="airport_change">
                        {{ "OBS Närliggande flygplats!" | locale }}
                      </div>
                    </div>
                    <div class="time-2">{{ segment.arr_time }}</div>
                    <div class="time-arr_airport" tooltip :title="segment.destination" :class="{ 'time-technical-stop': segment.destination_nearby || segment.tech_stop }">
                      <i v-if="segment.type === 'TRN'" class="fa fa-train"></i>
                      <i v-else-if="segment.type === 'BUS'" class="fa fa-bus"></i>

                      {{ segment.destination || '&nbsp;' }}

                      <div v-if="segment.destination_nearby" class="airport_change">
                        {{ "OBS Närliggande flygplats!" | locale }}
                      </div>
                    </div>
                    <div class="time-carrier" v-html="opCarrierText(segment.op_carrier)"></div>
                    <div class="time-flightnumber" tooltip :title="generateFlightnumberTooltip(segment)">{{ segment.flight }}</div>

                    <div v-if="segment.type === 'AIR'" class="time-baggage" tooltip :title="generateBaggageTooltip(segment.baggage)">
                      <span :class="segment.baggage.type">{{ segment.baggage.text }}</span>
                    </div>
                    <div v-else class="time-baggage">
                      <span :class="segment.baggage.type">{{ "Inkluderat" | locale }}</span>
                    </div>
                  </div>
                </template>

                <div class="time-travel_time">{{ itin.travel_time }}</div>
                <div v-if="itin.tech_stops" class="flight-technical-stop">* {{ itin.tech_stops }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="itinerary.attributes.itinerary.some(itin => itin.segments.some(segment => segment.recheck_bags))" class="multiple_tickets" tooltip :title="'Er resa består av två eller flera biljetter, vilket innebär att ni bokar två eller flera separata biljetter.<br /><br />Du behöver därför checka in dig själv och eventuellt bagage igen mellan de flygningar som är på separata biljetter.<br /><br />Om en av dina flygningar ställer in av någon anledning och inte genomförs som planerat så ändras/avbokas INTE den/de andra flygningen/flygningarna per automatik.<br /><br />Ni ansvarar själva för eventuell ombokning och står själva för eventuella merkostnader.' | locale">
          <img :src="'/assets/shared/icons/rules.png' | cloudinary" />
          {{ "Resan består av två eller flera biljetter," | locale }} <span style="text-decoration: underline;">{{ "läs mer" | locale }}</span>.
        </div>

        <div v-if="itinerary.attributes.multi" class="multiple_tickets" tooltip :title="'Er resa består av två eller flera enkelresor, vilket innebär att ni bokar två eller flera separata biljetter.<br /><br />Om en av dina flygningar ställer in av någon anledning och inte genomförs som planerat så ändras/avbokas INTE den/de andra flygningen/flygningarna per automatik.<br /><br />Ni ansvarar själva för eventuell ombokning och står själva för eventuella merkostnader.' | locale">
          <img :src="'/assets/shared/icons/rules.png' | cloudinary" />
          {{ "Resan består av enkla biljetter," | locale }} <span style="text-decoration: underline;">{{ "läs mer" | locale }}</span>.
        </div>

        <Baggage v-if="itinerary.attributes.baggage_ancillaries" :itinerary="itinerary" @change="bagsChange" />

        <div v-if="itinerary.attributes.climate" class="climate" tooltip :title="climateTooltipText" tooltip-max-width="300">
          <input type="checkbox" name="climate" :id="`${itinerary.id}_climate`"
                 value="1" :data-price="itinerary.attributes.climate.price" :data-price-pax="itinerary.attributes.climate.pax" v-model="climateSelected" @change="priceChange" />
          <label :for="`${itinerary.id}_climate`">{{ itinerary.attributes.climate.text }} ({{ itinerary.attributes.climate.emission }} kg CO<sub>2</sub>)</label>. <span style="text-decoration: underline;">{{ "läs mer" | locale }}</span>.
        </div>

        <div class="continue-button">
          <template v-if="selected">
            <div class="spinner" style="text-align: center;">
              <img :src="'/assets/shared/ajax-loader_white.gif' | cloudinary" />
            </div>
          </template>
          <template v-else>
            <div class="y-button">
              <template v-if="alreadySelected && $store.state.alternativeViewState === 0">
                <a class="fly" href="javascript:void(0);" @click="openAlternative(1)">{{ "Behåll mitt valda flyg" | locale }}</a>
              </template>
              <template v-else-if="alreadySelected">
                <a class="fly" href="javascript:void(0);" @click="openAlternative(0)">{{ "Se fler flygalternativ" | locale }}</a>
              </template>
              <template v-else>
                <input type="submit" name="commit" class="fly" :value="submitText" />
              </template>
            </div>
          </template>
        </div>

      </div>
    </div>

    <div style="visibility: collapse; width: 0; height: 0;">
      <div :id="`cabin-tooltip-${itinerary.id}`" class="tip_cabin">
        <div class="header">
          <div class="from">{{ "Från" | locale }}</div>
          <div class="to">{{ "Till" | locale }}</div>
          <div class="cabin">{{ "Biljettyp" | locale }}</div>
        </div>
        <div v-for="(flight, flightIndex) in allFlights(itinerary.attributes)" class="row" :key="`cabin-tooltip-${itinerary.id}-${flightIndex}`">
          <div class="from">{{ flight.origin }}</div>
          <div class="to">{{ flight.destination }}</div>
          <div class="cabin">{{ flight.cabin }}</div>
        </div>
      </div>

      <div v-if="itinerary.attributes.textrule" :id="`text-tooltip-${itinerary.id}`" class="tip_text">
        <div class="header">{{ itinerary.attributes.textrule.headline }}</div>
        <div class="body"><span v-html="itinerary.attributes.textrule.text"></span></div>
      </div>
    </div>
  </form>
</template>

<script>
/* global gon */
import ItineraryBase from "./itinerary_air_base";
import Baggage from "./internal/baggage";
import { ViewState } from "../../store/defs";

export default {
  extends: ItineraryBase,
  components: { Baggage },
  watch: {
    selectedPriceBags() {
      if (this.alreadySelected) {
        this.$store.commit("updateAlternativeData", {
          markup: this.currentPriceBags,
          baggage: this.extractFormBags()
        });
      }
    }
  },
  computed: {
    alreadySelected() {
      if (this.$store.state.alternativeItinerary) {
        return this.$store.state.alternativeItinerary.id === this.itinerary.id;
      } else {
        return false;
      }
    }
  },
  methods: {
    // Currently I use numbers in the template, as I can't reference ViewState.
    openAlternative(viewState) {
      const currentViewState = this.$store.state.alternativeViewState;
      switch (currentViewState) {
        case ViewState.air: history.pushState(null, null, gon.links.step_1_url); break;
        case ViewState.hotel: history.pushState(null, null, gon.links.flight); break;
      }

      this.$store.dispatch("updateViewState", viewState);
    }
  }
};
</script>

<style scoped>
.flight-wrap .extra-links {
  float: right;
}
</style>
