import Filter from "../../filter";
import { AirResults } from "./air_results";
//import { HotelResults } from "./hotel_results";
import Itinerary from "./itinerary";

declare let gon: any;

export namespace ItinerariesResult {
  export function load() {
    results_ready();
    $(".fancybox").fancybox();
  }

  function results_ready(): void {
    if ($("#meta_no_availability_error").length > 0) {
      $.fancybox.open($("#meta_no_availability_error"));
    }

    $(".step_1_air_form").each((index, value) => {
      $(value).bind("submit", () => {
        Itinerary.step_1_form_submit(value);
      });
    });

    if (gon.search.search_type === "hotel" || gon.search.search_type === "package") {
      //HotelResults.load_step_1_hotel_results();
    } else {
      AirResults.load_step_1_air_results();
    }

    if (gon.search.search_type === "package") {
      $(".step_1_air_form:not(.item_loaded) input[type=checkbox]").on("click", (): void => {
        $(".step_1_hotel_form").each((index: number, elem: Element): void => {
          //HotelResults.update_price_on_button_hotel($(elem));
        });
      });
    }

    $(".topnav a").each((): void => {
      if ($(this).target === "") {
        $(this).target = "_blank";
      }
    });

    $(".list_view").on("click", (): boolean => {
      $(".img-selected").removeClass("img-selected");
      $(this).parent().addClass("img-selected");
      $("#filter_view").val("list");
      Filter.submit();

      return false;
    });

    $(".gallery_view").on("click", (): boolean => {
      $(".img-selected").removeClass("img-selected");
      $(this).parent().addClass("img-selected");
      $("#filter_view").val("gallery");
      Filter.submit();

      return false;
    });

    $(".map_view").on("click", (): boolean => {
      $(".img-selected").removeClass("img-selected");
      $(this).parent().addClass("img-selected");
      $("#filter_view").val("map");
      Filter.submit();

      return false;
    });
  }
}

export default ItinerariesResult;
