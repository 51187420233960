import accounting from "accounting";

import { Track } from "../../../common/track";
import { __ } from "../../../common/translation";

import { AirResults } from "../../itineraries_result/scripts/air_results";
//import { HotelResults } from "../../itineraries_result/scripts/hotel_results";
import { Tabs } from "../../tabs";
import { ExceptionHandler } from "../../../common/exception_handler";
import {setCurrencySettings} from "../../../../app/helpers";

declare const gon: any;

export module Filter {
  /* private */ let skipSubmit: boolean = false;
  /* private */ let lastSequence: number = 0;
  /* private */ let channel: any;

  export function select_all_airlines(): void { check_all_boxes(".airline_box"); }
  export function select_none_airlines(): void { uncheck_all_boxes(".airline_box"); }

  export function select_all_alliances(): void { check_all_boxes(".alliances_box"); }
  export function select_none_alliances(): void { uncheck_all_boxes(".alliances_box"); }

  export function select_all_stops(): void { check_all_boxes(".connection_box"); }
  export function select_none_stops(): void { uncheck_all_boxes(".connection_box"); }

  // This is what triggers when we anything has been changed.
  function onUpdated(): void {
    if (!skipSubmit) {
      submit();
    }
  }

  export function submit(): void {
    $("#sequence").val(Number($("#sequence").val()) + 1);
    $("#search_results").addClass("overlay");
    $("#filter_form").submit();
    $("#filter_form").on("ajax:error", (eventObject: JQueryEventObject): void => {
      ExceptionHandler.show_failure_screen();
    });
  }

  export function reloadFilter(): void {
    // Monkey-patch for direct flight filter button
    const elemDf: JQuery<HTMLElement> = $("#filter_direct");
    if (elemDf.length > 0) {
      elemDf.val(0);
      $(".Switch", elemDf.parent()).removeClass("On");
    }

    const elemBf: JQuery<HTMLElement> = $("#filter_inc_bagage");
    if (elemBf.length > 0) {
      elemBf.val(0);
      $(".Switch", elemBf.parent()).removeClass("On");
    }

    load();
    onUpdated();
  }

  export function load(): void {
    skipSubmit = true;

    $(".filter input:checkbox").off("change").find("input:checkbox").on("change", onUpdated);
    $("#clear_filter").off("click").on("click", reloadFilter);
    $(".filter-collapsed").off("click");
    $("#filter_name").val("");

    $(".filter a.select_all").off("click").on("click", (eventObject: JQueryEventObject): boolean => {
      const target: JQuery<HTMLElement> = $(eventObject.target);
      target.closest(".filtersec").find("input:checkbox").off("change");
      target.closest(".filtersec").find("input:checkbox:not(:checked)").click();
      target.closest(".filtersec").find("input:checkbox").on("change", onUpdated);
      onUpdated();
      return false;
    });

    $(".filter a.select_none").off("click").on("click", (eventObject: JQueryEventObject): boolean => {
      const target: JQuery<HTMLElement> = $(eventObject.target);
      target.closest(".filtersec").find("input:checkbox").off("change");
      target.closest(".filtersec").find("input:checkbox:checked").click();
      target.closest(".filtersec").find("input:checkbox").on("change", onUpdated);
      onUpdated();
      return false;
    });

    $(".filter-collapsable .filter-header").on("click", filter_caption_click);

    if ($(".custom-checkbox-airline").length === 0) {
      Tabs.customCheckboxes($(".filter").find("input:checkbox"), "airline");
    } else {
      $(".filter").find("input:checkbox:checked").click();
    }

    for (let i: number = 0; i < 10; i += 1) {
      load_leg_filters(i);
    }

    $(".filter").find("input:checkbox").on("change", onUpdated);

    if (gon.filter_max) {
      setup_filter_order_by();
      setup_filter_name();
      setup_sliders();
      setup_checkbox_lists();
      setup_switches();
      setup_form();
    }

    select_all_airlines();
    select_all_alliances();
    select_all_stops();

    // Just for tracking
    $(".filter input[type='checkbox']").on("change", (eventObject: JQueryEventObject): void => {
      Track.log(`filter: ${$(eventObject.target).attr("id")}`);
    });
    $(".Switch").on("click", (eventObject: JQueryEventObject): void => {
      const target: JQuery<HTMLElement> = $(eventObject.target).closest("li");
      const caption: JQuery<HTMLElement> = $(".caption", target);
      const input: JQuery<HTMLElement> = $("input[type='hidden']", target);
      Track.log(`filter: '${caption.text()}' = ${input.val()}`);
    });

    skipSubmit = false;
  }

  function setup_filter_order_by(): void {
    $("#reset-filter").off("click").on("click", function() {
      $("#filter_no_result").addClass("overlay");
      skipSubmit = true;
      $("#filter_form").trigger("reset");
      skipSubmit = false;
      reloadFilter();
      return false;
    });

    $("ul.sort_by li").off("click").on("click", (eventObject: JQueryEventObject): void => {
      const target: JQuery<HTMLElement> = $(eventObject.target);
      const orderBy: string = target.attr("data-order-by");
      $("ul.sort_by li.ui-btn-active").removeClass("ui-btn-active");
      target.addClass("ui-btn-active");
      $("#filter_order_by").val(orderBy);
      Track.log(`Click order by: ${orderBy}`);
      onUpdated();
    });
  }

  function setup_form(): void {
    $("#filter_form").off("ajax:success").on("ajax:success", function(e, data, status, xhr) {
      const requestSequenceValue: number = Number(xhr.getResponseHeader("filtersequence"));
      if (requestSequenceValue > lastSequence) {
        $("#sequence").val((lastSequence = requestSequenceValue));
      } else {
        // console.warn(`Filter Sequence is too old! (New: ${requestSequenceValue}, Last: ${lastSequence})`);
        return;
      }

      $("#search_results").html(data);
      $(".fancybox").fancybox();

      setup_filter_order_by();

      $("#search_results").removeClass("overlay");

      const dataUnderLimit: boolean = data.length < 400;
      const noResultsVisibleText: boolean = $("#filter_no_result").is(":visible");
      const noResultsTextOverlay: boolean = $("#filter_no_result").hasClass("overlay");

      if (dataUnderLimit && !noResultsVisibleText) {
        $("#filter_no_result").show();
      } else if (dataUnderLimit && noResultsVisibleText && noResultsTextOverlay) {
        // TODO: Handle error
        // console.error("Something went wrong!");
      } else if (!dataUnderLimit && noResultsVisibleText) {
        $("#filter_no_result").hide();
        $("#filter_no_result").removeClass("overlay");
      }

      if ((gon.search.search_type === "hotel") || (gon.search.search_type === "package")) {
        //HotelResults.load_step_1_hotel_results();
      } else {
        AirResults.load_step_1_air_results();
      }
    });
  }

  function setup_switches(): void {
    $(".Switch").off("click").on("click", function(elem) {
      const target: JQuery<HTMLElement> = $(elem.target).parent();
      if (target.hasClass("On")) {
        target.parent().find("input:checkbox").prop("checked", true);
        target.removeClass("On").addClass("Off");
        target.parent().find("input:hidden").val(0);

        if (target.parent().find("input:hidden")[0].id === "filter_direct") {
          $(".filter_stops").each((index1: number, elem1: Element): void => {
            const target1: JQuery<HTMLElement> = $(elem1);
            if (target1.slider("option", "value") === 0) {
              target1.off("slidechange");
              target1.slider("option", "value", 99);
              $(`#stops_${target1.attr("id").split("_")[1]}`).text(`Max ${target1.slider("value")} ${__("byten")}`);
              $(`#filter_stops_${target1.attr("id").split("_")[1]}`).val(target1.slider("value"));
              target1.on("slidechange", (eventObject: JQueryEventObject): void => {
                trackSlider(eventObject);
                onUpdated();
              });
            }
          });
        }
      } else {
        target.parent().find("input:checkbox").prop("checked", true);
        target.removeClass("Off").addClass("On");
        target.parent().find("input:hidden").val(1);

        if (target.parent().find("input:hidden")[0].id === "filter_direct") {
          $(".filter_stops").each((index1: number, elem1: Element): void => {
            const target1: JQuery<HTMLElement> = $(elem1);
            if (target1.slider("option", "value") !== 0) {
              target1.off("slidechange");
              target1.slider("option", "value", 0);
              $(`#stops_${target1.attr("id").split("_")[1]}`).text(`Max ${target1.slider("value")} ${__("byten")}`);
              $(`#filter_stops_${target1.attr("id").split("_")[1]}`).val(target1.slider("value"));
              target1.on("slidechange", (eventObject: JQueryEventObject): void => {
                trackSlider(eventObject);
                onUpdated();
              });
            }
          });
        }
      }
      onUpdated();
    });

    $(".Switch").each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      if (target.parent().find("input:checkbox").length) {
        if (!target.parent().find("input:checkbox").hasClass("show")) {
          target.parent().find("input:checkbox").hide();
          target.parent().find("input:checkbox").checked = true;
          target.removeClass("On").addClass("Off");
          target.parent().find("input:hidden").val(0);
        }
      }
    });
  }

  function setup_sliders(): void {
    setCurrencySettings();
    $(".filter_slider").off("slidechange").on("slidechange", (eventObject: JQueryEventObject): void => {
      trackSlider(eventObject);
      onUpdated();
    });

    if ($("#filter_price-range").length > 0) {
      $("#filter_price-range").slider({
        range: true,
        min: gon.filter_max.min_price,
        max: gon.filter_max.max_price,
        values: [ gon.filter_max.min_price, gon.filter_max.max_price ],
        slide(event: Event, ui: any): void {
          $("#filter_price_txt_min").text(`Min ${accounting.formatMoney(ui.values[0])}`);
          $("#filter_price_txt_max").text(`Max ${accounting.formatMoney(ui.values[1])}`);
          $("#filter_price_min").val(ui.values[0]);
          $("#filter_price_max").val(ui.values[1]);
        }
      });

      $("#filter_price_txt_min").text(`Min ${accounting.formatMoney($("#filter_price-range").slider("values", 0))}`);
      $("#filter_price_txt_max").text(`Max ${accounting.formatMoney($("#filter_price-range").slider("values", 1))}`);
      $("#filter_price_min").val($("#filter_price-range").slider("values", 0));
      $("#filter_price_max").val($("#filter_price-range").slider("values", 1));
    }

    if (("#t-time-range").length > 0) {
      $("#t-time-range").slider({
        range: "min",
        value: gon.filter_max.max_traveltime,
        min: gon.filter_max.min_traveltime,
        max: gon.filter_max.max_traveltime + 15,
        step: 15,
        slide(event: Event, ui: any): void {
          const hms: string[] = minutesToHoursAndMinutes(ui.value, true);
          $("#t-time").text(__("Max %Ht %Mm").replace("%H", `${hms[0]}`).replace("%M", `${hms[1]}`));
          $("#filter_travel_time").val(ui.value);
        }
      });

      const hms: string[] = minutesToHoursAndMinutes($("#t-time-range").slider("value"), true);
      $("#t-time").text(__("Max %Ht %Mm").replace("%H", `${hms[0]}`).replace("%M", `${hms[1]}`));
      $("#filter_travel_time").val($("#t-time-range").slider("value"));
    }
  }

  // Alliances & Alliances & Stops
  function setup_checkbox_lists(): void {
    $("#select_all_alliances").off("click").on("click", (): boolean => {
      select_all_alliances();
      onUpdated();
      return false;
    });

    $("#select_none_alliances").off("click").on("click", (): boolean => {
      select_none_alliances();
      onUpdated();
      return false;
    });

    $("#select_all_airline").off("click").on("click", (): boolean => {
      select_all_airlines();
      onUpdated();
      return false;
    });

    $("#select_none_airline").off("click").on("click", (): boolean => {
      select_none_airlines();
      onUpdated();
      return false;
    });

    $("#select_all_stops").on("click", (): boolean => {
      select_all_stops();
      onUpdated();
      return false;
    });

    $("#select_none_stops").on("click", (): boolean => {
      select_none_stops();
      onUpdated();
      return false;
    });
  }

  function setup_filter_name(): void {
    $("#filter_name").on("change keyup", (): void => {
      onUpdated();
    });
  }

  function load_leg_filters(i: number): void {
    if ($(`#stops-range_${i}`).length > 0) {
      $(`#stops-range_${i}`).slider({
        range: "min",
        value: gon.filter_max.max_stops[i],
        min: gon.filter_max.min_stops[i],
        max: gon.filter_max.max_stops[i],
        slide(event: Event, ui: any) {
          $(`#stops_${i}`).text(`Max ${ui.value} ${__("byten")}`);
          $(`#filter_stops_${i}`).val(ui.value);
          if ((ui.value > 0) && ($("#filter_direct").val() === "1")) {
            $("#filter_direct").parent().children(".Switch").removeClass("On").addClass("Off");
            $("#filter_direct").val("0");
          }

          let stopSum: number = 0;
          $(".filter_stops").each((index: number, elem: Element): void => {
            const indexId: string = $(elem).attr("id").split("_")[1];
            const value: any = $(`#filter_stops_${indexId}`).val();
            if (typeof value === "string") {
              stopSum += Number(value);
            } else if (typeof value === "number") {
              stopSum += value;
            } else {
              throw Error("Unsupported value type");
            }
          });

          if ((stopSum === 0) && ($("#filter_direct").val() === "0")) {
            $("#filter_direct").parent().children(".Switch").removeClass("Off").addClass("On");
            $("#filter_direct").val("1");
          }
        }
      });

      $(`#stops_${i}`).text(`Max ${$(`#stops-range_${i}`).slider("value")} ${__("byten")}`);
      $(`#filter_stops_${i}`).val($(`#stops-range_${i}`).slider("value"));
    }

    if ($(`#d-time-range_${i}`).length > 0) {
      $(`#d-time-range_${i}`).slider({
        range: true,
        min: 0,
        max: 1440,
        step: 15,
        values: [ 0, 1440 ],
        slide(event: Event, ui: any) {
          const hms1: string[] = minutesToHoursAndMinutes(ui.values[0], true);
          const hms2: string[] = minutesToHoursAndMinutes(ui.values[1], true);
          $(`#d-time_${i}`).text(`${hms1[0]}:${hms1[1]} - ${hms2[0]}:${hms2[1]}`);
          $(`#filter_departure_time_min_${i}`).val(ui.values[0]);
          $(`#filter_departure_time_max_${i}`).val(ui.values[1]);
        }
      });

      let hms1: string[] = minutesToHoursAndMinutes($(`#d-time-range_${i}`).slider("values", 0), true);
      let hms2: string[] = minutesToHoursAndMinutes($(`#d-time-range_${i}`).slider("values", 1), true);
      $(`#d-time_${i}`).text(`${hms1[0]}:${hms1[1]} - ${hms2[0]}:${hms2[1]}`);
      $(`#filter_departure_time_min_${i}`).val($(`#d-time-range_${i}`).slider("values", 0));
      $(`#filter_departure_time_max_${i}`).val($(`#d-time-range_${i}`).slider("values", 1));

      $(`#a-time-range_${i}`).slider({
        range: true,
        min: 0,
        max: 1440,
        step: 15,
        values: [ 0, 1440 ],
        slide(event: Event, ui: any) {
          hms1 = minutesToHoursAndMinutes(ui.values[0], true);
          hms2 = minutesToHoursAndMinutes(ui.values[1], true);
          $(`#a-time_${i}`).text(`${hms1[0]}:${hms1[1]} - ${hms2[0]}:${hms2[1]}`);
          $(`#filter_arrival_time_min_${i}`).val(ui.values[0]);
          $(`#filter_arrival_time_max_${i}`).val(ui.values[1]);
        }
      });

      hms1 = minutesToHoursAndMinutes($(`#a-time-range_${i}`).slider("values", 0), true);
      hms2 = minutesToHoursAndMinutes($(`#a-time-range_${i}`).slider("values", 1), true);
      $(`#a-time_${i}`).text(`${hms1[0]}:${hms1[1]} - ${hms2[0]}:${hms2[1]}`);
      $(`#filter_arrival_time_min_${i}`).val($(`#a-time-range_${i}`).slider("values", 0));
      $(`#filter_arrival_time_max_${i}`).val($(`#a-time-range_${i}`).slider("values", 1));
    }
  }

  function check_all_boxes(selector: string): void {
    $(".filter").find("input:checkbox").off("change");
    $(`${selector} input:checkbox:not(:checked)`).click();
    $(".filter").find("input:checkbox").on("change", function() { onUpdated(); });
  }

  function uncheck_all_boxes(selector: string): void {
    $(".filter").find("input:checkbox").off("change");
    $(`${selector} input:checkbox:checked`).click();
    $(".filter").find("input:checkbox").on("change", function() { onUpdated(); });
  }

  function filter_caption_click(eventObject: JQueryEventObject): boolean {
    const target: JQuery<HTMLElement> = $(eventObject.target);
    const parent: JQuery<HTMLElement> = target.parent();
    const content: JQuery<HTMLElement> = $(".filter-content", parent);
    if (parent.hasClass("filter-open")) {
      parent.removeClass("filter-open");
      parent.addClass("filter-closed");
      content.slideUp(150);
    } else {
      parent.addClass("filter-open");
      parent.removeClass("filter-closed");
      content.slideDown(150);
    }
    eventObject.stopPropagation();
    return false;
  }

  function minutesToHoursAndMinutes(m, zeroPadded: boolean = false): string[] {
    let hours: number | string = Math.floor(m / 60);
    if (zeroPadded && (`${hours}`.length === 1)) { hours = `0${hours}`; }
    let minutes: number | string = m % 60;
    if (zeroPadded && (`${minutes}`.length === 1)) { minutes = `0${minutes}`; }
    if ((hours === 24) && (minutes === "00")) {
      hours = 23;
      minutes = 59;
    }
    return [hours.toString(), minutes.toString()];
  }

  function trackSlider(eventObject: Event): void {
    const values: JQuery<HTMLElement> = $(eventObject.target).closest("li").find("input:hidden");

    let result: string = "";
    values.each((index: number, elem: Element): void => {
      result += `${$(elem).attr("id")}: ${$(elem).val()};`;
    });

    Track.log(`filter: ${result}`);
  }
}
