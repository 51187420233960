import accounting from "accounting";
import honeybadger from "honeybadger-js";
import jsCookies from "js-cookie";

import { Track } from "../../../common/track";
import { __ } from "../../../common/translation";
//import { HotelResults } from "../scripts/hotel_results";
import {setCurrencySettings,navigate, raiseError} from "../../../../app/helpers";

declare const gon: any;

export module AirResults {
  // Removed Flights
  function debug_show_removed_flights(): void {
    const input = $(".debug-window #show_removed_flights")[0] as HTMLInputElement;
    if (input.checked) {
      $(".fly-schedule-removed_reason").show();
      $(".fly-schedule-remove").show();
    } else {
      $(".fly-schedule-removed_reason").hide();
      $(".fly-schedule-remove").hide();
    }
  }

  // Update price
  function updatePriceOnButton(t: JQuery<HTMLElement>): void {
    setCurrencySettings();
    let total: number = 0;
    $("input[type=checkbox]:checked", t.form()).each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      total += Number(target.attr("data-price"));
    });

    $("input[type=submit][class=fly]", t.form()).each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      const orgPrice: number = Number(target.attr("data-price"));
      const newTotal: number = orgPrice + total;
      if (newTotal > orgPrice) {
        if (target.attr("data-default-value") === undefined) {
          target.attr("data-default-value", target.val());
        }
        target.val(__("Välj flyg och bagage:") + ` ${accounting.formatMoney(newTotal)}`);
      } else {
        target.val(target.attr("data-default-value"));
      }
    });
  }

  function reloadResults(): void {
    $.get(`${document.location.href}/reload`, function(data): void {
      $("#search_results").html(data);
      if (gon.search.search_type === "hotel" || gon.search.search_type === "package") {
        //HotelResults.load_step_1_hotel_results();
      } else {
        load_step_1_air_results();
      }
    });
  }

  export function load_step_1_air_results(): void {
    $(".step_1_air_form:not(.item_loaded)").each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      $(".tip", target).tooltipster({
        contentAsHTML: true
      });

      $(".ctip", target).tooltipster({
        contentAsHTML: true,
        trigger: "custom",
        triggerOpen: {
          click: true,
          mouseenter: true,
          touchstart: true,
          tap: true
        },
        triggerClose: {
          click: true,
          mouseleave: true,
          scroll: true,
          touchleave: true
        }
      });

      $(".fancybox", target).fancybox();
    });

    $(".step_1_air_form:not(.item_loaded) input[type=checkbox]").on("click", function() {
      updatePriceOnButton($(this));
    });

    $("form.step_1_air_form:not(.item_loaded)").on("ajax:before", (eventObject: JQueryEventObject): void => {
      $(eventObject.target).find(".y-button").hide();
      $(eventObject.target).find(".spinner").show();
      $("input[type=submit][class=air]").attr("disabled", "disabled");
      $(".fly-schedule").addClass("overlay");
      $(eventObject.target).find(".fly-schedule").removeClass("overlay");
    });

    $(".step_1_air_form:not(.item_loaded)").on("ajax:success", air_form_success);
    $(".step_1_air_form:not(.item_loaded)").on("ajax:failure ajax:error", air_form_failure);

    $(".step_1_air_form:not(.item_loaded)").each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      $(target).addClass("item_loaded");
    });

    if ((typeof gon.debug_enabled !== "undefined" && gon.debug_enabled !== null) && gon.debug_enabled) {
      debug_show_removed_flights();
    }
  }

  function air_form_success(e, data, status, xhr): void {
    $("input[type=submit][class=fly]").each(function() {
      $(this).removeAttr("disabled");
    });

    if (data.available) {
      jsCookies.set("cookie_message", "true", { expires: 7 });
      navigate(data.url);
    } else {
      $("input[type=submit][class=fly]").each(function() {
        $(this).parent().show();
      });

      $(".spinner").each(function() {
        $(this).hide();
      });

      $(".fly-schedule").removeClass("overlay");
      Track.log("Show no availability error screen");
      $.fancybox.open($("#step_1_no_availability_error"));

      reloadResults();
    }
  }

  function air_form_failure(xhr, status, error): void {
    const data: object = {
      error: "step_1_air_form failure",
      url: xhr.currentTarget.baseURI,
      status: status.status,
      html: xhr.currentTarget.innerHTML
    };

    Track.log("AJAX ERROR (Itinerary)", data);
    honeybadger.notify(raiseError("Ajax error step_1", ""), { context: data });

    $("input[type=submit][class=fly]").each(function() {
      $(this).parent().show();
      $(this).removeAttr("disabled");
    });

    $(".spinner").each(function() {
      $(this).hide();
    });

    $(".fly-schedule").removeClass("overlay");
    $.fancybox.open($("#step_1_ajax_error"));
  }
}
