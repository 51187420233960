import Vue from "vue";
import { setCurrencySettings, translate, isNull } from "./index";
import accounting from "accounting";
import { cloudinary } from "./classes/cloudinary";

declare const gon: any;

setCurrencySettings();

function minutesToHoursAndMinutes(m: number, zeroPadded: boolean = false, overflow: boolean = false) {
  // if the value is minus then we should turn it around.
  while (m < 0) { m += 24 * 60; }

  let hoursNumber: number = Math.floor(m / 60);
  let minutesNumber: number = m % 60;

  if (overflow && (hoursNumber > 24 || (hoursNumber >= 24 && minutesNumber > 0))) {
    hoursNumber = hoursNumber % 24;
  }

  let hours: string = "" + hoursNumber;
  let minutes: string = "" + minutesNumber;

  if (zeroPadded && hours.length == 1) {
    hours = `0${hours}`;
  }

  if (zeroPadded && minutes.length == 1) {
    minutes = `0${minutes}`;
  }

  if (minutesNumber == 24 && minutes == "00") {
    return ["23", "59"];
  }

  return [hours, minutes];
}

Vue.filter("dateformat", function(value: string | number | Date) {
  const date = new Date(value);
  return date.toLocaleString().replace(/\//g, "-");
});

Vue.filter("minutes_to_hours_and_minutes", function(value: number) {
  const hms = minutesToHoursAndMinutes(value, true);
  return translate("%Ht %Mm").replace("%H", `${hms[0]}`).replace("%M", `${hms[1]}`);
});

Vue.filter("minutes_to_clock", function(value) {
  const extraDays = Math.floor(value / 1440);
  const hms = minutesToHoursAndMinutes(value, true, true);

  let extraDaysText = "";
  if (extraDays !== 1440 && extraDays > 0) {
    extraDaysText = `+${extraDays}`;
  } else if (extraDays < 0) {
    extraDaysText = `${extraDays}`;
  }

  return `${hms[0]}:${hms[1]}${extraDaysText}`;
});

Vue.filter("minutes_to_clock_with_day", (value: number, day: number) => {
  const hms = minutesToHoursAndMinutes(value, true, true);

  if (!isNull(day)) {
    let extraDays = Math.floor(value / 1440);
    if (value === 1440) {
      extraDays = 0;
    }

    const newDay = Math.floor(day + extraDays);
    const dayName = gon.common.day_names[newDay % 7];
    return `${dayName} ${hms[0]}:${hms[1]}`;
  } else {
    return `${hms[0]}:${hms[1]}`;
  }
});

Vue.filter("currency", (value: number) => {
  return accounting.formatMoney(value);
});

Vue.filter("currency-prefix", value => {
  const number = parseFloat(value);
  const prefix = number >= 0 ? "+" : "";
  return `${prefix}${accounting.formatMoney(number)}`;
});


Vue.filter("locale", (value: string, ...args: string[]) => {
  return translate(value, ...args);
});

Vue.filter("cloudinary", (value: string, args: {} = null) => {
  if (gon.development) {
    return value;
  } else {
    return cloudinary(value, args);
  }
});

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let func = function(eventObject: any): void {
      if (binding.value(eventObject, el)) {
        window.removeEventListener("scroll", func);
      }
    };
    window.addEventListener("scroll", func);
  }
});

