import { newComponent } from "@aobtravel/falco-webkit/src/support";
import { mapping } from "@aobtravel/falco-webkit/mapping";

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import { locales } from "../components/web/locales";

// TODO: Now when translation.io have Vue support we should move it there
const language = document.getElementsByTagName("html")[0].getAttribute("lang");
const i18n = new VueI18n({
    locale: language,
    fallbackLocale: "en-GB",
    messages: locales
});

window.newComponent = function newComponentAsync(target, klass, props = {}) {
    const loader = mapping[klass];
    loader().then(component => {
        newComponent(target, component.default, props, { i18n });
    });
};
