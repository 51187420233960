import { SearchfieldWidget } from "./searchfield_widget";

export class Searchfield {
  private static searchfieldWidget: SearchfieldWidget;

  public static load(): void {
    this.getWidget().load();
  }

  public static beginSearch(): void {
    this.getWidget().beginSearch();
  }

  public static getWidget(): SearchfieldWidget {
    if (!this.searchfieldWidget) {
      this.searchfieldWidget = new SearchfieldWidget();
    }
    return this.searchfieldWidget;
  }
}
