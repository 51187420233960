<template>
  <div :id="componentID()" @click="onClick">
    <input type="hidden" :name="this.name" :value="this.currentValue" />
    <div class="Switch" :class="{'Off': !this.currentValue, 'On': this.currentValue}">
      <div class="Toggle"></div>
      <span class="On">ON</span> <span class="Off">OFF</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    context: Number | Object
  },
  data() {
    return {
      uid: null,
      currentValue: this.checked,
      skipWatch: false
    };
  },
  watch: {
    checked: {
      handler(val, oldVal) {
        this.setValue(val);
      }
    },
    currentValue: {
      handler(val, oldVal) {
        if (!this.skipWatch) {
          this.$emit("change", this, val, this.context);
        }
      }
    }
  },
  methods: {
    componentID() {
      return `cuid_${this.uid}`;
    },
    setValue(value) {
      this.skipWatch = true;
      this.currentValue = value;
      this.$nextTick(() => {
        this.skipWatch = false;
      });
    },
    reset() {
      this.setValue(this.checked);
    },
    onClick() {
      this.currentValue = !this.currentValue;
    }
  },
  beforeMount() {
    this.uid = this._uid;
  }
};
</script>
