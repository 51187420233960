<template>
  <div :id="componentID()">
    <input type="hidden" :name="this.minName" :value="this.currentValues[0]" />
    <input type="hidden" :name="this.maxName" :value="this.currentValues[1]" />
    <div class="slider_component" :class="{ 'ui-slider-max-value': min == max }"></div>
  </div>
</template>

<script>
export default {
  props: {
    minName: {
      type: String,
      required: true
    },
    maxName: {
      type: String,
      required: true
    },
    values: {
      type: Number[2],
      default: null
    },
    min: Number | null,
    max: Number | null,
    step: Number | null
  },
  data() {
    return {
      uid: null,
      currentValues: (this.values === null) ? [this.min, this.max] : this.values,
      skipWatch: false
    };
  },
  watch: {
    currentValues: {
      handler(val, oldVal) {
        if (!this.skipWatch) {
          this.$emit("change", this, val);
        }
      }
    }
  },
  methods: {
    componentID() {
      return `cuid_${this.uid}`;
    },
    component() {
      return $(`#${this.componentID()} .slider_component`);
    },
    getValues() {
      return this.currentValues;
    },
    setValues(values) {
      this.skipWatch = true;
      this.currentValues = values;
      this.component().slider("option", "values", values);
      this.$nextTick(() => {
        this.skipWatch = false;
      });
    },
    reset() {
      this.setValues([this.min, this.max]);
    },
    componentOptions() {
      const self = this;
      return {
        range: true,
        values: this.currentValues,
        min: this.min,
        max: this.max,
        step: this.step,
        slide(event, ui) {
          self.$emit("slide", self, ui.values);
        },
        stop(event, ui) {
          self.currentValues = ui.values;
        }
      };
    }
  },
  beforeMount() {
    this.uid = this._uid;
  },
  mounted() {
    this.component().slider(this.componentOptions());
  }
};
</script>
