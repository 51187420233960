import "@fancyapps/fancybox";
import jsCookie from "js-cookie";

$(window).on("load", (): void => {
  if ($("#cookie_message").length > 0) {
    if (!jsCookie.get("cookie_message")) {
      $("#cookie_message").show();
      $("#cookie_message_readmore").on("click", (): void => {
        $.fancybox.open($("#cookie_message_popup"), { autoSize: true });
        jsCookie.set("cookie_message", "true", { expires: 7 });
        $("#cookie_message").hide();
      });
    }
  }
});
