
/**
 * Plain event object easy for reuse.
 */
export class Event {
    public type: string;
    public data: any;

    constructor(type: string, data: any) {
        this.type = type;
        this.data = data;
    }
}

/**
 * Event Handler Interface.
 */
export interface IEventHandler<T> {
    on(handler: { (data?: T): void }): void;
    off(handler: { (data?: T): void }): void;
}

/**
 * Event Handler.
 */
export class EventHandler<T> implements IEventHandler<T> {
    private handlers: { (data?: T): void; }[] = [];

    public on(handler: { (data?: T): void }): void {
        this.handlers.push(handler);
    }

    public off(handler: { (data?: T): void }): void {
        this.handlers = this.handlers.filter((handler) => (handler !== handler));
    }

    public trigger(data?: T) {
        this.handlers.slice(0).forEach((handler) => handler(data));
    }

    public expose(): IEventHandler<T> {
        return this;
    }
}
