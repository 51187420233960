
declare const gon: any;

function getTranslation(key: string): string {
    if (gon.translations && gon.translations[key]) {
        return gon.translations[key];
    } else {
        console.warn(`Translation key missing (${key})`);
        return key;
    }
}

/**
 * Translate a string.
 * The arguments will just be taken in order and ignore the name of them.
 *
 * @example
 *
 *      translate("Hello %{args}", "World") => "Hello World"
 *
 * @param value translation key.
 * @param args translation args, if there are any.
 */
export function translate(value: string, ...args: string[]) {
    let strt: string = getTranslation(value);

    if (args && args.length > 0) {
        for (let i: number = 0; i < args.length; i += 1) {
            let iStart: number = strt.indexOf("%{");
            let iEnd: number = strt.indexOf("}", iStart) + 1;
            const str = strt.substring(iStart, iEnd);
            strt = strt.replace(str, args[i]);
        }
    }

    return strt;
}
