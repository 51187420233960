(function ($) { $.fn.datepicker.language["de-DE"] = {
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
    daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    months: ["Januar","Februar","März","April","Mai","Juni", "Juli","August","September","Oktober","November","Dezember"],
    monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    today: "Heute",
    clear: "Löschen",
    dateFormat: "dd.mm.yyyy",
    timeFormat: "hh:ii",
    firstDay: 1
}; }(jQuery));
