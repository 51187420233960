

export default {
  /**
   * System variables
   */
  searchChannel: null,

  /**
   * App variables
   */
  searchStatus: null,             // The search status (ex. searching, ready)
  alternativeViewState: null,     // The Alternative view state. (Like showing flights on package)
  alternativeItinerary: null,

  alternativeMarkup: null,        // Add a markup on all the normal itineraries on a alternative search
  alternativeBaggageData: null,   // Data that should be submitted with a alternative search

  lockItineraries: false,         // Add a overlay to all itineraries besides the selected itinerary.
  lockFilter: false,              // Lock the filter allowing no changes.
  loadMoreResultsRunning: false,  // Limit the load more to only run one at once.
  loadHidden: false,              // Load the hidden itineraries. If debug is enabled.

  flightItinerary: null,
  flightItineraries: [],
  flightItinerariesOffset: 0,
  flightItinerariesCount: null,
  flightItinerariesCountMax: null,
  flightItinerariesCountRemoved: null,
  flightBanners: [],
  flightBannersRight: [],
  flightBannersLeft: [],
  flightFilter: null,

  hotelItineraries: [],
  hotelItinerariesOffset: 0,
  hotelItinerariesCount: null,
  hotelItinerariesCountMax: null,
  hotelItinerariesCountRemoved: null,
  hotelBanners: [],
  hotelBannersRight: [],
  hotelBannersLeft: [],
  hotelFilter: null,
  hotelRoomsPax: null
};
