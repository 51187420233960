<template>
  <div>
    <div style="font-size: 0;">
      <div class="block1">
        <table>
          <tr style="border-bottom: 1px solid #7f7f7f;">
            <th>Passenger</th>
            <th>Leg</th>
            <th>Sales Price</th>
            <th>Cost Price</th>
            <th>Description</th>
            <th>Pricerule</th>
          </tr>

          <template v-for="collection in info.itinerary.data.attributes.baggage_ancillaries">
            <template v-for="paxbags in collection">
              <tr v-for="ancillary in paxbags.ancillaries" :key="`bag_${ancillary.id}`" class="border-b-1">
                <td>{{ paxbags.pax_type }} {{ paxbags.pax_number }}</td>
                <td>{{ paxbags.leg_number }}</td>
                <td>{{ ancillary.price }}</td>
                <td>{{ ancillary.net_price }}</td>
                <td>{{ ancillary.id }}</td>
                <td>{{ ancillary.pricerule_id[ancillary.key.toLowerCase()] }}</td>
              </tr>
            </template>
          </template>
        </table>

        <div class="separator"></div>

        <table style="border-top: 1px solid #7f7f7f;">
          <tr style="border-bottom: 1px solid #7f7f7f;">
            <th>Part</th>
            <th>Code</th>
            <th>Amount</th>
            <th>Carrier</th>
            <th>Description</th>
            <th>Passenger</th>
            <th>Leg</th>
          </tr>

          <tr v-for="anc in info.itinerary.data.attributes.ancillaries" :key="`anc_${anc.part_index}_${anc.code}_${anc.amount}`" class="border-b-1">
            <td>#{{ anc.part_index }}</td>
            <td>{{ anc.code }}</td>
            <td>{{ anc.amount }}</td>
            <td>{{ anc.carrier }}</td>
            <td>{{ anc.description }}</td>
            <td>{{ anc.passenger_code }}</td>
            <td>from {{ anc.start_segment }} to {{ anc.end_segment }}</td>
          </tr>
        </table>
      </div>

      <div class="block2">
        <table>
          <tr style="border-bottom: 1px solid #7f7f7f;">
            <th>Resa</th>
            <th>Leg</th>
            <th>Bag</th>
            <th>Text</th>
          </tr>

          <tr v-for="leg in info.itinerary.data.attributes.bags_included" :key="`anc_bag_${leg.leg_index}_${leg.flight_index}_${leg.type}`">
            <td>{{ leg.leg_index }}</td>
            <td>{{ leg.flight_index }}</td>
            <td>{{ leg.type }}</td>
            <td>{{ leg.text }}</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Alt itinerary -->
    <template v-if="info.itinerary2">
      <div class="separator itinerary-separator"></div>

      <div style="font-size: 0;">
        <div class="block1">
          <table>
            <tr style="border-bottom: 1px solid #7f7f7f;">
              <th>Passenger</th>
              <th>Leg</th>
              <th>Sales Price</th>
              <th>Sales Price per pax</th>
              <th>Cost Price</th>
              <th>Cost Price per pax</th>
              <th>Description</th>
              <th>Pricerule</th>
            </tr>

            <template v-for="paxbag in info.itinerary2.data.attributes.bags">
              <tr v-for="bag in paxbag.items" :key="`bag_${paxbag.pax_no}_${bag.leg_no}`" class="border-b-1">
                <td>{{ paxbag.text }}</td>
                <td>{{ bag.leg_no }}</td>
                <td>{{ bag.sales_price }}</td>
                <td>{{ bag.sales_price_per_pax }}</td>
                <td>{{ bag.cost_price }}</td>
                <td>{{ bag.cost_price_per_pax }}</td>
                <td>{{ bag.text }}</td>
                <td>{{ bag.pricerule }}</td>
              </tr>
            </template>
          </table>

          <div class="separator"></div>

          <table style="border-top: 1px solid #7f7f7f;">
            <tr style="border-bottom: 1px solid #7f7f7f;">
              <th>Part</th>
              <th>Code</th>
              <th>Amount</th>
              <th>Carrier</th>
              <th>Description</th>
              <th>Passenger</th>
              <th>Leg</th>
            </tr>

            <tr v-for="anc in info.itinerary2.data.attributes.ancillaries" :key="`anc_${anc.part_index}_${anc.code}_${anc.amount}`" class="border-b-1">
              <td>#{{ anc.part_index }}</td>
              <td>{{ anc.code }}</td>
              <td>{{ anc.amount }}</td>
              <td>{{ anc.carrier }}</td>
              <td>{{ anc.description }}</td>
              <td>{{ anc.passenger_code }}</td>
              <td>from {{ anc.start_segment }} to {{ anc.end_segment }}</td>
            </tr>
          </table>
        </div>

        <div class="block2">
          <table>
            <tr style="border-bottom: 1px solid #7f7f7f;">
              <th>Resa</th>
              <th>Leg</th>
              <th>Bag</th>
              <th>Text</th>
            </tr>

            <tr v-for="leg in info.itinerary2.data.attributes.bags_included" :key="`anc_bag_${leg.leg_index}_${leg.flight_index}_${leg.type}`">
              <td>{{ leg.leg_index }}</td>
              <td>{{ leg.flight_index }}</td>
              <td>{{ leg.type }}</td>
              <td>{{ leg.text }}</td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  tabOptions: {
    title: "Ancillaries",
    canShow(info) {
      if (info && info.itinerary && info.itinerary.data.attributes.ancillaries) {
        return true;
      }
      return false;
    }
  },
  props: {
    info: Object
  }
};
</script>

<style scoped>
.block1 {
  width: 80%;
  display: inline-block;
  vertical-align: top;
}
.block2 {
  width: 20%;
  display: inline-block;
  vertical-align: top;
  border-left: 1px solid #9a9a9a;
  border-bottom: 1px solid #9a9a9a;
}
.separator {
  background-color: #d9d9d9;
  height: 8px;
  width: 100%;
}
.itinerary-separator {
  border: 1px solid rgb(154, 154, 154);
  margin-top: -1px;
}
.border-b-1 {
  border-bottom: 1px solid #9a9a9a;
}
table {
  width: 100%;
  height: 100%;
}
th {
  padding-right: 5px;
  padding-left: 5px;
  white-space: nowrap;
  text-align: left;
}
td {
  padding-right: 5px;
  padding-left: 5px;
  white-space: nowrap;
}
tr {
  font-size: 12px;
}
</style>
