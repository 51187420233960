import { SearchChannel } from "./cable/search_channel";
import { SearchType, ViewState } from "./defs";

// This is a bit of a hack, but it works.
declare const gon: any;

function search_type() {
  if (gon.search_parameters && gon.search_parameters.type) {
    return gon.search_parameters.type;
  } else {
    return null;
  }
}

export default {
  /** Gets if we are currently searching. */
  searching: (state: any, getters: any) => {
    if (state.searchStatus !== "ready") {
      if (getters.viewState === ViewState.hotel) {
        return state.flightItineraries.length === 0;
      } else {
        return state.hotelItineraries.length === 0;
      }
    }
    return false;
  },

  /** Gets the search type. */
  searchType: (state: any): SearchType => {
    if (state.search_parameters) {
      const rawType: string = state.search_parameters.type;
      switch (rawType) {
        case "package": return SearchType.package;
        case "hotel":   return SearchType.hotel;
        default:        return SearchType.air;
      }
    } else {
      return SearchType.noSearch;
    }
  },

  /** Gets the current view state. */
  viewState: (state: any, getters: any): ViewState => {
    const isHotel: boolean = (state.alternativeViewState === null || state.alternativeViewState === ViewState.hotel);
    const isSearchHotel = search_type() == "hotel" || search_type() == "package";
    if (isSearchHotel && isHotel) {
      return ViewState.hotel;
    } else {
      return ViewState.air;
    }
  },

  searchChannel: (state: any): SearchChannel => state.searchChannel as SearchChannel,

  /// Flight Methods

  hasFlightBanners: (getters: any): boolean => getters.flightBanners.length > 0,
  flightBanners: (state: any): any[] => state.flightBanners || [],

  hasFlightItineraries: (getters: any): boolean => getters.flightItineraries.length > 0,
  flightItineraries: (state: any): any[] => state.flightItineraries || [],

  /// Hotel Methods

  hasHotelBanners: (getters: any): boolean => getters.hotelBanners.length > 0,
  hotelBanners: (state: any): any[] => state.hotelBanners || [],

  hasHotelItineraries: (getters: any): boolean => getters.hotelItineraries.length > 0,
  hotelItineraries: (state: any): any[] => state.hotelItineraries || []
};
