
function createSpanFromMatch(str: string, regex: RegExp): string {
  if (!(str && regex)) {
    return str;
  }

  const rresult: RegExpMatchArray = str.match(regex);
  if (rresult) {
    const tmp: number = rresult.index + rresult[0].length;
    let result: string = "";
    if (rresult.index > 0) {
      result += str.substring(0, rresult.index);
    }
    result += `<span style="color:red;">${rresult[0]}</span>`;
    result += str.substring(tmp, str.length);
    return result;
  } else {
    return str;
  }
}

function createAutocompleteItem(owner: JQuery<HTMLElement>, ul: JQuery<HTMLElement>, item: any): JQuery<Element> {
  let li: JQuery<HTMLElement>;
  let nameResult: string;
  let cityResult: string;
  const term: string = owner.attr("data-req-term").trim();
  const regex: RegExp = new RegExp(term, "i");
  if (item.hotel) {
    li = $("<li>").addClass("searchfield");
    nameResult = createSpanFromMatch(item.name, regex);
    cityResult = createSpanFromMatch(item.city.name, regex);
    const countryResult: string = createSpanFromMatch(item.country.name, regex);
    const adressResult: string = createSpanFromMatch(item.address, regex);
    let stars: string = "";
    for (let s: number = 0; s < item.rating; s += 1) {
      stars += '<img class="star-image" style="height:20px;width:20px;">';
    }
    li.append(`<div class="autocomplete-container"><div class="container"><span class="name">${nameResult}</span><span class="country">${countryResult + ", " + cityResult + ", " + adressResult}</span></div><div class="iata">${stars}</div></div>`);
    li.appendTo(ul);
    return li;
  } else {
    const atype: string = item.city_airport === true ? "cityairport" : item.airport === true ? "airport" : "city";
    const countryText: string = item.airport === true ? `${item.city}, ${item.country}` : `${item.country}`;
    const iataResult: string = createSpanFromMatch(item.iata, regex);
    nameResult = createSpanFromMatch(item.name, regex);
    cityResult = createSpanFromMatch(countryText, regex);

    li = $("<li>").addClass("searchfield").addClass(atype);
    li.append(`<div class="autocomplete-container"><div class="container"><span class="name">${nameResult}</span><span class="country">${cityResult}</span></div><div class="iata">${iataResult}</div></div>`);
    li.appendTo(ul);
    return li;
  }
}

export class Autocomplete {
  public static load_autocomplete_fields(targetSelector: string = ".airport_field", enableAutocompleteSelect: boolean = true): void {
    $(targetSelector).each(function(): void {
      $(this).autocomplete({
        delay: 10,
        minLength: 0,
        source(request, response): any {
          $(this.element[0]).attr("data-req-term", request.term);
          $.ajax({
            url: $(this.element[0]).attr("data-source"),
            dataType: "json",
            data: {
              term: request.term
            },
            success(data): any {
              const results: object[] = [];
              $.map(data.cities, function(value, key) {
                results.push(value);

                return $.map(value.airports, (value2, key2) => results.push(value2));
              });
              $.map(data.airports, (value, key) => results.push(value));
              $.map(data.hotels, (value, key) => results.push(value));
              return response(results);
            },
            error(): any {
              return response([]);
            }
          });
          return null;
        },
        focus(event, ui): boolean {
          return false;
        },
        select(event, ui): boolean {
          const qel: JQuery<HTMLElement> = $(event.currentTarget);
          qel.val(ui.item.fullname);
          $(qel.attr("data-id-element")).val(ui.item.id);
          return false;
        }
      })
      .data("ui-autocomplete")._renderItem = function(ul, item) {
        return createAutocompleteItem($(this.element[0]), ul, item);
      };

      if (enableAutocompleteSelect) {
        $(targetSelector).on("autocompleteselect", function(): void {
          if ($(this)[0].id.indexOf("origin") !== -1) {
            const id: string = $(this)[0].id.split("_")[2];
            $(`#search_legs_${id}_destination_text`).focus();
          }
        });
      }

      $(targetSelector).focus(function(): void {
        $(this).keydown();
      });

      $(targetSelector).on("blur", function(): void {
        const value: string = $(this).val();
        if (value.trim() === "") {
          $(this).val("");
        }
      });
    });
  }
}
