(function ($) { $.fn.datepicker.language["nb-NO"] = {
    days: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
    daysShort: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
    daysMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
    months: ["januar","februar","mars","april","mai","juni", "juli","august","september","oktober","november","desember"],
    monthsShort: ["jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"],
    today: "Idag",
    clear: "Clear",
    dateFormat: "dd.mm.yyyy",
    timeFormat: "hh:ii aa",
    firstDay: 1
}; }(jQuery));
