export * from "./classes/alerts";
export * from "./classes/event_handler";
export * from "./classes/form_cache";
export * from "./classes/http";
export * from "./classes/translation";
export * from "./classes/utils";

import accounting from "accounting";

declare const gon: any;

/**
 * Update the currency settings using the settings from gon.
 */
export function setCurrencySettings(): void {
    accounting.settings = {
        currency: {
            symbol: gon.currency.unit,
            format: {
                pos: gon.currency.format.replace("%n", "%v").replace("%u", "%s"),
                neg: gon.currency.negative_format.replace("%n", "%v").replace("%u", "%s"),
                zero: gon.currency.format.replace("%n", "%v").replace("%u", "%s")
            },
            decimal: gon.currency.separator,
            thousand: gon.currency.delimiter,
            precision: gon.currency.precision
        },
        number: {
            precision: 0,
            thousand: gon.currency.delimiter,
            decimal: gon.currency.separator
        }
    };
}
