import Vue from "vue";
import { appendArrayIDSafe } from "../helpers";

export default {
  updateGon(state: any, gon: any) {
    // TODO: Make it possible to have banners for both hotel and flight
    Vue.set(state, "flightBanners", gon.banners);
    Vue.set(state, "flightBannersRight", gon.vbanner);
    Vue.set(state, "hotelBanners", gon.banners);
    Vue.set(state, "hotelBannersRight", gon.vbanner);

    Vue.set(state, "search_parameters", gon.search_parameters);
  },

  searchStatus(state: any, value: any) {
    state.searchStatus = value;
  },
  /**
   * This is called from Search Channel.
   *
   * @param state
   * @param value
   */
  updateFilterData(state: any, value: any) {
    if (value.view_state === "air") {
      state.flightFilter = value;
    } else {
      state.hotelFilter = value;
    }
  },
  updateItineraries(state: any, value: any) {
    const resp: any = value.data;
    const flush: boolean = value.type === "set";

    switch (resp.type) {
      case "package": {
        state.hotelItinerariesCount = resp.results_count;
        state.hotelItinerariesCountMax = resp.results_count_max;
        state.hotelItinerariesCountRemoved = resp.results_count_removed;
        state.hotelItinerariesOffset = resp.current_offset;
        state.hotelRoomsPax = resp.rooms_pax;
        appendArrayIDSafe(state.hotelItineraries, resp.itineraries.data, flush);

        if (resp.selected_itinerary.data) {
          state.alternativeItinerary = resp.selected_itinerary.data;
        }
        break;
      }

      case "hotel": {
        state.hotelItinerariesCount = resp.results_count;
        state.hotelItinerariesCountMax = resp.results_count_max;
        state.hotelItinerariesCountRemoved = resp.results_count_removed;
        state.hotelItinerariesOffset = resp.current_offset;
        state.hotelRoomsPax = resp.rooms_pax;
        appendArrayIDSafe(state.hotelItineraries, resp.itineraries.data, flush);
        break;
      }

      case "air": {
        state.flightItinerariesCount = resp.results_count;
        state.flightItinerariesCountMax = resp.results_count_max;
        state.flightItinerariesCountRemoved = resp.results_count_removed;
        state.flightItinerariesOffset = resp.current_offset;
        appendArrayIDSafe(state.flightItineraries, resp.itineraries.data, flush);
        break;
      }
    }

    state.loadMoreResultsRunning = false;
  },
  updateAlternativeData(state: any, value: any) {
    state.alternativeMarkup = value.markup;
    state.alternativeBaggageData = value.baggage;
  }
};
