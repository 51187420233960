<template>
  <div class="search-body" v-scroll="handleScroll">
    <div class="search-body-filter">
      <div v-if="hasDiscount" class="internet-bank-text" style="text-align: center;">
        <span :title="discountTitle">{{ discountContent }}</span>
      </div>

      <template v-if="$store.getters.searching">
        <div class="fake-filter">
          <div class="fake-filter-header"></div>
          <div class="fake-filter-item"></div>
          <div class="fake-filter-item"></div>
          <div class="fake-filter-header"></div>
          <div class="fake-filter-item"></div>
          <div class="fake-filter-item"></div>
          <div class="fake-filter-item"></div>
          <div class="fake-filter-header"></div>
          <div class="fake-filter-item"></div>
          <div class="fake-filter-item"></div>
          <div class="fake-filter-item"></div>
          <div class="fake-filter-item"></div>
        </div>
      </template>
      <template v-else>
        <keep-alive>
          <search-filter-hotel v-if="$store.getters.viewState === 1" />
          <search-filter-flight v-else />
        </keep-alive>
      </template>
    </div>

    <div class="search-body-results">
      <template v-if="$store.getters.searching">
        <template v-if="$store.getters.viewState === 1">
          <itinerary-hotel-fake />
          <itinerary-hotel-fake />
        </template>

        <template v-if="$store.getters.viewState === 0">
          <div class="fake-sort_by_group-container">
            <div class="sort_by_group">
              <ul class="sort_by">
                <li><div /></li>
                <li><div /></li>
                <li><div /></li>
              </ul>
            </div>
          </div>

          <itinerary-air-fake />
          <itinerary-air-fake />
        </template>
      </template>
      <template v-else>
        <keep-alive>
          <step-1-results-hotel v-if="$store.getters.viewState === 1" />
          <step-1-results-air v-else />
        </keep-alive>
      </template>
    </div>

    <div ref="banner" class="search-body-banner">
      <div v-if="vbanner && vbanner.length > 0" class="banner-container">
        <a v-for="banner in vbanner" :key="banner.image" :href="banner.link" target="_blank">
          <img :src="`/assets/${banner.image}` | cloudinary({ e_trim: false })" style="margin-bottom: 10px; margin-right: 10px;"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
/* global gon */

import SearchFilterFlight from "./filters/search_filter_flight.vue";
import SearchFilterHotel  from "./filters/search_filter_hotel.vue";
import Step1ResultsAir    from "./step_1_results_air.vue";
import Step1ResultsHotel  from "./step_1_results_hotel.vue";
import ItineraryAirFake   from "./itineraries/itinerary_air_fake.vue";
import ItineraryHotelFake from "./itineraries/itinerary_hotel_fake.vue";
import { SearchType }     from "app/store/defs";
import { isNull, documentHeight, windowHeight, scrollPosition } from "app/helpers";

export default {
  components: { SearchFilterFlight, SearchFilterHotel,
                Step1ResultsAir, Step1ResultsHotel, ItineraryAirFake, ItineraryHotelFake },
  data() {
    return {
      bannerLeft: 0
    };
  },
  computed: {
    loading() {
      return this.$store.state.searchStatus !== "ready";
    },
    vbanner() {
      if (this.$store.getters.viewState === 1) {
        return this.$store.state.hotelBannersRight;
      } else {
        return this.$store.state.flightBannersRight;
      }
    },
    hasDiscount() {
      return !isNull(gon.has_discount);
    },
    discountTitle() {
      return this.hasDiscount ? gon.has_discount.title : null;
    },
    discountContent() {
      return this.hasDiscount ? gon.has_discount.content : null;
    }
  },
  methods: {
    handleScroll: function (evt, el) {
      // Handle load more itineraries
      if (!this.$store.state.loadMoreResultsRunning && !this.$store.getters.searching) {
        if ((windowHeight() + scrollPosition()) >= documentHeight() - 1250) {
          this.$store.dispatch("loadMoreResults");
          this.$store.state.loadMoreResultsRunning = true;
        }
      }

      // Handle banner
      const bannerTop = 10;
      const header = document.getElementById("header");
      if (header) {
        const headerHeight = header.clientHeight;
        const scrollOffsetTop = document.getElementsByClassName("search-body-banner")[0].offsetTop;

        const bannerContainer = document.getElementsByClassName("banner-container")[0];
        if (bannerContainer) {
          const currentPosition = bannerContainer.style.position;

          if (window.top.scrollY > scrollOffsetTop - (headerHeight + bannerTop)) {
            if (currentPosition === "static") {
              bannerContainer.style.position = "fixed";
              bannerContainer.style.top = `${headerHeight + bannerTop}px`;
            }

            // Set the X position
            const searchBody = document.getElementsByClassName("search-body-results")[0];
            const searchBodyRect = searchBody.getBoundingClientRect();
            bannerContainer.style.left = `${searchBodyRect.right}px`;
          } else {
            this.bannerLeft = bannerContainer.getBoundingClientRect().x;
            if (currentPosition !== "static") {
              bannerContainer.style.position = "static";
              bannerContainer.style.left = "0";
            }
          }
        }
      }
    }
  },
  mounted() {
    // Lets update it again so we are sure we have it.
    this.$store.dispatch("updateGon");

    const self = this;
    $(document).on("runFilter", (event, filter) => {
      self.$store.dispatch("runFilter", filter);
    });

    // $(window).on("hashchange", () => {
    //   this.$store.dispatch("updateViewState", null);
    // });
  }
};
</script>

<style scoped>
.search-body {
  font-size: 0;
}

.search-body-filter,
.search-body-results,
.search-body-banner {
  vertical-align: top;
  display: inline-block;
  font-size: 16px;
}

.search-body-filter {
  width: 23%;
}
.search-body-results {
  width: 65%;
  padding-left: 15px;
  padding-right: 15px;
}
.search-body-banner {
  width: 12%;
}
.search-body-results .title p {
  font-size: 26px;
  padding-bottom: 22px;
}
.search-body-results .title small {
  font-size: 20px;
}
.search-body-banner img {
  border-radius: 5px;
  max-width: 150px;
}


/* Placing the css here because it is scoped */
.fake-filter-header,
.fake-filter-item {
  animation: fakeFilterAnimation 1s infinite;
}

@keyframes fakeFilterAnimation {
  0% {
    background-color: #2f29d1;;
  }
  50% {
    background-color: #2c26cf;
  }
  100% {
    background-color: #2f29d1;
  }
}

</style>
