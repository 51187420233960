
declare const gon: any;

export class Datepickers {
  public static load_datepickers(): void {
    const searchLegs0Date = $("#search_legs_0_date");
    this.leg0DatepickerDate = $("#search_legs_0_date").datepicker({
      language: gon.locale,
      selectOtherMonths: true,
      showOtherMonths: true,
      changeMonth: true,
      changeYear: true,
      toggleSelected: false,
      startDate: new Date(searchLegs0Date.attr("data-defaultDate")),
      minDate: new Date(searchLegs0Date.attr("data-mindate")),
      maxDate: new Date(searchLegs0Date.attr("data-maxdate")),
      autoClose: true,
      altFieldDateFormat: "yyyy-mm-dd",
      onSelect: this.datepickerOnSelect0
    }).data("datepicker");

    if (this.leg0DatepickerDate) {
      this.leg0DatepickerDate.selectDate(new Date(searchLegs0Date.attr("data-defaultDate")));
    }

    const searchLegs1Date: JQuery<HTMLElement> = $("#search_legs_1_date");
    this.leg1DatepickerDate = $("#search_legs_1_date").datepicker({
      selectOtherMonths: true,
      showOtherMonths: true,
      language: gon.locale,
      changeMonth: true,
      changeYear: true,
      toggleSelected: false,
      startDate: new Date(searchLegs1Date.attr("data-defaultDate")),
      minDate: new Date(searchLegs1Date.attr("data-mindate")),
      maxDate: new Date(searchLegs1Date.attr("data-maxdate")),
      autoClose: true,
      altFieldDateFormat: "yyyy-mm-dd",
      onSelect: this.datepickerOnSelect1
    }).data("datepicker");

    if (this.leg1DatepickerDate) {
      this.leg1DatepickerDate.selectDate(new Date(searchLegs1Date.attr("data-defaultDate")));
    }
  }

  public static load_datepickers_inline(): void {
    const legsDate0: JQuery<HTMLElement> = $("#search_legs_0_datepicker");
    const legsDate1: JQuery<HTMLElement> = $("#search_legs_1_datepicker");

    this.leg0Datepicker = $("#search_legs_0_datepicker").datepicker({
      language: gon.locale,
      classes: "inline-picker",
      selectOtherMonths: true,
      showOtherMonths: true,
      changeMonth: true,
      changeYear: true,
      toggleSelected: false,
      altField: "#search_legs_0_date",
      startDate: new Date(legsDate0.attr("data-defaultDate")),
      minDate: new Date(legsDate0.attr("data-mindate")),
      maxDate: new Date(legsDate0.attr("data-maxdate")),
      altFieldDateFormat: "yyyy-mm-dd",
      onSelect: this.datepickerInlineOnSelect0
    }).data("datepicker");

    this.leg1Datepicker = $("#search_legs_1_datepicker").datepicker({
      selectOtherMonths: true,
      showOtherMonths: true,
      language: gon.locale,
      changeMonth: true,
      changeYear: true,
      toggleSelected: false,
      classes: "inline-picker",
      altField: "#search_legs_1_date",
      startDate: new Date(legsDate1.attr("data-defaultDate")),
      minDate: new Date(legsDate1.attr("data-mindate")),
      maxDate: new Date(legsDate1.attr("data-maxdate")),
      altFieldDateFormat: "yyyy-mm-dd",
      onSelect: this.datepickerInlineOnSelect1
    }).data("datepicker");

    if (this.leg0Datepicker) {
      const defaultDate: Date = new Date(legsDate0.attr("data-defaultDate"));
      this.leg0Datepicker.selectDate(defaultDate);
    }

    if (this.leg1Datepicker) {
      const defaultDate: Date = new Date(legsDate1.attr("data-defaultDate"));
      this.leg1Datepicker.selectDate(defaultDate);
    }

    // MONKEYPATCH!
    // There is an internal error inside AirDatepicker that sets the inputfield to NaN-NaN-NaN.
    // So we just force update the input field after x time to make sure it gets updated correctly.
    setTimeout(function () {
      if (this.leg0Datepicker) { this.leg0Datepicker._setInputValue(); }
      if (this.leg1Datepicker) { this.leg1Datepicker._setInputValue(); }
    }.bind(this), 10);
  }

  private static leg0Datepicker: any;
  private static leg1Datepicker: any;
  private static leg0DatepickerDate: any;
  private static leg1DatepickerDate: any;

  private static datepickerOnSelect0(formattedDate, date, inst): void {
    if (!Datepickers.leg1DatepickerDate) {
      return;
    }

    Datepickers.leg1DatepickerDate.update("minDate", date);
    if (date > Datepickers.leg1DatepickerDate.selectedDates[0]) {
      Datepickers.leg1DatepickerDate.selectDate(date);
    }

    setTimeout(function() {
      $("#search_legs_1_date").datepicker().data("datepicker").show();
    }, 250);
  }

  private static datepickerOnSelect1(formattedDate, date, inst): void {
    if ($("#search_legs_1_hotel_date").length > 0) {
      $("#search_legs_0_date").datepicker().data("datepicker").update("maxDate", date);
      $("#search_legs_1_date").datepicker().data("datepicker").update("maxDate", date);
      $("#search_legs_1_date").datepicker().data("datepicker").selectDate(date);
    }
  }

  private static datepickerInlineOnSelect0(formattedDate, date, inst): void {
    if (!Datepickers.leg1Datepicker) {
      return;
    }

    let newDate = new Date(date);
    // If there are hotels we add one day to the next day.
    const searchType: string = $(".search-field").attr("data-view");
    if (searchType === "hotel" || searchType === "package") {
      newDate.setDate(newDate.getDate() + 1);
    }

    Datepickers.leg1Datepicker.update("minDate", newDate);

    if (newDate > Datepickers.leg1Datepicker.selectedDates[0]) {
      Datepickers.leg1Datepicker.selectDate(newDate);
    }

    if ($("#search_legs_0_hotel_date").length > 0) {
      $("#search_legs_0_hotel_date").datepicker().data("datepicker").update("minDate", date);
      $("#search_legs_0_hotel_date").datepicker().data("datepicker").selectDate(date);
      $("#search_legs_1_hotel_date").datepicker().data("datepicker").update("minDate", newDate);
    }
  }

  private static datepickerInlineOnSelect1(formattedDate, date, inst): void {
    if ($("#search_legs_1_hotel_date").length > 0) {
      $("#search_legs_0_hotel_date").datepicker().data("datepicker").update("maxDate", date);
      $("#search_legs_1_hotel_date").datepicker().data("datepicker").update("maxDate", date);
      $("#search_legs_1_hotel_date").datepicker().data("datepicker").selectDate(date);
    }
  }
}
