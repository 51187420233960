import { AirResults } from "../../itineraries_result/scripts/air_results";
//import { HotelResults } from "../../itineraries_result/scripts/hotel_results";

declare const gon: any;

export module Itinerary {
  export function step_1_form_submit(f) {
    $(".spinner", f).show();
    $("input[type=submit][class=fly]", f).each(function() {
      $(this).parent().hide();
      $(".fly-schedule").addClass("overlay");
      $(this).closest(".fly-schedule").removeClass("overlay");
      $("input[type=submit][class=fly]").attr("disabled", "disabled");
    });
  }

  let loadingMoreOffset = 0;
  let loadingMoreResults = false;

  export function loadMoreResults() {
    if (loadingMoreResults) {
      return;
    }

    loadingMoreOffset += 15;
    loadingMoreResults = true;

    $.get(`${document.location.href}/more/${loadingMoreOffset}`, { },
      function(data) {
        // if data.length > 0
        $("#search_results").append(data);
        loadingMoreResults = false;
        if ((gon.search.search_type === "hotel") || (gon.search.search_type === "package")) {
          //HotelResults.load_step_1_hotel_results();
        } else {
          AirResults.load_step_1_air_results();
        }
    });
  }
}

export default Itinerary;
