import { Track } from "components/common/track";
import { navigate } from "./utils";
import { translate } from "./translation";

/**
 * Destroy all tooltipster tooltips.
 */
export function destroyAllTooltips(): void {
    // @ts-ignore
    $.tooltipster.instances().forEach((element): void => {
        element.destroy();
    });
}

// Used so we only can create one alert at once.
let createdAlert: boolean = false;

/**
 * Show a stylized popup alert.
 * @param title Title of the alert.
 * @param message Message of the alert.
 * @param url Address the browser will navigate to when the alert is closed.
 * @param onClose Callback function on closing, return false and it will skip navigating.
 */
export function showAlert(context: string, title: string, message: string, url: string = null,
                          onClose: () => boolean = null) {
    if (createdAlert) {
        return;
    }
    createdAlert = true;

    Track.log(`Show alert "${title}" (${context})`, { "message": message, "context": context });

    const messageDiv: JQuery<HTMLElement> = $(`<div><h1>${title}</h1><span>${message}</span></div>`);

    $.fancybox.open(messageDiv, {
        afterClose(): void {
            createdAlert = false;

            let doNavigate = true;
            if (onClose) {
                doNavigate = onClose();
            }

            if (url !== null && doNavigate) {
                navigate(url);
            }
        }
    });
}

/**
 * Show a stylized timeout popup alert.
 */
export function showServerTimeoutAlert(context: string = "") {
    showAlert(
        context,
        translate("Server Problem"),
        translate("Det ser ut som servern tar för lång tid att svara, försök igen senare."),
        "/");
}

/**
 * Show a stylized exception popup alert.
 */
export function showException(context: string = "") {
    showAlert(
        context,
        translate("Något gick fel"),
        translate("Försök igen eller kontakta kundservice."),
        "/");
}

export function showNoResultsAlert(context: string = "", youth: boolean = false) {
  if (youth == true) {
    showAlert(
        context,
        translate("Förlåt, inga resultat hittades"),
        translate("Vad gäller ungdomspriser kan vi endast visa med avresa från Sverige eller Köpenhamn."));
  } else {
    showAlert(
        context,
        translate("Förlåt, inga resultat hittades"),
        translate("Vi kunde inte hitta några resultat för detta datum och destination. " +
            "Var snäll och prova ett annat datum eller kontakta vår kundservice."),
        "/");
  }
}

export function showTicketsNotConfirmedAlert(context: string = "") {
    showAlert(
        context,
        translate("Något gick fel"),
        translate("Biljetterna finns inte kvar längre. Var snäll och gör ett nytt sök."),
        "/");
}


let unsaved_changes: boolean = false;

export function enableUnsavedChanges() {
    $(":button, :submit, .back").on("click", (): void => {
        this.disable();
    });

    unsaved_changes = true;
    $(":input" || ":checked").change(() => {
        unsaved_changes = false;
    });

    $(window).bind("beforeunload", (): string => {
        if (unsaved_changes) {
            return translate("Du har olagrade ändringar på den här sidan. Vill du lämna den här sidan och kasta bort dina ändringar eller stanna kvar på den här sidan?");
        }
    });
}

export function disableUnsavedChanges(): void {
    $(window).unbind("beforeunload");
}
