import axios, {AxiosPromise} from "axios";
import {Track} from "components/common/track";

function axiosGetUrl(config) {
    if (config.baseURL) {
        return config.url.replace(config.baseURL, '');
    }
    return config.url;
}

axios.interceptors.request.use(
    (config) => {
        // Track.log(`[Channel] ${config.method.toUpperCase()} - ${axiosGetUrl(config)}`, JSON.stringify(config));
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        // Track.log(`[Channel] ${response.status} - ${axiosGetUrl(response.config)}`, JSON.stringify(response));
        return response;
    },
    (error) => {
        if (error.response) {
            const status = error.response ? error.response.status : "No response";
            Track.log(`[Channel] ${status} - ${axiosGetUrl(error.response.config)}`, JSON.stringify(error.response));
        }
        return Promise.reject(error);
    }
);

export class HttpHelper {
    public static get(url: string): AxiosPromise<any> {
        return axios.get(url, this.defaultOptions());
    }

    public static post(url: string, options: any = {}): AxiosPromise<any> {
        return axios.post(url, options, this.defaultOptions());
    }

    private static defaultOptions(): {} {
        const metaCertToken: HTMLMetaElement = document.querySelector("meta[name='csrf-token']");
        return {
            timeout: 10000,
            headers: {
                "X-CSRF-TOKEN": metaCertToken.content
            }
        };
    }
}
