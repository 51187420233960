<script>
import SearchFilterItem from "./search_filter_item.vue";
import Slider from "app/form/slider";
import MultiSlider from "app/form/multi_slider";
import Checkbox from "app/form/checkbox";
import SwitchButton from "app/form/switch_button";
import TimeRangeSlider from "app/form/time_range_slider";

import { ViewState } from "../../store/defs";

export default {
  components: { SearchFilterItem, Slider, MultiSlider, Checkbox, SwitchButton, TimeRangeSlider },
  computed: {
    filterData() {
      switch (this.$store.getters.viewState) {
        default:
        case ViewState.air: return this.$store.state.flightFilter;
        case ViewState.hotel: return this.$store.state.hotelFilter;
      }
    }
  },
  watch: {
    filterData(val) {
      if (!this.initialized) {
        this.initialized = true;

        if (val.legs) {
          var minMaxStops = {};
          var maxMaxStops = {};
          var maxTraveltimeSeg = {};

          for (var i = 0; i < val.legs.length; i++) {
            minMaxStops[i] = val.legs[i].min_stops;
            maxMaxStops[i] = val.legs[i].max_stops;
            maxTraveltimeSeg[i] = val.max_traveltime_segments[i];

            this.filterArrivalTime[i] = this.minutesArrivalTime(i);
            this.filterDepartureTime[i] = this.minutesDepartureTime(i);
          }

          this.minTraveltime = val.min_traveltime;
          this.minTraveltimeSeg = val.min_traveltime_segments;
          this.maxTraveltime = val.max_traveltime;
          this.maxTraveltimeSeg = maxTraveltimeSeg;

          this.minMaxStops = minMaxStops;
          this.maxMaxStops = maxMaxStops;
        }

        this.filterPrice = [val.min_price, val.max_price];
      }
    }
  },
  data() {
    return {
      skipSubmit: false,
      initialized: false,
      minTraveltime: null,
      minTraveltimeSeg: {},
      maxTraveltime: null,
      maxTraveltimeSeg: {},
      minMaxStops: null,
      maxMaxStops: null,
      filterPrice: [0, 0],
      filterArrivalTime: {},
      filterDepartureTime: {}
    };
  },
  methods: {
    /** Flight Methods */
    maxTraveltimeChange(sender, value) {
      this.maxTraveltime = value;
    },
    maxTraveltimeSegChange(sender, values, context) {
      this.maxTraveltimeSeg[context] = values;
    },
    maxStopsChange(name, value, context) {
      this.maxMaxStops[context] = value;
    },
    minutesArrivalTime(legIndex) {
      if (this.filterData.min_arrival_time) {
        const startDate = new Date(this.filterData.legs[legIndex].from_date);
        const endDate1 = new Date(this.filterData.min_arrival_time[legIndex]);
        const endDate2 = new Date(this.filterData.max_arrival_time[legIndex]);
        return [
          this.minutesBetweenDates(startDate, endDate1),
          this.minutesBetweenDates(startDate, endDate2)
        ];
      } else {
        return [];
      }
    },
    minutesDepartureTime(legIndex) {
      if (this.filterData.min_departure_time) {
        const startDate = new Date(this.filterData.legs[legIndex].from_date);
        const endDate1 = new Date(this.filterData.min_departure_time[legIndex]);
        const endDate2 = new Date(this.filterData.max_departure_time[legIndex]);
        return [
          this.minutesBetweenDates(startDate, endDate1),
          this.minutesBetweenDates(startDate, endDate2)
        ];
      } else {
        return [];
      }
    },

    /** Hotel Methods */
    filterPriceChange(sender, values) {
      this.filterPrice = values;
    },

    /** General Methods */
    onSubmit(event) {
      event.preventDefault();

      this.$nextTick(() => {
        $(document).trigger("runFilter");
      });
    },
    minutesBetweenDates(date1, date2) {
      return ((date2 - date1) / 1000) / 60;
    },
    onChange(sender) {
      if (!this.skipSubmit) {
        this.$nextTick(() => { // Allow the form inputs to update.
          $(document).trigger("runFilter");
        });
      }
    },
    onReset() {
      this.skipSubmit = true;
      this.internalCallResetOnChildren(this);
      this.$nextTick(() => {
        this.skipSubmit = false;
        this.onChange(this);
      });
    },
    internalCallResetOnChildren(target) {
      if (target.reset) {
        target.reset();
      } else if (target.$children) {
        target.$children.forEach((e) => {
          if (e.reset) {
            e.reset();
          } else {
            this.internalCallResetOnChildren(e);
          }
        });
      }
    }
  },
  mounted() {
    const self = this;
    $(document).on("resetFilter", (event) => {
      self.onReset();
    });
  }
};
</script>
