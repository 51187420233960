<template>
  <div style="padding: 10px;">
    <div style="width: 49%; display: inline-block; vertical-align: top;">
      <h4 style="font-size: 18px;">Presentationrules</h4>
      <table>
        <tr v-for="key in Object.keys(info.presentationrules)" :key="key">
          <td style="padding-right: 10px;">{{ key }}</td>
          <td>{{ info.presentationrules[key] }}</td>
        </tr>
      </table>
    </div>

    <template v-if="info.itinerary && info.itinerary.data.attributes.template">
      <div style="width: 49%; display: inline-block; vertical-align: top;">
        <h4 style="font-size: 18px;">Itinerary Presentationrules</h4>
        <table>
          <tr v-for="key in Object.keys(info.itinerary.data.attributes.template)" :key="key">
            <td style="padding-right: 10px;">{{ key }}</td>
            <td>{{ info.itinerary.data.attributes.template[key] }}</td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  tabOptions: {
    title: "Presentationrules",
    canShow(info) {
      if (info && info.presentationrules) {
        return true;
      }
      return false;
    }
  },
  props: {
    info: Object
  }
};
</script>
