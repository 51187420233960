<template>
  <div class="itinerary-window" style="height: 157px; display: block;">
    <ul class="itinerary">
      <li class="segment-header">
        <div class="div1">#</div>
        <div>Dep. airport</div>
        <div>Dep. time</div>
        <div>Arr. airport</div>
        <div>Arr. time</div>
        <div class="div6">Leg</div>
        <div class="div6">Part</div>
        <div>Carrier</div>
        <div>Flightno.</div>
        <div>Op. carrier</div>
        <div>Op. flightno.</div>
        <div>Class</div>
        <div>Cabin</div>
        <div>Farebasis</div>
        <div>Brand</div>
        <div>Equipment</div>
        <div>Dep. term.</div>
        <div>Arr. term.</div>
        <div>Flight time</div>
        <div>Travel time</div>
        <div>Meal</div>
        <div>Ndc segment</div>
        <div>MD5</div>
      </li>

      <template v-for="leg in info.itinerary.data.attributes.segments">
        <li class="segment" v-for="segment in leg" :key="`segment_${segment.index}_${segment.md5key}`">
          <div class="center">{{ segment.index }}</div>
          <div>{{ segment.departure_airport }}</div>
          <div>{{ segment.departure_time }}</div>
          <div>{{ segment.arrival_airport }}</div>
          <div>{{ segment.arrival_time }}</div>
          <div class="center">{{ segment.leg_index }}</div>
          <div class="center">{{ segment.part_index }}</div>
          <div>{{ segment.carrier }}</div>
          <div>{{ segment.flightnumber }}</div>
          <div>{{ segment.operating_carrier }}</div>
          <div>{{ segment.operating_flightnumber }}</div>
          <div>{{ segment.flight_class }}</div>
          <div>{{ segment.cabin }}</div>
          <div>{{ segment.farebasis }}</div>
          <div>{{ segment.brand_name || '&nbsp;' }}</div>
          <div>{{ segment.equipment_type }}</div>
          <div>{{ segment.departure_terminal }}</div>
          <div>{{ segment.arrival_terminal }}</div>
          <div>{{ segment.flight_time }}</div>
          <div>{{ segment.travel_time }}</div>
          <div>{{ segment.meal_code }}</div>
          <div>{{ segment.ndc }}</div>
          <div>{{ segment.md5key }}</div>
        </li>
      </template>
    </ul>

    <!-- Alt itinerary -->
    <template v-if="info.itinerary2">
      <div class="separator itinerary-separator"></div>

      <ul class="itinerary" style="padding-top: 10px;">
        <li class="segment-header">
          <div class="div1">#</div>
          <div>Dep. airport</div>
          <div>Dep. time</div>
          <div>Arr. airport</div>
          <div>Arr. time</div>
          <div class="div6">Leg</div>
          <div class="div6">Part</div>
          <div>Carrier</div>
          <div>Flightno.</div>
          <div>Op. carrier</div>
          <div>Op. flightno.</div>
          <div>Class</div>
          <div>Cabin</div>
          <div>Farebasis</div>
          <div>Brand</div>
          <div>Equipment</div>
          <div>Dep. term.</div>
          <div>Arr. term.</div>
          <div>Flight time</div>
          <div>Travel time</div>
          <div>Meal</div>
          <div>MD5</div>
        </li>

        <template v-for="leg in info.itinerary2.data.attributes.segments">
          <li class="segment" v-for="segment in leg" :key="`segment_${segment.index}_${segment.md5key}`">
            <div class="center">{{ segment.index }}</div>
            <div>{{ segment.departure_airport }}</div>
            <div>{{ segment.departure_time }}</div>
            <div>{{ segment.arrival_airport }}</div>
            <div>{{ segment.arrival_time }}</div>
            <div class="center">{{ segment.leg_index }}</div>
            <div class="center">{{ segment.part_index }}</div>
            <div>{{ segment.carrier }}</div>
            <div>{{ segment.flightnumber }}</div>
            <div>{{ segment.operating_carrier }}</div>
            <div>{{ segment.operating_flightnumber }}</div>
            <div>{{ segment.flight_class }}</div>
            <div>{{ segment.cabin }}</div>
            <div>{{ segment.farebasis }}</div>
            <div>{{ segment.brand_name || '&nbsp;' }}</div>
            <div>{{ segment.equipment_type }}</div>
            <div>{{ segment.departure_terminal }}</div>
            <div>{{ segment.arrival_terminal }}</div>
            <div>{{ segment.flight_time }}</div>
            <div>{{ segment.travel_time }}</div>
            <div>{{ segment.meal_code }}</div>
            <div>{{ segment.md5key }}</div>
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  tabOptions: {
    title: "Segments",
    canShow(info) {
      if (info && info.itinerary) {
        return true;
      }
      return false;
    }
  },
  props: {
    info: Object
  },
  methods: {
    segmentBrandName(segment) {
      if (segment.brand && segment.brand.brand_name) {
        return segment.brand.brand_name;
      }
      return null;
    }
  }
};
</script>

<style scoped>
.itinerary {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.segment-header {
  display: table-row;
  background-color: #E8E8E8;
  border-bottom: 1px solid #7f7f7f;
}
.segment-header div {
  display: table-cell;
  padding-right: 20px;
  padding-bottom: 2px;
  font-size: 12px;
  white-space: nowrap;
}
.div1 {
  text-align: center;
  padding-right: 0;
  width: 20px;
}
.div6 {
  text-align: center;
  padding-right: 0;
  width: 50px;
}
.segment {
  display: table-row;
}
.segment div {
  display: table-cell;
  padding-right: 5px;
  border-bottom: 1px solid #7f7f7f;
  white-space: nowrap;
}
.segment div.center {
  text-align: center;
}

.separator {
  background-color: #d9d9d9;
  height: 8px;
  width: 100%;
}
.itinerary-separator {
  border: 1px solid rgb(154, 154, 154);
  margin-top: -1px;
}
</style>
