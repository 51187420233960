import store from "app/store/store";
import { ExceptionHandler } from "../../../common/exception_handler";
import { Track } from "../../../common/track";
import ItinerariesResult from "../../itineraries_result";
import { Tabs } from "../../tabs";
import { State } from "./searchfield_functions";
import {translate} from "../../../../app/helpers";

declare const gon: any;

export class SearchfieldWidget {
  public load(): void {
    this.registerActions();
    Tabs.customRadio("search[type]");
    this.loadPage();

    $(".search-field-form").on("submit", (eventObject: JQueryEventObject) => {
      if (store && store.state.searchStatus === "searching") {
        const text = translate("Du har olagrade ändringar på den här sidan. Vill du lämna den här sidan och förkasta dina ändringar eller stanna kvar på den här sidan?");
        return confirm(text);
      }
    });
  }

  public beginSearch(): void {
    setTimeout(() => State.show_searchfield(), 100);
    this.hideSearchfield();
  }

  public hideSearchfield(): void {
    $(".search_box_overlay").show();
    $(".search_box_overlay_top").show();

    if (gon.search.search_type === "package") {
      $(".search_box_overlay_top .package").show();
    } else if (gon.search.search_type === "hotel") {
      $(".search_box_overlay_top .hotel").show();
    } else {
      $(".search_box_overlay_top .air").show();
    }
  }

  public showSearchfield(): void {
    $(".search_box_overlay").hide();
    $(".search_box_overlay_top").hide();
  }

  private registerActions(): void {
    $(`[name="search[type]"]`).on("change", (eventObject: JQueryEventObject) => {
      const href: string = $(eventObject.target).attr("data-href");
      if (href && window.location.pathname.split("/").length === 2) {
        history.pushState(null, null, href);
      }
    });

    // Load the initial search field
    $(`input[type=radio][name="search[type]"]:checked`).each((key: number, element : Element) => {
      this.updateSearchtype($(element).val());
    });

    $("#air_form")
      .off("ajax:failure ajax:error")
      .on("ajax:failure ajax:error", (xhr, status, error) => {
        this.showSearchfield();
        Track.log("AJAX ERROR (Searchfield)");
      });

    $("#air_form").off("ajax:error").on("ajax:error", (eventObject: JQueryEventObject) => {
      ExceptionHandler.show_failure_screen();
    });

    $("#air_form")
      .off("ajax:success")
      .on("ajax:success", (e, data, status, xhr) => {
        if (data.url) {
          window.location.href = data.url;
        } else if (data.errors) {
          for (const errorObj of data.errors) {
            if ($(`#search_${errorObj[0]}`).length) {
              $(`#search_${errorObj[0]}`).tooltipster({
                contentAsHTML: true,
                theme: "tooltipster-red",
                trigger: "custom",
                content: errorObj[1].join("<br /><br />"),
              });
              $(`#search_${errorObj[0]}`).tooltipster("open");
            }
          }

          setTimeout(() => {
            // @ts-ignore
            $.tooltipster.instances().forEach((element: JQuery<HTMLElement>): void => {
              element.destroy();
            });
          }, 3000);
        } else {
          ItinerariesResult.load();
          $(".search_box_overlay").hide();
          for (const errorObj of data.errors) {
            if ($(`#search_${errorObj[0]}`).length) {
              $(`#search_${errorObj[0]}`).tooltipster({
                contentAsHTML: true,
                theme: "tooltipster-red",
                trigger: "custom",
                content: errorObj[1].join("<br /><br />"),
              });

              $(`#search_${errorObj[0]}`).tooltipster("open");

              setTimeout(() => {
                // @ts-ignore
                $.tooltipster.instances().forEach((element) => {
                  element.destroy();
                });
              }, 3000);
            }
          }
        }
      });

    $(`input[type=radio][name="search[type]"]`).on("change", (event: JQueryEventObject) => {
      const value: string = $(event.target).val();
      this.updateSearchtype(value);
    });
  }

  private loadPage(): void {
    State.load();
  }

  private updateSearchtype(value: string): boolean {
    if ($(".search-field").attr("data-view") === value) {
      return false;
    }

    const locationSearch: string = window.location.search.substring(1);
    switch (value) {
      case "return": {
        if ($(".search-field").attr("data-view") !== "oneway") {
          this.ajaxLoadForm("/load_form/return", locationSearch);
        }
        $(".return_overlay").hide();
        break;
      }

      case "oneway": {
        if ($(".search-field").attr("data-view") !== "return") {
          this.ajaxLoadForm("/load_form/oneway", locationSearch);
        }
        $(".return_overlay").show();
        break;
      }

      default: {
        this.ajaxLoadForm("/load_form/" + value, locationSearch);
        break;
      }
    }

    $(".search-field").attr("data-view", value);
    return true;
  }

  private ajaxLoadForm(endpoint: string, locationSearch: string): void {
    // TODO: JQuery doesnt scope the methods.
    $.get(endpoint, locationSearch, function(data) {
      $(".search-field")[0].innerHTML = data;
      this.loadPage();
    }.bind(this));
  }
}
