(function ($) { $.fn.datepicker.language["sv-SE"] = {
    days: ["söndag", "måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag"],
    daysShort: ["sön", "mån", "tis", "ons", "tor", "fre", "lör"],
    daysMin: ["sö", "må", "ti", "on", "to", "fr", "lö"],
    months: ["januari","februari","mars","april","maj","juni", "juli","augusti","september","oktober","november","december"],
    monthsShort: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
    today: "Idag",
    clear: "Rensa",
    dateFormat: "yyyy-mm-dd",
    timeFormat: "hh:ii aa",
    firstDay: 1
}; }(jQuery));
