export class Tabs {
  public static customRadio(radioName: string): void {
    const radioButton: JQuery<HTMLElement> = $(`input[type=radio][name="${radioName}"]`);
    $(radioButton).each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      target.wrap(`<span class="custom-radio-${radioName.replace("[", "_").replace("]", "")}"></span>`);
      if (target.is(":checked")) {
        target.parent().addClass("selected");
      }
    });

    $(radioButton).click((eventObject: JQueryEventObject): void => {
      const target: JQuery<HTMLElement> = $(eventObject.target);
      if (target.is(":checked")) {
        target.parent().addClass("selected");
      }
      $(radioButton).not(target).each((index: number, elem: Element): void => {
        const target: JQuery<HTMLElement> = $(elem);
        target.parent().removeClass("selected");
      });
    });
  }

  public static customCheckbox(checkboxName: string): void {
    const checkBox = $(`input[name="${checkboxName}"]`);
    $(checkBox).each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      target.wrap(`<span class="custom-checkbox-${checkboxName.replace("[", "_").replace("]", "")} ${target.attr("class")}"></span>`);
      if (target.is(":checked")) {
        target.parent().addClass("selected");
      }
    });

    $(checkBox).click((eventObject: JQueryEventObject): void => {
      $(eventObject.target).parent().toggleClass("selected");
    });
  }

  public static customCheckboxes(obj: JQuery<HTMLElement>, name: string): void {
    $(obj).each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      target.wrap(`<span class="custom-checkbox-${name} ${target.attr("class")}"></span>`);
      if (target.is(":checked")) {
        target.parent().addClass("selected");
      }
    });

    $(obj).click((eventObject: JQueryEventObject): void => {
      $(eventObject.target).parent().toggleClass("selected");
    });
  }
}
