<template>
  <div class="pricing-window" style="height: 100%;">
    <div class="pricing-row">
      <div class="total-pricing-window" v-if="info.itinerary">
        <ul class="total-pricing">
          <li class="line-header">
            <div>Description</div>
            <div>Type</div>
            <div>Cost</div>
            <div>Ex. rate</div>
            <div>Vat</div>
            <div>Sales</div>
            <div>Profit</div>
            <div>Pricerule</div>
            <div>Searchrule</div>
          </li>

          <li :class="{ 'line':  part.title !== 'Total', 'sumline': part.title === 'Total' }" v-for="part in info.itinerary.data.attributes.price_list" :key="`part_${part.title}`">
            <div>{{ part.title }}</div>
            <div>{{ part.price_type }}</div>
            <div>{{ part.net_price }} {{ part.currency }} <span v-if="part.ex_net_price">({{ part.ex_net_price }} {{ info.itinerary.data.attributes.currency }})</span></div>
            <div>{{ part.ex_rate }}</div>
            <div><template v-if="part.tax">{{ part.tax }} {{ part.currency }}</template></div>
            <div><template v-if="part.sales_price">{{ part.sales_price }} {{ part.currency }}</template></div>
            <div><template v-if="part.profit">{{ part.profit }} {{ part.currency }}</template></div>
            <div>{{ part.pricerule }}</div>
            <div>{{ part.searchrule }}</div>
          </li>
        </ul>
      </div>

      <div class="unit-pricing-window">
        <ul class="unit-pricing">
          <li class="line-header">
            <div>Passenger type</div>
            <div>Fare</div>
            <div>Tax</div>
            <div>Cost</div>
            <div>Sales</div>
            <div>Profit</div>
          </li>

          <li class="line" v-for="item in info.itinerary.data.attributes.unit_price_list" :key="item.title">
            <div>{{ item.title }}</div>
            <div>{{ item.fare }} {{ item.currency }} <span v-if="item.ex_net_price">({{ item.ex_net_price }})</span></div>
            <div>{{ item.tax }} {{ item.currency }}</div>
            <div>{{ item.net_price }} {{ item.currency }}</div>
            <div>{{ item.sales_price }} {{ item.currency }}</div>
            <div>{{ item.profit }} {{ item.currency }}</div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Alt itinerary -->
    <template v-if="info.itinerary2">
      <div class="separator itinerary-separator"></div>

      <div class="pricing-row">
        <div class="total-pricing-window">
          <ul class="total-pricing">
            <li class="line-header">
              <div>Description</div>
              <div>Type</div>
              <div>Cost</div>
              <div>Ex. rate</div>
              <div>Vat</div>
              <div>Sales</div>
              <div>Profit</div>
              <div>Pricerule</div>
              <div>Searchrule</div>
            </li>

            <li :class="{ 'line':  part.title !== 'Total', 'sumline': part.title === 'Total' }" v-for="part in info.itinerary2.data.attributes.price_list" :key="`part_${part.title}`">
              <div>{{ part.title }}</div>
              <div>{{ part.price_type }}</div>
              <div>{{ part.net_price }} {{ part.currency }}</div>
              <div>{{ part.ex_rate }}</div>
              <div><template v-if="part.tax">{{ part.tax }} {{ part.currency }}</template></div>
              <div><template v-if="part.sales_price">{{ part.sales_price }} {{ part.currency }}</template></div>
              <div><template v-if="part.profit">{{ part.profit }} {{ part.currency }}</template></div>
              <div>{{ part.pricerule }}</div>
              <div>{{ part.searchrule }}</div>
            </li>
          </ul>
        </div>

        <div class="unit-pricing-window">
          <ul class="unit-pricing">
            <li class="line-header">
              <div>Passenger type</div>
              <div>Fare</div>
              <div>Tax</div>
              <div>Cost</div>
              <div>Sales</div>
              <div>Profit</div>
            </li>

            <li class="line" v-for="item in info.itinerary2.data.attributes.unit_price_list" :key="item.title">
              <div>{{ item.title }}</div>
              <div>{{ item.fare }} {{ item.currency }} <span v-if="item.ex_net_price">({{ item.ex_net_price }})</span></div>
              <div>{{ item.tax }} {{ item.currency }}</div>
              <div>{{ item.net_price }} {{ item.currency }}</div>
              <div>{{ item.sales_price }} {{ item.currency }}</div>
              <div>{{ item.profit }} {{ item.currency }}</div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  tabOptions: {
    title: "Price",
    canShow(info) {
      if (info && info.itinerary && info.itinerary.data.attributes.price_list && info.itinerary.data.attributes.unit_price_list) {
        return true;
      }
      return false;
    }
  },
  props: {
    info: Object
  }
};
</script>

<style scoped>
.total-pricing-window {
  width: 60%;
  float: left;
  border-left-style: solid;
  border-left-width: 1px;
  height: 100%;
}
.pricing-row {
  display: inline-block;
  width: 100%;
}
.total-pricing {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.line-header {
  display: table-row;
  border-bottom: 1px solid #7f7f7f; /* $color1; */
}
.line-header div {
  display: table-cell;
  padding-right: 20px;
  padding-bottom: 2px;
  font-size: 12px;
  white-space: nowrap;
  padding-left: 5px;
}
.line {
  display: table-row;
}
.line div {
  display: table-cell;
  padding-right: 5px;
  padding-left: 5px;
  border-bottom: 1px solid #7f7f7f; /* $color7; */
  white-space: nowrap;
  padding-bottom: 3px;
}
.sumline {
  display: table-row;
}
.sumline div {
  display: table-cell;
  padding-right: 5px;
  padding-left: 5px;
  white-space: nowrap;
  padding-top: 0;
}
.unit-pricing-window {
  width: 40%;
  float: right;
  border-left-style: solid;
  border-left-width: 1px;
  height: 100%;
}

.unit-pricing {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.unit-pricing .line-header {
  display: table-row;
  /* background-color: $color2; */
  border-bottom: 1px solid #7f7f7f; /* $color1; */
}
.unit-pricing .line-header div {
  display: table-cell;
  padding-right: 20px;
  padding-bottom: 2px;
  font-size: 12px;
  white-space: nowrap;
  padding-left: 5px;
}
.line {
  display: table-row;
}
.line div {
  display: table-cell;
  padding-right: 5px;
  white-space: nowrap;
  padding-left: 5px;
}
.separator {
  background-color: #d9d9d9;
  height: 8px;
  width: 100%;
}
.itinerary-separator {
  border: 1px solid rgb(154, 154, 154);
  margin-top: -1px;
}
</style>
