<template>
  <div style="padding: 10px;">
    <p>Debug information</p>
    <br />

    <table>
      <tr v-for="key in Object.keys(state)" :key="key">
        <td style="padding-right: 10px;">{{ key }}</td>
        <td>{{ tryGetValue(key) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  tabOptions: {
    title: "Vue",
    canShow(info) {
      // Make it a little more user friendly for our fellow gate admins
      if (window.show_debug_vue) {
        return true;
      }
      return false;
    }
  },
  props: {
    info: Object
  },
  computed: {
    state() {
      return this.$store.state;
    }
  },
  methods: {
    tryGetValue(key) {
      try {
        if (this.state[key]) {
          return JSON.stringify(this.state[key]);
        }
      } catch (e) {
        return null;
      }
    }
  }
};
</script>
