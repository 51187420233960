import honeybadgerJs from "honeybadger-js";

export class Translation {
  private static dictionary: object = {};

  public static AddDictionary(dictionary: object): void {
    for (const key of Object.keys(dictionary)) {
      this.dictionary[key] = dictionary[key];
    }
  }

  public static FindText(key: string): string {
    if (!this.dictionary[key]) {
      honeybadgerJs.notify(`No entry found for key '${key}'`);
    }
    return this.dictionary[key] || key;
  }

  public static Translate(text: string, param: string[]): string {
    let newText: string = this.FindText(text);
    if (param != null) {
      const maxi: number = param.length;
      for (let i: number = 0; i < maxi; i += 1) {
        const regex: RegExp = new RegExp(`{%${(i + 1)}}`, "g");
        newText = newText.replace(regex, param[i]);
      }
    }
    return newText;
  }
}

export function __(text: string, param?: string[]): string {
  return Translation.Translate(text, param);
}
