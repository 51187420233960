import { Track } from "./track";
import { __ } from "./translation";
import {navigate} from "../../app/helpers";

declare const gon: any;

export class ExceptionHandler {
  public static show_failure_screen(): void {
    this.show_exception("Ajax Error", null);
    Track.log("Failed to load page.");
  }

  public static show_exception(title: string, errors: string[]): void {
    const errorTitle: string = title || __("Något gick fel");
    const errorMessages: string[] = errors || [__("Försök igen eller kontakta kundservice.")];
    const errorDiv: JQuery<HTMLElement> = this.create_error_div(errorTitle, errorMessages);

    Track.log(`Show exception '${title}'.`, errors);

    $.fancybox.open(errorDiv, {
      afterClose(): void {
        if (gon.links.fallback_url) {
          navigate(gon.links.fallback_url);
        } else {
          navigate("/");
        }
      }
    });
  }

  private static create_error_div(title: string, errors: string[]): JQuery<HTMLElement> {
    let newDiv: JQuery<HTMLElement> = $("#search_error_message");
    if (newDiv.length === 0) {
      newDiv = $(`<div id="search_error_message" style="display: none;">`);
      newDiv.appendTo("body");
    }

    if ($(".title", newDiv).length > 0) {
      $(".title", newDiv).html(title);
    } else {
      newDiv.append(`<h1 class='title'>${title}</h1>`);
    }

    let errorsContainer: JQuery<HTMLElement> = null;
    if ($(".errors", newDiv).length > 0) {
      errorsContainer = $(".errors", newDiv);
      errorsContainer.html("");
    } else {
      errorsContainer = $(`<div class="errors">`);
      errorsContainer.appendTo(newDiv);
    }

    errors.forEach((error: string) => {
      const errorElem: JQuery<HTMLElement> = $(`<p>${error}</p>`);
      errorElem.appendTo(errorsContainer);
    });

    return newDiv;
  }
}
