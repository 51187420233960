
export const locales = {
    /**
     * English
     */
    "en-GB": {
        actions: {
            close: "Close"
        },
        label: {
            empty: "This is a label"
        },
        seat_selector: {
            passenger_list: "Passenger List"
        },
        // new desktop components
        action_alert: {
            text: "Successfully upgraded cabin class to",
            cancel: "Cancel Upgrade"
        },
        brand: {
            booking_with: "booking with"
        },
        offer: {
            ribbon: "recommended",
            person: "person",
            luggage: "Luggage Add-ons",
            adult: "Adult",
            select: "Select",
            selected: "Your choice",
            inTotal: "in total",
            service_unavailable: "Service unavailable",
            chargeable_message: "* Available for a fee",
            features: {
                sustainable: 'More sustainable flying with SAF (20%) and emissions compensation (80%)',
                rewards: 'Extra bonus points (+20%)',
                refund_possible: "Refund possible",
                refund_not_possible: "Refund not possible",
                rebooking_possible: "Rebooking possible",
                rebooking_not_possible: "Rebooking not possible",
                hand_baggage: "%{value} hand luggage",
                hand_baggage_no: "No hand luggage",
                snacks_and_beverages: "Snacks & beverages",
                meals_and_beverages: "Meals & beverages",
                checkin_bags: "Check-in %{value} kg",
                checkin_bags_no: "No check-in baggage",
                checkin_bags_no_anc: "No check-in baggage (check below)",
                seat_reservation: "Seat reservation",
                priority_boarding: "Priority boarding (when possible)",
                lounge_access: "Lounge access",
                empty_middle_seat: "Empty middle seat",
                priority_services: "Priority services",
                refundable: "Refundable",
                seat_selection: "Seat selection",
                rebooking: "Rebooking",
                checked_baggage: "%{value} Checked baggage",
                under_seat: "Under the seat",
                in_flight_entertainment: "In-flight entertainment",
                in_seat_power: "In-seat power",
                internet: "Internet",
                tv: "TV",
                meals: "Meals",
                beverages: "Beverages",
                snacks: "Snacks",
                priority_checkin: "Priority check-in",
                priority_bag: "Priority baggage handling",
                priority_lane: "Priority lane",
            }
        },
        cabin: {
            upgrade_to: "Upgrade to",
            upgrade: "Upgrade"
        },
        action: {
            go_back_to_search: "Go back to search results",
            go_back: "Go back",
            continue: "Continue"
        },
        booking_timer: {
            air: "The selected flight is provisionally reserved. The remaining time until the reservation is released is:",
            package: "The selected flight + hotel is provisionally reserved. The remaining time until the reservation is released is:",
            hotel: "The selected hotel is provisionally reserved. The remaining time until the reservation is released is:",
            outoftime: "Time is up!",
            re_search: "Please do a new search via %{site}"
        }
    },
    /**
     * Swedish
     */
    "sv-SE": {
        site_url: "flygcity.se",
        actions: {
            close: "Stäng"
        },
        label: {
            empty: "Detta är en etikett"
        },
        seat_selector: {
            passenger_list: "Passagerarlista"
        },
        // new desktop components
        action_alert: {
            text: "Uppgraderade kabinklass till",
            cancel: "Avbryt uppgradering"
        },
        brand: {
            booking_with: "bokning med"
        },
        offer: {
            ribbon: "rekommenderad",
            person: "person",
            luggage: "Bagage-tillägg",
            adult: "vuxen",
            select: "Välj",
            selected: "Ert val",
            inTotal: "Totalt",
            service_unavailable: "Tjänsten är inte tillgänglig",
            chargeable_message: "* Tillgänglig mot en avgift",
            features: {
                sustainable: "Hållbarare flygande med SAF (20%) och utsläppskompensation (80%)",
                rewards: 'Extra bonuspoäng (+20%)',
                refund_possible: "Återbetalningsbar",
                refund_not_possible: "Ej återbetalningsbar",
                rebooking_possible: "Ombokningsbar",
                rebooking_not_possible: "Ej ombokningsbar",
                hand_baggage: "%{value} handbagage",
                hand_baggage_no: "Inget handbagage",
                snacks_and_beverages: "Snacks & drycker",
                meals_and_beverages: "Måltider & drycker",
                checkin_bags: "Incheckat bagage %{value} kg",
                checkin_bags_no: "Inget incheckat bagage",
                checkin_bags_no_anc: "Inget incheckat bagage (se nedan)",
                seat_reservation: "Platsreservation",
                priority_boarding: "Prioriterad ombordstigning (om möjligt)",
                lounge_access: "Tillgång till lounge",
                // priority_lane: "Priority lane (when possible)",
                empty_middle_seat: "Tomt mittsäte",
                priority_services: "Prioritetstjänster",
                refundable: "Återbetalningsbar",
                seat_selection: "Val av sittplats",
                rebooking: "Ombokningsbar",
                checked_baggage: "%{value} incheckat bagage",
                under_seat: "Väska under sätet",
                in_flight_entertainment: "Underhållning ombord",
                in_seat_power: "Eluttag vid sätet",
                internet: "Internet",
                tv: "TV",
                meals: "Måltider",
                beverages: "Drycker",
                snacks: "Snacks",
                priority_checkin: "Prioriterad incheckning",
                priority_bag: "Prioriterad bagagehantering",
                priority_lane: "Prioriterad säkerthetskontroll",
            }
        },
        cabin: {
            upgrade_to: "Uppgradera till",
            upgrade: "Uppgradera"
        },
        action: {
            go_back_to_search: "Gå tillbaka till sökresultaten",
            go_back: "Gå tillbaka",
            continue: "Fortsätt"
        },
        booking_timer: {
            air: "Valt flyg är preliminärt reserverat. Återstående tid tills reserveringen släpps är:",
            package: "Valt flyg+hotell är preliminärt reserverat. Återstående tid tills reserveringen släpps är:",
            hotel: "Valt hotell är preliminärt reserverat. Återstående tid tills reserveringen släpps är:",
            outoftime: "Tiden är ute!",
            re_search: "Vänligen gör nytt sök via %{site}"
        }
    },
    "pl-PL": {
        site_url: "flygcity.se",
        actions: {
            close: "Zamknij"
        },
        label: {
            empty: "To jest etykieta"
        },
        seat_selector: {
            passenger_list: "Lista pasażerów"
        },
        // new desktop components
        action_alert: {
            text: "Pomyślnie podwyższono klasę kabiny do",
            cancel: "Anuluj podwyższenie"
        },
        brand: {
            booking_with: "rezerwacja z"
        },
        offer: {
            ribbon: "zalecane",
            person: "osoba",
            luggage: "Bagaż",
            adult: "dorosły",
            select: "Wybierz",
            selected: "Twój wybór",
            inTotal: "Razem",
            service_unavailable: "Usługa nie jest dostępna",
            chargeable_message: "* Dostępne za opłatą",
            features: {
                sustainable: 'Zrównoważone latanie z SAF (20%) i kompensacją emisji (80%)',
                rewards: 'Dodatkowe punkty bonusowe (+20%)',
                refund_possible: "Możliwy zwrot",
                refund_not_possible: "Zwrot niemożliwy",
                rebooking_possible: "Możliwa zmiana rezerwacji",
                rebooking_not_possible: "Zmiana rezerwacji niemożliwa",
                hand_baggage: "%{value} bagaż podręczny",
                hand_baggage_no: "Brak bagażu podręcznego",
                snacks_and_beverages: "Przekąski i napoje",
                meals_and_beverages: "Posiłki i napoje",
                checkin_bags: "Bagaż do odprawy %{value} kg",
                checkin_bags_no: "Brak bagażu do odprawy",
                checkin_bags_no_anc: "Brak bagażu do odprawy (sprawdź poniżej)",
                seat_reservation: "Rezerwacja miejsc",
                priority_boarding: "Priorytetowe wejście na pokład (jeśli to możliwe)",
                lounge_access: "Dostęp do saloniku",
                empty_middle_seat: "Puste miejsce środkowe",
                priority_services: "Usługi priorytetowe",
                refundable: "Możliwość zwrotu",
                seat_selection: "Wybór miejsca",
                rebooking: "Zmiana rezerwacji",
                checked_baggage: "%{value} Zarejestrowany bagaż",
                under_seat: "Pod siedzeniem",
                in_flight_entertainment: "Rozrywka na pokładzie",
                in_seat_power: "Zasilanie w siedzeniu",
                internet: "Internet",
                tv: "Telewizja",
                meals: "Posiłki",
                beverages: "Napoje",
                snacks: "Przekąski",
                priority_checkin: "Priorytetowe zameldowanie",
                priority_bag: "Priorytetowa obsługa bagażu",
                priority_lane: "Priorytetowa kontrola bezpieczeństwa",
            }
        },
        cabin: {
            upgrade_to: "Podwyższenie do",
            upgrade: "Podwyższenie"
        },
        action: {
            go_back_to_search: "Wróć do wyników wyszukiwania",
            go_back: "Powrót",
            continue: "Kontynuuj"
        },
        booking_timer: {
            air: "Wybrany lot jest tymczasowo zarezerwowany. Pozostały czas do zwolnienia rezerwacji wynosi:",
            package: "Wybrany lot+hotel jest tymczasowo zarezerwowany. Pozostały czas do zwolnienia rezerwacji to:",
            hotel: "Wybrany hotel jest tymczasowo zarezerwowany. Pozostały czas do zwolnienia rezerwacji to:",
            outoftime: "Czas minął!",
            re_search: "Proszę przeprowadzić nowe wyszukiwanie poprzez %{site}"
        }
    },
    /**
     * Danish
     */
    "da-DK": {
        actions: {
            close: "Tæt"
        },
        label: {
            empty: "This is a label"
        },
        seat_selector: {
            passenger_list: "Passenger List"
        },
        // new desktop components
        action_alert: {
            text: "Opdateret kabineklasse til",
            cancel: "Annuller opgradering"
        },
        brand: {
            booking_with: "booking med"
        },
        offer: {
            ribbon: "anbefalede",
            person: "person",
            luggage: "Bagage",
            adult: "Voksen",
            select: "Vælg",
            selected: "Dit valg",
            inTotal: "in totaal",
            service_unavailable: "Service ikke tilgængelig",
            chargeable_message: "* Tilgængelig mod betaling",
            features: {
                sustainable: 'Mere bæredygtig flyvning med SAF (20%) og emissionskompensation (80%)',
                rewards: 'Ekstra bonuspoint (+20%)',
                refund_possible: "Refusion mulig",
                refund_not_possible: "Refusion ikke mulig",
                rebooking_possible: "Ombooking mulig",
                rebooking_not_possible: "Ombooking ikke mulig",
                hand_baggage: "%{value} håndbagage",
                hand_baggage_no: "Ingen håndbagage",
                snacks_and_beverages: "Snacks & drikkevarer",
                meals_and_beverages: "Måltider & drikkevarer",
                checkin_bags: "Indchecket bagage %{value} kg",
                checkin_bags_no: "Ingen indchecket bagage",
                checkin_bags_no_anc: "Ingen indchecket bagage (tjek nedenfor)",
                seat_reservation: "Sædereservation",
                priority_boarding: "Prioritet boarding (når det er muligt)",
                lounge_access: "Lounge adgang",
                empty_middle_seat: "Tomt midtersæde",
                priority_services: "Prioritetstjenester",
                refundable: "Kan refunderes",
                seat_selection: "Sædevalg",
                rebooking: "Ombooking",
                checked_baggage: "%{value} Indchecket bagage",
                under_seat: "Under sædet",
                in_flight_entertainment: "Underholdning ombord",
                in_seat_power: "Strøm i sædet",
                internet: "Internet",
                tv: "TV",
                meals: "Måltider",
                beverages: "Drikkevarer",
                snacks: "Snacks",
                priority_checkin: "Prioritet check-in",
                priority_bag: "Prioritet bagagehåndtering",
                priority_lane: "Prioriteret sikkerhedstjek",
            }
        },
        cabin: {
            upgrade_to: "Opgrader til",
            upgrade: "Opgrade"
        },
        action: {
            go_back_to_search: "Gå tilbage til søgeresultater",
            go_back: "Gå tilbage",
            continue: "Fortsæt"
        },
        booking_timer: {
            air: "Valgte fly er midlertidigt reserveret. Resterende tid indtil reservationen frigives er:",
            package: "Valgte fly+hotel er foreløbig reserveret. Den resterende tid til reserveringen udløber er:",
            hotel: "Valgte hotel er foreløbig reserveret. Den resterende tid til reserveringen udløber er:",
            outoftime: "Time is up!",
            re_search: "Please do a new search via %{site}"
        }
    },
    /**
     * Norwegian
     */
    "nb-NO": {
        actions: {
            close: "Lukk"
        },
        label: {
            empty: "This is a label"
        },
        seat_selector: {
            passenger_list: "Passenger List"
        },
        // new desktop components
        action_alert: {
            text: "Vellykket oppgradering av hytteklasse til",
            cancel: "Avbryt oppgradering"
        },
        brand: {
            booking_with: "booking med"
        },
        offer: {
            ribbon: "anbefales",
            person: "person",
            luggage: "Bagasje",
            adult: "Voksen",
            select: "Velge",
            selected: "Ditt valg",
            inTotal: "totalt",
            service_unavailable: "Tjenesten er utilgjengelig",
            chargeable_message: "* Tilgjengelig mot betaling",
            features: {
                sustainable: 'Mer bærekraftig flyging med SAF (20%) og utslippskompensasjon (80%)',
                rewards: 'Ekstra bonuspoeng (+20%)',
                refund_possible: "Refusjon mulig",
                refund_not_possible: "Refusjon ikke mulig",
                rebooking_possible: "Ombooking mulig",
                rebooking_not_possible: "Ombooking ikke mulig",
                hand_baggage: "%{value} håndbagasje",
                hand_baggage_no: "Ingen håndbagasje",
                snacks_and_beverages: "Snacks & drikkevarer",
                meals_and_beverages: "Måltider & drikkevarer",
                checkin_bags: "Innsjekket bagasje %{value} kg",
                checkin_bags_no: "Ingen innsjekket bagasje",
                checkin_bags_no_anc: "Ingen innsjekket bagasje (sjekk nedenfor)",
                seat_reservation: "Setereservasjon",
                priority_boarding: "Prioritet ombordstigning (når mulig)",
                lounge_access: "Lounge tilgang",
                empty_middle_seat: "Tomt midtersete",
                priority_services: "Prioritetstjenester",
                refundable: "Kan refunderes",
                seat_selection: "Setevalg",
                rebooking: "Ombooking",
                checked_baggage: "%{value} Innsjekket bagasje",
                under_seat: "Under setet",
                in_flight_entertainment: "Underholdning ombord",
                in_seat_power: "Strøm i setet",
                internet: "Internet",
                tv: "TV",
                meals: "Måltider",
                beverages: "Drikkevarer",
                snacks: "Snacks",
                priority_checkin: "Prioritet innsjekking",
                priority_bag: "Prioritet bagasjehåndtering",
                priority_lane: "Prioritert sikkerhetssjekk",
            }
        },
        cabin: {
            upgrade_to: "Oppgradere til",
            upgrade: "Oppgradering"
        },
        action: {
            go_back_to_search: "Gå tilbake til søkeresultatene",
            go_back: "Gå tilbake",
            continue: "Fortsette"
        },
        booking_timer: {
            air: "Vi holder fortsatt av plassene dine, vennligst book nå for å sikre din booking.",
            package: "Vi holder fortsatt av plassene dine, vennligst book nå for å sikre din booking:",
            hotel: "Vi holder fortsatt av plassene dine, vennligst book nå for å sikre din booking:",
            outoftime: "Time is up!",
            re_search: "Please do a new search via %{site}"
        }
    },
    /**
     * German
     */
    "de-DE": {
        actions: {
            close: "Schließen"
        },
        label: {
            empty: "This is a label"
        },
        seat_selector: {
            passenger_list: "Passenger List"
        },
        // new desktop components
        action_alert: {
            text: "Die Kabinenklasse wurde erfolgreich auf aktualisiert",
            cancel: "Upgrade abbrechen"
        },
        brand: {
            booking_with: "Buchung mit"
        },
        offer: {
            ribbon: "empfohlen",
            person: "person",
            luggage: "Gepäck",
            adult: "Erwachsene",
            select: "Wählen",
            selected: "Ihre Wahl",
            inTotal: "insgesamt",
            service_unavailable: "Dienst nicht verfügbar",
            chargeable_message: "* Gegen Gebühr verfügbar",
            features: {
                sustainable: 'Nachhaltigeres Fliegen mit SAF (20%) und Emissionskompensation (80%)',
                rewards: 'Extra Bonuspunkte (+20%)',
                refund_possible: "Rückerstattung möglich",
                refund_not_possible: "Rückerstattung nicht möglich",
                rebooking_possible: "Umbuchung möglich",
                rebooking_not_possible: "Umbuchung nicht möglich",
                hand_baggage: "%{value} Handgepäck",
                hand_baggage_no: "Kein Handgepäck",
                snacks_and_beverages: "Snacks & Getränke",
                meals_and_beverages: "Mahlzeiten & Getränke",
                checkin_bags: "Aufgegebenes Gepäck %{value} kg",
                checkin_bags_no: "Kein aufgegebenes Gepäck",
                checkin_bags_no_anc: "Kein aufgegebenes Gepäck (siehe unten)",
                seat_reservation: "Sitzplatzreservierung",
                priority_boarding: "Priorität Boarding (wenn möglich)",
                lounge_access: "Lounge Zugang",
                empty_middle_seat: "Leerer Mittelsitz",
                priority_services: "Prioritätsservice",
                refundable: "Rückerstattbar",
                seat_selection: "Sitzplatzauswahl",
                rebooking: "Umbuchung",
                checked_baggage: "%{value} Aufgegebenes Gepäck",
                under_seat: "Unter dem Sitz",
                in_flight_entertainment: "Unterhaltung an Bord",
                in_seat_power: "Strom im Sitz",
                internet: "Internet",
                tv: "TV",
                meals: "Mahlzeiten",
                beverages: "Getränke",
                snacks: "Snacks",
                priority_checkin: "Priorität Check-in",
                priority_bag: "Priorität Gepäckabfertigung",
                priority_lane: "Vorrangige Sicherheitskontrolle",
            }
        },
        cabin: {
            upgrade_to: "Upgraden auf",
            upgrade: "Aktualisierung"
        },
        action: {
            go_back_to_search: "Gehen Sie zurück zu den Suchergebnissen",
            go_back: "Geh zurück",
            continue: "Weiter"
        },
        booking_timer: {
            air: "Der von Ihnen gewählte Flug wurde vorläufig reserviert. Bis die Reservierung aufgehoben wird, dauert es noch:",
            package: "Ihr gewähltes Flug- und Hotelpaket wurde vorläufig reserviert. Bis die Reservierung aufgehoben wird, dauert es noch:",
            hotel: "Ihr gewähltes Hotel wurde vorläufig reserviert. Bis die Reservierung aufgehoben wird, dauert es noch:",
            outoftime: "Time is up!",
            re_search: "Please do a new search via %{site}"
        }
    },
    /**
     * French
     */
    "fr-FR": {
        actions: {
            close: "Fermer"
        },
        label: {
            empty: "This is a label"
        },
        seat_selector: {
            passenger_list: "Passenger List"
        },
        // new desktop components
        action_alert: {
            text: "La classe de cabine a été mise à jour avec succès pour",
            cancel: "Annuler la mise à niveau"
        },
        brand: {
            booking_with: "Réservation avec"
        },
        offer: {
            ribbon: "recommandé",
            person: "personne",
            luggage: "Bagages",
            adult: "Adulte",
            select: "Choisir",
            selected: "Votre choix",
            inTotal: "Au total",
            service_unavailable: "Service unavailable",
            chargeable_message: "* Disponible moyennant un supplément",
            features: {
                sustainable: 'Vol plus durable avec SAF (20%) et compensation des émissions (80%)',
                rewards: 'Points bonus supplémentaires (+20%)',
                refund_possible: "Remboursement possible",
                refund_not_possible: "Remboursement non possible",
                rebooking_possible: "Réembarquement possible",
                rebooking_not_possible: "Réembarquement non possible",
                hand_baggage: "%{value} bagage à main",
                hand_baggage_no: "Pas de bagage à main",
                snacks_and_beverages: "Snacks & boissons",
                meals_and_beverages: "Repas & boissons",
                checkin_bags: "Bagages enregistrés %{value} kg",
                checkin_bags_no: "Pas de bagages enregistrés",
                checkin_bags_no_anc: "Pas de bagages enregistrés (voir ci-dessous)",
                seat_reservation: "Réservation de siège",
                priority_boarding: "Embarquement prioritaire (si possible)",
                lounge_access: "Accès au salon",
                empty_middle_seat: "Siège du milieu vide",
                priority_services: "Services prioritaires",
                refundable: "Remboursable",
                seat_selection: "Sélection de siège",
                rebooking: "Réembarquement",
                checked_baggage: "%{value} Bagages enregistrés",
                under_seat: "Sous le siège",
                in_flight_entertainment: "Divertissement en vol",
                in_seat_power: "Alimentation en siège",
                internet: "Internet",
                tv: "TV",
                meals: "Repas",
                beverages: "Boissons",
                snacks: "Snacks",
                priority_checkin: "Enregistrement prioritaire",
                priority_bag: "Bagage prioritaire",
                priority_lane: "Contrôle de sécurité prioritaire",
            }
        },
        cabin: {
            upgrade_to: "Mise à niveau vers",
            upgrade: "Mise à jour"
        },
        action: {
            go_back_to_search: "Revenir aux résultats de la recherche",
            go_back: "Retourner",
            continue: "Plus loin"
        },
        booking_timer: {
            air: "Le vol que vous avez sélectionné a été provisoirement réservé. Tant que la réservation n'est pas annulée, il faut encore :",
            package: "Votre forfait vol et hôtel sélectionné a été provisoirement réservé. Tant que la réservation n'est pas annulée, il faut encore :",
            hotel: "Votre hôtel sélectionné a été provisoirement réservé. Tant que la réservation n'est pas annulée, il faut encore :",
            outoftime: "Time is up!",
            re_search: "Please do a new search via %{site}"
        }
    },
    /**
     * Spanish
     */
    "es-ES": {
        actions: {
            close: "Cerca"
        },
        label: {
            empty: "This is a label"
        },
        seat_selector: {
            passenger_list: "Passenger List"
        },
        // new desktop components
        action_alert: {
            text: "Actualizó con éxito la clase de cabina a",
            cancel: "Cancelar actualización"
        },
        brand: {
            booking_with: "reservando con"
        },
        offer: {
            ribbon: "recomendada",
            person: "persona",
            luggage: "Equipaje",
            adult: "Adulta",
            select: "Seleccionar",
            selected: "Tu elección",
            inTotal: "en total",
            service_unavailable: "Service unavailable",
            chargeable_message: "* Disponible por un cargo",
            features: {
                sustainable: 'Vuelo más sostenible con SAF (20%) y compensación de emisiones (80%)',
                rewards: 'Puntos de bonificación extra (+20%)',
                refund_possible: "Reembolso posible",
                refund_not_possible: "Reembolso no posible",
                rebooking_possible: "Reembarque posible",
                rebooking_not_possible: "Reembarque no posible",
                hand_baggage: "%{value} equipaje de mano",
                hand_baggage_no: "Sin equipaje de mano",
                snacks_and_beverages: "Snacks y bebidas",
                meals_and_beverages: "Comidas y bebidas",
                checkin_bags: "Equipaje facturado %{value} kg",
                checkin_bags_no: "Sin equipaje facturado",
                checkin_bags_no_anc: "Sin equipaje facturado (ver abajo)",
                seat_reservation: "Reserva de asiento",
                priority_boarding: "Embarque prioritario (cuando sea posible)",
                lounge_access: "Acceso a la sala de espera",
                empty_middle_seat: "Asiento del medio vacío",
                priority_services: "Servicios prioritarios",
                refundable: "Reembolsable",
                seat_selection: "Selección de asiento",
                rebooking: "Reembarque",
                checked_baggage: "%{value} Equipaje facturado",
                under_seat: "Debajo del asiento",
                in_flight_entertainment: "Entretenimiento en vuelo",
                in_seat_power: "Energía en el asiento",
                internet: "Internet",
                tv: "TV",
                meals: "Comidas",
                beverages: "Bebidas",
                snacks: "Snacks",
                priority_checkin: "Check-in prioritario",
                priority_bag: "Manejo de equipaje prioritario",
                priority_lane: "Carril prioritario",
            }
        },
        cabin: {
            upgrade_to: "Actualizar a",
            upgrade: "Mejorar"
        },
        action: {
            go_back_to_search: "Regrese a los resultados de búsqueda",
            go_back: "Regresa",
            continue: "Continuar"
        },
        booking_timer: {
            air: "El vuelo elegido está reservado de forma provisional. El tiempo restante para que la reserva se libere es de:",
            package: "El vuelo y el hotel elegidos están reservados de forma provisional. El tiempo restante para que la reserva se libere es de:",
            hotel: "El hotel elegido está reservado de forma provisional. El tiempo restante para que la reserva se libere es de:",
            outoftime: "Time is up!",
            re_search: "Please do a new search via %{site}"
        }
    },
    /**
     * Finnish
     */
    "fi-FI": {
        actions: {
            close: "kiinni"
        },
        label: {
            empty: "This is a label"
        },
        seat_selector: {
            passenger_list: "Passenger List"
        },
        // new desktop components
        action_alert: {
            text: "Päivitetty onnistuneesti matkustamon luokkaan",
            cancel: "Peruuta päivitys"
        },
        brand: {
            booking_with: "varaus"
        },
        offer: {
            ribbon: "suositeltava",
            person: "henkilö",
            luggage: "matkatavarat",
            adult: "Aikuinen",
            select: "Valita",
            selected: "Valintanne",
            inTotal: "yhteensä",
            service_unavailable: "Palvelu ei saatavilla",
            chargeable_message: "* Saatavana lisämaksusta",
            features: {
                sustainable: 'Kestävämpi lentäminen SAF:lla (20%) ja päästöjen kompensointi (80%)',
                rewards: 'Lisäbonus pisteet (+20%)',
                refund_possible: "Hyvitys mahdollinen",
                refund_not_possible: "Hyvitys ei ole mahdollista",
                rebooking_possible: "Uudelleenvaraus mahdollista",
                rebooking_not_possible: "Uudelleenvaraus ei ole mahdollista",
                hand_baggage: "%{value} käsimatkatavarat",
                hand_baggage_no: "Ei käsimatkatavaroita",
                snacks_and_beverages: "Välipalat & juomat",
                meals_and_beverages: "Ateriat & juomat",
                checkin_bags: "Kirjatut laukut %{value} kg",
                checkin_bags_no: "Ei kirjattuja laukkuja",
                checkin_bags_no_anc: "Ei kirjattuja laukkuja (katso alla)",
                seat_reservation: "Istuinpaikan varaus",
                priority_boarding: "Etuoikeutettu nousu (jos mahdollista)",
                lounge_access: "Lounge-pääsy",
                empty_middle_seat: "Tyhjä keskimmäinen istuin",
                priority_services: "Etuoikeutetut palvelut",
                refundable: "Palautettavissa",
                seat_selection: "Istuinpaikan valinta",
                rebooking: "Uudelleenvaraus",
                checked_baggage: "%{value} Kirjatut laukut",
                under_seat: "Istuimen alla",
                in_flight_entertainment: "Viihde lennolla",
                in_seat_power: "Virta istuimessa",
                internet: "Internet",
                tv: "TV",
                meals: "Ateriat",
                beverages: "Juomat",
                snacks: "Välipalat",
                priority_checkin: "Etuoikeutettu sisäänkirjautuminen",
                priority_bag: "Etuoikeutettu laukun käsittely",
                priority_lane: "Ensisijainen turvatarkastus",
            }
        },
        cabin: {
            upgrade_to: "Päivittää",
            upgrade: "Päivitys"
        },
        action: {
            go_back_to_search: "Palaa hakutuloksiin",
            go_back: "Mene takaisin",
            continue: "Jatkaa"
        },
        booking_timer: {
            air: "Valittu lento on alustavasti varattu. Aikaa jäljellä, kunnes matkavaraus vapautuu:",
            package: "Valittu lento+hotelli on alustavasti varatu. Aikaa jäljellä, kunnes matkavaraus vapautuu:",
            hotel: "Valittu hotelli on alustavasti varattu. Aikaa jäljellä, kunnes matkavaraus vapautuu:",
            outoftime: "Time is up!",
            re_search: "Please do a new search via %{site}"
        }
    },
    /**
     * Dutch (Netherlands)
     */
    "nl-NL": {
        actions: {
            close: "Dichtbij"
        },
        label: {
            empty: "This is a label"
        },
        seat_selector: {
            passenger_list: "Passenger List"
        },
        // new desktop components
        action_alert: {
            text: "Cabine klasse succesvol opgewaardeerd naar",
            cancel: "Upgrade annuleren"
        },
        brand: {
            booking_with: "boeken bij"
        },
        offer: {
            ribbon: "aanbevolen",
            person: "persoon",
            luggage: "Bagage add-ons",
            adult: "Volwassen",
            select: "Kiezen",
            selected: "Uw keuze",
            inTotal: "in totaal",
            service_unavailable: "Service onbeschikbaar",
            chargeable_message: "* Beschikbaar tegen betaling",
            features: {
                sustainable: 'Duurzamer vliegen met SAF (20%) en emissiecompensatie (80%)',
                rewards: 'Extra bonuspunten (+20%)',
                refund_possible: "Terugbetaling mogelijk",
                refund_not_possible: "Terugbetaling niet mogelijk",
                rebooking_possible: "Omboeken mogelijk",
                rebooking_not_possible: "Omboeken niet mogelijk",
                hand_baggage: "%{value} handbagage",
                hand_baggage_no: "Geen handbagage",
                snacks_and_beverages: "Snacks & drankjes",
                meals_and_beverages: "Maaltijden & drankjes",
                checkin_bags: "Ingecheckte bagage %{value} kg",
                checkin_bags_no: "Geen ingecheckte bagage",
                checkin_bags_no_anc: "Geen ingecheckte bagage (zie hieronder)",
                seat_reservation: "Stoelreservering",
                priority_boarding: "Voorrang bij instappen (indien mogelijk)",
                lounge_access: "Toegang tot de lounge",
                empty_middle_seat: "Lege middelste stoel",
                priority_services: "Voorrangsdiensten",
                refundable: "Terugbetaalbaar",
                seat_selection: "Stoelselectie",
                rebooking: "Omboeken",
                checked_baggage: "%{value} Ingecheckte bagage",
                under_seat: "Onder de stoel",
                in_flight_entertainment: "Entertainment aan boord",
                in_seat_power: "Stroom in de stoel",
                internet: "Internet",
                tv: "TV",
                meals: "Maaltijden",
                beverages: "Drankjes",
                snacks: "Snacks",
                priority_checkin: "Voorrang bij inchecken",
                priority_bag: "Voorrang bij bagageafhandeling",
                priority_lane: "Prioritaire veiligheidscontrole",
            }
        },
        cabin: {
            upgrade_to: "Upgraden naar",
            upgrade: "Upgraden"
        },
        action: {
            go_back_to_search: "Ga terug naar zoekresultaten",
            go_back: "Ga terug",
            continue: "Voortzetten"
        },
        booking_timer: {
            air: "De gekozen vlucht is voorlopig gereserveerd. De resterende tijd tot de reservering wordt vrijgegeven is:",
            package: "De gekozen vlucht+hotel is voorlopig gereserveerd. De resterende tijd tot de reservering wordt vrijgegeven is:",
            hotel: "Het gekozen hotel is voorlopig gereserveerd. De resterende tijd tot de reservering wordt vrijgegeven is:",
            outoftime: "Time is up!",
            re_search: "Please do a new search via %{site}"
        }
    }
};
