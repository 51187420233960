<template>
  <div>
    <div class="sort_by_group" v-if="!$store.getters.searching && filterData">
      <ul class="sort_by">
        <!-- Sort: Cheapest -->
        <li :class="{ 'ui-btn-active': filterOrderBy === 'price' }" data-order-by="price" @click="changeOrderBy('price')">
          <template v-if="filterData">
            {{ "Billigast" | locale }}<br>{{ filterData.min_price_pax | currency }}<div class="small_text">{{ "per person" | locale }}</div>
          </template>
          <template v-else>
            {{ "Billigast" | locale }}<br>&nbsp;<div class="small_text">&nbsp;</div>
          </template>
        </li>

        <!-- Sort: Fastest -->
        <li :class="{ 'ui-btn-active': filterOrderBy === 'traveltime' }" data-order-by="traveltime" @click="changeOrderBy('traveltime')">
          <template v-if="filterData">
            {{ "Snabbast" | locale }}<br>{{ filterData.min_traveltime_price_pax | currency }}<div class="small_text">{{ "per person" | locale }}</div>
          </template>
          <template v-else>
            {{ "Snabbast" | locale }}<br>&nbsp;<div class="small_text">&nbsp;</div>
          </template>
        </li>

        <!-- Sort: Best -->
        <li :class="{ 'ui-btn-active': filterOrderBy === 'best' }" data-order-by="best" @click="changeOrderBy('best')">
          <template v-if="filterData">
            {{ "Bäst" | locale }}<br>{{ filterData.best_price_pax | currency }}<div class="small_text">{{ "per person" | locale }}</div>
          </template>
          <template v-else>
            {{ "Bäst" | locale }}<br>&nbsp;<div class="small_text">&nbsp;</div>
          </template>
        </li>

        <!-- Sort: Meta -->
        <li v-if="metaButtonPrice" :class="{ 'ui-btn-active': filterOrderBy === 'meta' }" data-order-by="meta" @click="changeOrderBy('meta')">
          {{ "Ert val" | locale }}<br>{{ metaButtonPrice | currency }}<div class="small_text">{{ "per person" | locale }}</div>
        </li>
      </ul>
    </div>

    <nav v-if="legsCount < 3" class="datepicker--nav" style="background-color:#FFFFFF">
      <div class="selectdate">
        <div class="datepicker--nav-action" @click="selectDate(1)"><svg><path d="M 17,12 l -5,5 l 5,5"></path></svg></div>
        <div class="datepicker--nav-title">{{ returnDate(0) }}</div>
        <div class="datepicker--nav-action" @click="selectDate(2)"><svg><path d="M 14,12 l 5,5 l -5,5"></path></svg></div>
      </div>
      <div v-if="legsCount > 1" class="selectdate">
        <div class="datepicker--nav-action" @click="selectDate(3)"><svg><path d="M 17,12 l -5,5 l 5,5"></path></svg></div>
        <div class="datepicker--nav-title">{{ returnDate(1) }}</div>
        <div class="datepicker--nav-action" @click="selectDate(4)"><svg><path d="M 14,12 l 5,5 l -5,5"></path></svg></div>
      </div>
    </nav>
  
    <itinerary-air-alternative v-if="$store.state.alternativeItinerary" :itinerary="$store.state.alternativeItinerary" :key="`alt_${$store.state.alternativeItinerary.id}`" />

    <div v-if="itineraries.length === 0 && !$store.getters.searching && !loading">
      {{ "Alla resultat är bortfiltrerade." | locale }} <a id="reset-filter" href="javascript:void(0);" @click="resetFilter">{{ "Klicka här för att rensa alla filter" | locale }}</a>.
    </div>
    <div v-else>
      <!-- Default flight ViewState -->
      <template v-if="$store.state.alternativeViewState === null">
        <template v-for="(itinerary, index) in itineraries">
          <itinerary-air-5 v-if="itinerary.attributes.template.id === 5" :itinerary="itinerary" :key="itinerary.id" />
          <itinerary-air-4 v-else-if="itinerary.attributes.template.id === 4" :itinerary="itinerary" :key="itinerary.id" />
          <itinerary-air-3 v-else-if="itinerary.attributes.template.id === 3" :itinerary="itinerary" :key="itinerary.id" />
          <itinerary-air-2 v-else-if="itinerary.attributes.template.id === 2" :itinerary="itinerary" :key="itinerary.id" />
          <itinerary-air-1 v-else :itinerary="itinerary" :key="itinerary.id" />

          <div v-if="hasBanners && (index % 4) == 0" :key="`banner_${index}`" class="banners_horizontal">
            <a target="_blank" :href="banners[(index / 4) % banners.length].link">
              <img :src="`/assets/banners_horizontal/${banners[(index / 4) % banners.length].image}` | cloudinary({ e_trim: false })" />
            </a>
          </div>
        </template>
      </template>
      <!-- Alternative ViewState -->
      <template v-else>
        <template v-for="(itinerary, index) in itineraries">
          <itinerary-air-alternative :itinerary="itinerary" :key="itinerary.id" />

          <div v-if="hasBanners && (index % 4) == 0" :key="`banner_${index}`" class="banners_horizontal">
            <a target="_blank" :href="banners[(index / 4) % banners.length].link">
              <img :src="`/assets/banners_horizontal/${banners[(index / 4) % banners.length].image}` | cloudinary({ e_trim: false })" />
            </a>
          </div>
        </template>
      </template>
    </div>

  </div>
</template>

<script>
import { Track } from "components/common/track";
import ItineraryAir1 from "./itineraries/itinerary_air_1.vue";
import ItineraryAir2 from "./itineraries/itinerary_air_2.vue";
import ItineraryAir3 from "./itineraries/itinerary_air_3.vue";
import ItineraryAir4 from "./itineraries/itinerary_air_4.vue";
import ItineraryAir5 from "./itineraries/itinerary_air_5.vue";
import ItineraryAirAlternative from "./itineraries/itinerary_air_alternative.vue";
import { ViewState } from "../store/defs";

import { buildPreConversionData, isSkyscanner, sendPreConversionEvent } from "dep/skyscanner/skyscanner";

export default {
  components: { ItineraryAir1, ItineraryAir2, ItineraryAir3, ItineraryAir4, ItineraryAir5, ItineraryAirAlternative },
  data() {
    return {
      loading: true,
      hasBanners: false,
      filterOrderBy: "price",
      legsCount: gon.search_parameters.legs.length
    };
  },
  computed: {
    itineraries() {
      return this.$store.getters.flightItineraries;
    },
    banners() {
      return this.$store.state.flightBanners;
    },
    metaButtonPrice() {
      if (this.filterData && this.filterData.meta_price_pax) {
        return this.filterData.meta_price_pax;
      } else {
        return null;
      }
    },
    filterData() {
      switch (this.$store.getters.viewState) {
        default:
        case ViewState.air: return this.$store.state.flightFilter;
        case ViewState.hotel: return this.$store.state.hotelFilter;
      }
    }
  },
  watch: {
    itineraries(newValue, oldValue) {
      if (this.loading) {
        this.$nextTick(() => {
          if(!isSkyscanner()) return false;

          if(this.itineraries[0].attributes.meta) {
            const payload = buildPreConversionData(
                document.getElementsByTagName("body")[0].getAttribute("currency"),
                this.itineraries[0].attributes.fare_basis_codes,
                this.itineraries[0].attributes.marketing_carriers,
                this.itineraries[0].attributes.price,
                "dayview-page"
            );

            sendPreConversionEvent(payload);
          } else {
            const payload = buildPreConversionData(
                document.getElementsByTagName("body")[0].getAttribute("currency"),
                this.itineraries[0].attributes.fare_basis_codes,
                this.itineraries[0].attributes.marketing_carriers,
                '0',
                "dayview-page"
            );

            sendPreConversionEvent(payload);
          }

          // build and send analytics data to Skyscanner

        });
      }
      Track.log("Itineraries visible")
      this.loading = false;
    },
    banners(newValue, oldValue) {
      this.hasBanners = newValue && newValue.length > 0;
    },
    filterData(newValue, oldValue) {
      if (newValue && newValue.meta_price) {
        this.filterOrderBy = "meta";
      }
    }
  },
  methods: {
    changeOrderBy(val) {
      this.filterOrderBy = val;
      document.getElementById("filter_order_by").value = val;
      this.$store.dispatch("runFilter");
    },
    resetFilter() {
      $(document).trigger("resetFilter");
    },
    selectDate(button) {
      if (button == 1) {
        document.querySelectorAll("[class$='-selected-']")[0].previousSibling.click();
      }
      if (button == 2) {
        document.querySelectorAll("[class$='-selected-']")[0].nextSibling.click();
      }
      if (button == 3) {
        document.querySelectorAll("[class$='-selected-']")[1].previousSibling.click();  
      }
      if (button == 4) {
        document.querySelectorAll("[class$='-selected-']")[1].nextSibling.click();
      }
      document.getElementsByClassName('main_btn_wide')[0].click();
    },
    returnDate(val) {
      var date = new Date(gon.search_parameters.legs[val][2]);

      var days = gon.common.day_names
      var months = gon.common.month_names.filter(Boolean);

      return days[date.getDay()] + " " + date.getDate() + " " + months[date.getMonth()] + ".";
    }
  },
  mounted() {
    if (this.banners !== undefined && this.banners !== null) {
      this.hasBanners = this.banners.length > 0;
    }
    else {
      this.hasBanners = false;
    }
  }
};
</script>
