declare const window: any;

/**
 * Check if the object is NULL or Undefined.
 * @param obj source object
 */
export function isNull(obj: any): boolean {
    if (obj == null || obj === null || typeof obj === "undefined") {
        return true;
    }
    return false;
}

/**
 * Check if the id already exists in the array.
 * @param array source array.
 * @param id id to match.
 */
export function arrayIncludeID(array: IObjectID[], id: string): boolean {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
            return true;
        }
    }
    return false;
}

/**
 * Serialize a form to hash.
 * @param target the form.
 * @return hash of all the fields.
 */
export function serializeForm(target: JQuery<HTMLElement>): {} {
    const data: {} = {};

    function buildInputObject(arr: string[], val: string) {
        if (arr.length < 1) {
            return val;
        }

        let key = arr[0];
        if (key.slice(-1) === "]") {
            key = key.slice(0, -1);
        }

        let result = {};
        if (arr.length === 1) {
            result[key] = val;
        } else {
            arr.shift();
            result[key] = buildInputObject(arr, val);
        }

        return result;
    }

    $.each(target.serializeArray(), function() {
        const val: string = this.value;
        const c: string[] = this.name.split("[");
        $.extend(true, data, buildInputObject(c, val));
    });

    return data;
}

export function isInIframe(): boolean {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function parseDate(str: string): Date | string {
    const arr: string[] = str.split("-");
    const D: Date = new Date(Number(arr[0]), Number(arr[1]) - 1, Number(arr[2]));
    if (D.getFullYear() === Number(arr[0]) &&
        D.getMonth() === Number(arr[1]) - 1 &&
        D.getDate() === Number(arr[2])) {
        return D;
    } else {
        return "invalid date";
    }
}

/**
 * Gets if the client support WebSocket.
 */
export function supportWebSocket(): boolean {
    return !(isNull(window.WebSocket));
}

export function documentHeight(): number {
    return document.documentElement.offsetHeight
        || document.body.offsetHeight;
}

export function windowHeight(): number {
    return window.innerHeight                   // Modern Browsers (Chrome, Firefox)
        || document.documentElement.clientHeight  // Modern IE, including IE11
        || document.body.clientHeight;            // Old IE, 6,7,8
}

export function scrollPosition(): number {
    return window.scrollY                       // Modern Browsers (Chrome, Firefox)
        || window.pageYOffset                     // Modern IE, including IE11
        || document.documentElement.scrollTop;    // Old IE, 6,7,8
}

/**
 * Gets a config from a meta tag in the header.
 * @param name meta tag name.
 */
export function getConfig(name: string): string {
    const element: any = document.head.querySelector(`meta[name='${name}']`);
    if (element) {
        return element.getAttribute("content");
    }
}

/**
 * Empty an array using pop.
 * This is helpful when having reactive collections.
 * @param array source array
 */
export function emptyArray<T>(array: T[]): void {
    while (array.length > 0) {
        array.pop();
    }
}

/**
 * Append an array with another array by using push.
 * This is helpful when having reactive collections.
 * @param array source array.
 * @param values new array values.
 * @param flush should the array be flushed before appending.
 */
export function appendArray<T>(array: T[], values: T[], flush: boolean = false): void {
    if (flush) {
        emptyArray(array);
    }

    for (let i = 0; i < values.length; i++) {
        array.push(values[i]);
    }
}

/**
 * Interface for an object that have id.
 */
export interface IObjectID {
    id: string;
}

/**
 * Append an array with another array by checking if the id exists and using push.
 * This is helpful when having reactive collections.
 * @param array source array.
 * @param values new array values.
 * @param flush should the array be flushed before appending.
 */
export function appendArrayIDSafe(array: IObjectID[], values: IObjectID[], flush: boolean = true): void {
    if (flush) {
        emptyArray(array);
    }

    let skippedItems: number = 0;
    for (let i = 0; i < values.length; i++) {
        if (!arrayIncludeID(array, values[i].id)) {
            array.push(values[i]);
        } else {
            skippedItems += 1;
        }
    }

    if (skippedItems > 0) {
        console.warn(`Skipped adding ${skippedItems} items to array.`);
    }
}

/**
 * Ensure that the variable is a object.
 * @param obj source object
 */
export function toObject(obj: any): any {
    if (typeof (obj) === "object") {
        return obj;
    } else {
        return JSON.parse(obj);
    }
}

/**
 * Force the browser to navigate to a new page.
 * @param url the address of the new page.
 */
export function navigate(url: string): void {
    window.location.href = url;
}

export function raiseError(name: string, message: string): Error {
    const e: Error = new Error(message);
    e.name = name;
    return e;
}
