<template>
  <div>
    <p>Debug information</p>

    {{ info }}
  </div>
</template>

<script>
export default {
  tabOptions: {
    title: "Hotel",
    canShow(info) {
      if (info && info.hotel_id) {
        return true;
      }
      return false;
    }
  },
  props: {
    info: Object
  }
};
</script>
